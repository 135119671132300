import React, { useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import "../Products/product.css";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import ion_cart_outline from "../../Assets/Images//ion_cart-outline.svg";
import { Await, json, useLocation, useNavigate, useParams } from "react-router-dom";
import { vendorProductList } from "../../Services/api";
import axiosInstance from "../../Services/axios";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
import hamburgerMenu from "../../Assets/Images/p_navbars.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoSearch } from "react-icons/io5";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import { toast } from "react-toastify";
import { BsChevronDown } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";
import ReactLoading from "react-loading";
import vegIcon from "../../Assets/Images/vegpngoutput.png"
import nonvegIcon from "../../Assets/Images/nomvegoutput.png"
import eggIcon from "../../Assets/Images/eggpngoutput.png"
import Toggle from "../../Components/toggle-button/Toggle";
import { FaRupeeSign } from "react-icons/fa";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
const Products = () => {
  const navigate = useNavigate();
  const addonModalRef = useRef(null);
  const addonEditeModalRef = useRef(null);

  const [productlist, setProductlist] = useState([]);
  const [cartId, setCartId] = useState("");
  const [cardCount, setCardCount] = useState();
  const [shipingtype, setShipingtype] = useState("");
  const valueParam = useParams();
  const merchantId = valueParam;
  const [isModelOpen, setModelOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [showMenus, setShowMenus] = useState(false);
  const [searchitem, setSearchitem] = useState("");
  const [listitem, setListitem] = useState([]);
  const [searhModelItems, setSearhModelItems] = useState([]);
  const [loadingButtonId, setLoadingButtonId] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [productPrice, setProductPrice] = useState('')

  const [selectItem, setSelectItem] = useState('')
  const [openIndices, setOpenIndices] = useState([]);
  // Offer or Membership
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();
  // Food menu
  const [menuStatus, setMenuStatus] = useState('');
  //  const [menuSelect, setMenuSelect] = useState('');
  const [isVegSelected, setIsVegSelected] = useState(false);
  const [isNonVegSelected, setIsNonVegSelected] = useState(false);
  const [isEggSelected, setIsEggSelected] = useState(false);

  const [productlistRaw, setProductlistRaw] = useState('')

  const [productid, setProductid] = useState('')
  const [productCost, setProductCost] = useState('')
  const [addonsCost, setAddonsCost] = useState('')
  const [selectedAddons, setSelectedAddons] = useState([])
  const [productCount, setProductCount] = useState(0);
  const [cartDetails, setCartDetails] = useState([])

  const [card, setCard] = useState("")
  const [filteredItems, setFilteredItems] = useState([]);

  const location = useLocation();
  const { offer, flowtype } = location.state || {};

  console.log("searhModelItems", searhModelItems);

  const closeMenuList = (event) => {
    const menuButton = document.getElementById("menuButton");
    if (menuButton && !menuButton.contains(event.target)) {
      setShowMenus(false);
    }
  };


  useEffect(() => {
    filterFoodProducts()
  }, [isNonVegSelected, isVegSelected, isEggSelected]);

  useEffect(() => {
    setOpenIndices(productlist?.map((_, index) => index));
  }, [productlistRaw]);

  useEffect(() => {
    let productCart = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";
    setCartId(productCart?.cartId ? productCart?.cartId : "");

    setShipingtype(productCart?.shipping_type ? productCart?.shipping_type : "");
    let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
    setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "");
    setcardType(appliedCard?.cardType ? appliedCard?.cardType : "");
    setcardId(appliedCard?.cardId ? appliedCard?.cardId : "");
    let vendortype = localStorage.getItem("vendortype") ? JSON.parse(localStorage.getItem("vendortype")) : "";
    setMenuStatus(vendortype?.vendor_type ? vendortype?.vendor_type : "")
  }, []);

  useEffect(() => {
    if (shipingtype != "") {
      getproductlist();
    }
  }, [shipingtype]);

  useEffect(() => {
    if (cartId != "") {
      getproductlist();
    }
  }, [cartId]);
  useEffect(() => {
    if (flowtype) {
      let EditCart = localStorage.getItem("EditOrder_Detail") ? JSON.parse(localStorage.getItem("EditOrder_Detail")) : "";
      setCartId(EditCart?.orderid ? EditCart?.orderid : "");
      setShipingtype(EditCart?.shippingtype ? EditCart?.shippingtype : "");
    }
  }, [flowtype]);



  const getproductlist = () => {
    try {

      let reqData = {
        vendor_id: valueParam.vendor_id,
        shipping_type: shipingtype,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };

      axiosInstance.post("/user/products/vendor_products_list_without_login ", reqData)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            console.log("data", data);

            setProductlistRaw(data?.result);
            setProductlist(data?.result);
            setCardCount(data?.cart_count);
            setListitem(data?.all_products);
            setPageLoaded(true);
            setCartDetails(data.cart)
            setCard(data?.card)
            if (data?.cart_count != 0) {
              setModelOpen(true)
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast("Try again Later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  const handleClick = (buttonId, price, addons) => {
    setLoadingButtonId(buttonId);
    setModelOpen(false)
    setProductid(buttonId)
    Add_Remove_productcount(buttonId, "add", price, "0")
  };

  const Add_Remove_productcount = async (productId, Operation, price, addonstate) => {
    try {
      const Addition = [];

      if (selectedAddons && selectedAddons.length > 0) {
        selectedAddons.forEach((addon) => {
          Addition.push(...addon.options);
        });
      }

      let reqData = {
        vendor_id: valueParam.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        cart_id: cartId,
        quantity: 1,
        operation: Operation,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: Addition ? Addition : "",
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };

      axiosInstance.post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {

            let productCart = { shipping_type: shipingtype, cartId: res.data.cart_id, vendorId: valueParam.vendor_id };
            localStorage.setItem("productCart", JSON.stringify(productCart));
            localStorage.setItem("cartid", JSON.stringify(res.data.cart_id));
            if (Operation == "remove") {
              setProductCount(prevCount => prevCount - 1)
            } else {
              setProductCount(prevCount => prevCount + 1)
            }
            setCartId(res.data.cart_id);
            setCartDetails(res?.data?.cart_items)
            getproductlist();

            if (addonstate == "0") {
              setModelOpen(true)
            }
            setSelectedAddons([]);

          }
        })
        .catch((err) => {

          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }

        });
    } catch (error) {
      toast("can't select product please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  function openserachProductModal() {
    document.getElementById("serachProductModalProduct").classList.add("show");
    setMenuOpen(false)
    closeAddonModal()
    closeEditAddonModal()

  }

  function closeserachProductModal() {
    var modal = document.getElementById("serachProductModalProduct");
    modal.classList.remove("show");
    setMenuOpen(true)
  }

  const toggleAccordion = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };
  const isItemOpen = (index) => openIndices.includes(index);


  const handleModelClose = () => {
    setModelOpen(false);
  };


  useEffect(() => {
    filterProducts()
  }, [isVegSelected, isNonVegSelected, productlistRaw, isEggSelected])

  const filterProducts = () => {
    let filteredData = productlistRaw; 
    if (isVegSelected) { 
      filteredData = productlistRaw.map((category) => {
        return {
          ...category,
          products: category.products.filter((product) => product.food_type == 1 || (isEggSelected && product.food_type == 3))
        };
      }).filter(category => category.products.length > 0);
    } 
    else if (isNonVegSelected) {
      filteredData = productlistRaw.map((category) => {
        return {
          ...category,
          products: category.products.filter((product) => product.food_type == 2 || product.food_type == 3)
        };
      }).filter(category => category.products.length > 0);
    }

    // Update the product list state
    setProductlistRaw(productlistRaw);
    setProductlist(filteredData);

  };


  const handleVegToggle = () => {
    setIsVegSelected(!isVegSelected);
    if (!isVegSelected) {
      setIsNonVegSelected(false);
      setIsEggSelected(false)
    }
  };
  const handleNonVegToggle = () => {
    setIsNonVegSelected(!isNonVegSelected);
    if (!isNonVegSelected) {
      setIsVegSelected(false);
    }
  };

  // const handleEggToggle = () => {
  //   setIsEggSelected(!isEggSelected);
  // };
  const handleEggToggle = () => {
    if (isVegSelected) {
      setIsEggSelected(prevState => !prevState); // Toggle based on the previous state
    }
  };

  const dropUpMenuPress = () => {
    setShowMenus((prevShowMenus) => {
      const newState = !prevShowMenus;
      return newState;
    });
  };

  const handleCategoryClick = (index) => {
    const accordionItem = document.getElementById(`collapseOne${index}`);
    if (accordionItem) {
      accordionItem.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };


  // isEggSelected
  const filterFoodProducts = () => {
    let filteredList = listitem;
    console.log("listitem", listitem);

    if (isVegSelected) {
      if (isEggSelected) {
        filteredList = listitem?.filter((product) => product.food_type == 1 || product.food_type == 3)

      } else {
        filteredList = listitem?.filter((product) => product.food_type == 1)
      }
    } else if (isNonVegSelected) {
      filteredList = listitem?.filter((product) => product.food_type == 2 || product.food_type == 3)
    }
    setFilteredItems(filteredList)
    console.log("filteredList",filteredList);
    
  }
  useEffect(() => {
    const updatedList = listitem.filter((item) => {
      const hasValidProductName = typeof item.product_name === "string";
      const hasValidProductCategory = typeof item.category_name === "string";

      const productNameMatches =
        hasValidProductName &&
        item.product_name.toLowerCase().includes(searchitem.toLowerCase());
      const productCategoryMatches =
        hasValidProductCategory &&
        item.category_name.toLowerCase().includes(searchitem.toLowerCase());
      const updatedList = listitem.filter((item) => {
        const hasValidProductName = typeof item.product_name === "string";
        const hasValidProductCategory = typeof item.category_name === "string";
        const productNameMatches =
          hasValidProductName &&
          item.product_name.toLowerCase().includes(searchitem.toLowerCase());
        const productCategoryMatches =
          hasValidProductCategory &&
          item.category_name.toLowerCase().includes(searchitem.toLowerCase());
        if (productNameMatches || productCategoryMatches) {
          return true;
        }
        if (searchitem && (!hasValidProductName || !hasValidProductCategory)) {
          console.warn(
            "Item with non-string or missing name/category encountered:",
            item
          );
        }

        return false;
      });
      setFilteredItems(updatedList);
    });
  }, [searchitem, listitem]);

  const openAddonModal = (productid) => {
    addonModalRef.current.classList.add("show");
    addonModalRef.current.style.display = "block";
    setLoadingButtonId(productid)
    setProductid(productid)
    setModelOpen(false)
  };

  const closeAddonModal = () => {
    addonModalRef.current.classList.remove("show");
    addonModalRef.current.style.display = "none";
  };
  // productid
  const openEditAddonModal = (addons, addedAddons, productid, added_count) => {
    let transformedData = transformDataAndCalculateTotalCost(addons, addedAddons);
    setProductid(productid);
    setProductCount(added_count)
    addonEditeModalRef.current.classList.add("show");
    addonEditeModalRef.current.style.display = "block";
    setModelOpen(false)
  };

  const closeEditAddonModal = () => {
    addonEditeModalRef.current.classList.remove("show");
    addonEditeModalRef.current.style.display = "none";

  };

  const handelEdittoAddonmodel = (productid) => {
    closeEditAddonModal()
    openAddonModal()
    setModelOpen(false)
    setProductid(productid)
    setProductCost((totalPrice) * (productCount))
  }

  const transformDataAndCalculateTotalCost = (rawData, selectedOptions) => {
    let transformedData = [];
    let totalCost = 0;

    if (rawData && rawData.length > 0) {
      rawData.forEach((addon) => {
        let addonObj = {
          addonId: addon.id,
          options: []
        };

        addon?.options?.forEach((option) => {
          if (selectedOptions?.includes(option?.id)) {
            addonObj.options?.push(option?.id);
            totalCost += parseInt(option?.price, 10);
          }
        });

        if (addonObj.options.length > 0) {
          transformedData.push(addonObj);
        }
      });
    }

    return { addons: transformedData, cost: totalCost };
  };

  const selectAddon = (addon, option, price) => {
    let addonId = addon.id;
    let selectedAddonsList = [...selectedAddons];
    let found = false;

    selectedAddonsList.forEach(item => {
      if (item.addonId == addonId) {
        found = true;
        if (!item.options.includes(option)) {
          if ((addon.selection_type == 1 && item.options.length > 0) || (addon.selection_type == 2 && item.options.length >= addon.max_select)) {
            toast("You have already selected max addons for this category", {
              position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1'
            });
          } else {
            item.options.push(option);
            setProductCost(Number(productCost) + Number(price));
            setAddonsCost(Number(addonsCost) + Number(price));
            setProductPrice(productCost)
          }
        } else {
          item.options = item.options.filter((opt) => opt !== option);

          setProductCost(Number(productCost) - Number(price));
          setAddonsCost(Number(addonsCost) - Number(price));

        }
      }
    });

    if (!found) {
      selectedAddonsList.push({ "addonId": addonId, "options": [option] });

      setProductCost(Number(productCost) + Number(price));
      setAddonsCost(Number(addonsCost) + Number(price));
    };

    setSelectedAddons(selectedAddonsList);
  }

  const checkIsSelectedAddon = (addonId, option) => {
    let selectedAddonsList = [...selectedAddons];
    let found = false;
    selectedAddonsList.forEach(item => {
      if (item.addonId === addonId) {
        if (item.options.includes(option)) {
          found = true;
        }
      }
    });

    return found;
  }

  const checkButtonStatus = () => {

    for (const addon of selectItem?.addons) {
      const selectedAddon = selectedAddons?.find((sel) => sel?.addonId == addon?.id);
      const selectedOptionsCount = selectedAddon ? selectedAddon?.options?.length : 0;
      if (addon?.required == 1 && addon?.selection_type == 2 && selectedOptionsCount < addon.min_select) {
        toast(`You need to select at least ${addon.min_select} option(s) from the category ${addon?.addon_name}`, {
          position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1 toast-custom-style' // Add your custom class here
        });
        return;
      } else if (addon?.required == 1 && addon.selection_type == 1 && selectedOptionsCount <= 0) {
        toast(`You need to select at least 1 option(s) from the category ${addon?.addon_name}`,
          { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1 toast-custom-style' });
        return;
      }
    }


    closeAddonModal()
    // closeEditAddonModal()
    setModelOpen(true)
    Add_Remove_productcount(productid, "add", "1");
  }

  const update_Product = async (productId, Operation, count) => {
    try {
      let reqData = {
        vendor_id: valueParam.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        cart_id: cartId,
        quantity: count,
        operation: Operation,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: selectItem?.added_addons,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };
      axiosInstance.post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            setCartId(res.data.cart_id);
            getproductlist();
            closeEditAddonModal()
            setModelOpen(true)
          }
        })
        .catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast("can't select product please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  const confirmUpdate = () => {
    let ProductItem = cartDetails
    var ProductHave = ProductItem?.some((obj) => obj.product_id == productid);
    ProductItem?.map((Cartdata, id) => {
      if (Cartdata.product_id == productid) {
        if (Cartdata.quantity > productCount) {
          update_Product(productid, 'remove', Cartdata.quantity - productCount);
          setModelOpen(true)
        } else if (productCount > Cartdata.quantity) {
          update_Product(productid, 'add', productCount - Cartdata.quantity);
          setModelOpen(true)
        } else {
          closeEditAddonModal()
        }
      }
    })
  }
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (productlist?.length > 0) {
      let calculatedTotal = 0;
      productlist.forEach((product) => {
        product?.products?.forEach((item) => {
          if (productid == item?.product_id) {
            calculatedTotal += parseFloat(item?.price);
            item?.addons?.forEach((addon) => {
              addon?.options?.forEach((option) => {
                if (item?.added_addons?.includes(option?.id)) {
                  calculatedTotal += parseFloat(option?.price);
                }
              });
            });
          }
        });
      });
      setTotalPrice(calculatedTotal);
    }
  }, [productlist, productid]);

  console.log("searchitem", searchitem);

  return (
    <>
      <div className="main-top d-flex flex-column" style={{ overflowY: "auto", maxHeight: "300px" }}>
        <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
          <div className="d-flex justify-content-between align-items-center ms-2 me-2">
            <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button"
              onClick={() => {
                flowtype == "EDIT_ORDER" ? navigate('/ProductPass', { state: { orderid: cartId, flowtype: "EDIT_ORDER" } }) :
                  card?.card_status == "1" ? navigate(-1) :
                    navigate(`/vendor/${valueParam.vendor_id}`); localStorage.removeItem("shipping_type"); localStorage.removeItem("productDeliveryTime"); localStorage.removeItem("cartid")
              }} />
            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Products</div>
          </div>
          {cardCount > 0 ? (
            <div onClick={() => navigate("/productBasket", { state: { merchantId, cartId, flowtype: flowtype } })} style={{ cursor: "pointer" }} >
              <img src={ion_cart_outline} />
              <div className="basketCount">
                {cardCount > 0 ? (
                  <span style={{
                    height: "20px", width: "20px", backgroundColor: "var(--primary-bg)", color: "white", borderRadius: "50%",
                    display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
                  }} >
                    {cardCount}
                  </span>
                ) : (cardCount > 0 && <span>{cardCount}</span>)}
              </div>
            </div>
          ) : (
            <div>
              <img src={ion_cart_outline} />
            </div>
          )}
        </div>


      </div >


      <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390, zIndex: 7777, maxHeight: "auto", overflowY: "auto", }} ref={addonEditeModalRef} >
        <div className=" d-flex justify-content-end mt-2" onClick={() => closeEditAddonModal()}>
          <span className="closeIcon" ><IoMdClose /> </span>
        </div>
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <p className="m-0 ff-poppins-semiBold fs-14">Your Chosen</p>
            <p className="m-0 ff-poppins-regular fs-14 primary-text" onClick={() => handelEdittoAddonmodel(productid)}>Edit</p>
          </div>
          {productlist?.length > 0 &&
            productlist?.map((product, productIndex) => (
              <div key={productIndex}>
                {product?.products?.map((item) => {
                  return productid == item?.product_id ?
                    item?.addons?.map((addon) =>
                      addon?.options?.map((option, optionIndex) => {
                        return item?.added_addons?.includes(option?.id) && (
                          <div className="d-flex justify-content-between" key={optionIndex}>
                            <div className="d-flex mt-1">
                              <img src={option.type === "1" ? vegIcon : option.type === "2" ? nonvegIcon : ""} height="13px" width="13px" className="me-1 mt-1" />
                              <div className="ff-poppins-regular fs-13 ms-1">
                                {option?.name}
                              </div>
                            </div>
                            <div className="d-flex me-4">
                              <div className="ff-poppins-semiBold fs-12 mt-1 me-1">
                                + {option?.price}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : null;
                })}
              </div>
            ))
          }
          <div className="mt-3" style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
            <div className=" ">
              {productlist?.length > 0 &&
                productlist?.map((product) =>
                  product?.products?.map((item) => (
                    <div className="ff-poppins-regular ">
                      {productid == item?.product_id && item?.added_count > 0 && (
                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="  d-flex fs-14 col-4  ">
                              <div className="col-2">
                                <FiMinusCircle className="  primary-text fontSizeCountIcon"
                                  onClick={() => Add_Remove_productcount(item.product_id, "remove")
                                  }
                                />
                              </div>
                              <div className=" col-2 ms-1 d-flex justify-content-center">
                                {productCount}
                              </div>
                              <div className="col-2">
                                <LuPlusCircle
                                  className={`  primary-text fontSizeCountIcon ${card?.offer_type == "4" ? productCount == card?.combo_count ? "opacity_Cls rounded-circle" : "" : productCount == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                  onClick={() => {
                                    if (card?.offer_type == "4") {
                                      if (productCount != "1") {
                                        setProductCount((prevCount) => prevCount + 1);
                                        setProductid(item.product_id)
                                      }
                                      else {
                                        toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
                                      }
                                    }
                                    else {
                                      if (productCount != item.max_allowed_quantity) {
                                        setProductCount((prevCount) => prevCount + 1);
                                        setProductid(item.product_id);
                                        setProductCost((totalPrice) * (productCount + 1))
                                      } else {
                                        toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className=" p-1  ">
                              <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular fs-14 mb-1">
                                Cost: {(totalPrice) * (productCount)}
                              </p>
                            </div>

                          </div>
                          <div className="col-12">
                            <button type="button" className="btn-type-1   ff-poppins-semiBold mt-2 fs-14 w-100 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
                              onClick={() => confirmUpdate()} >
                              Confirm
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                )}
            </div>
          </div>
        </div>

      </div>
      {/* Add Addons Model  */}
      <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, width: 390, zIndex: 7777, maxHeight: "60%" }} ref={addonModalRef}  >
        <div className=" d-flex justify-content-end mt-2" onClick={() => closeAddonModal()}>
          <span className=" closeIcon " ><IoMdClose /> </span>
        </div>
        {productlist?.length > 0 &&
          productlist?.map((product, productIndex) => (
            <div key={productIndex} className="">
              {product?.products?.map((item, productItemIndex) => {
                return productid == item?.product_id ?
                  item?.addons?.map((addon, addonIndex) => (
                    <div className="offcanvas-body small">
                      <div className="">
                        <div className="mb-2">
                          <h6 className="m-0 ff-poppins-semiBold fs-14">choose {addon?.addon_name}</h6>
                          <div className="secondary-text fs-13">select any (optional)</div>
                        </div>
                        {addon?.options?.map((option, optionIndex) => (
                          <div className="d-flex justify-content-between" key={`${productIndex}-${productItemIndex}-${addonIndex}-${optionIndex}`}>
                            <div className="d-flex mt-1   ">
                              <img src={option?.type === "1" ? vegIcon : option?.type === "2" ? nonvegIcon : ""} height="13px" width="13px" className="me-1 mt-1" />
                              <div className="ff-poppins-regular fs-13 ms-1">{option?.name}</div>
                            </div>
                            <div className="d-flex">
                              <div className="ff-poppins-semiBold fs-12 mt-1 me-1">+ {option?.price}</div>
                              {
                                checkIsSelectedAddon(addon.id, option.id) ?
                                  <div onClick={() => { selectAddon(addon, option.id, option?.price); setProductid(item?.product_id) }} className="primary-text fs-15 ff-poppins-semiBold"> <MdOutlineCheckBox /> </div> :
                                  <div onClick={() => { selectAddon(addon, option.id, option?.price); setProductid(item?.product_id) }} className="fs-15 ff-poppins-semiBold " >    <MdOutlineCheckBoxOutlineBlank /></div>
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )) : ""
              }
              )}
            </div>
          ))}
        <div style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
          <div className=" p-1  ">
            <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular fs-14 mb-1">
              Cost: {productCost}  </p>
            <button type="button" className="btn-type-1 ff-poppins-semiBold fs-14 col-12 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
              onClick={() => checkButtonStatus()}  >
              Confirm
            </button>
          </div>

        </div>
      </div>
      {
        pageLoaded ?
          <div>
            <div className="mt-2 p-2" onClick={() => openserachProductModal()}>
              <div className="d-flex justify-content-between deleiveryAddress rounded-pill bg-white secondary-text-1 p-1">
                <input type="text" className="SearchServiceBox ms-2  ff-balooPaaji2-regular rounded-pill fs-14 mt-1" placeholder="Search " />
                <span className="me-3"> <IoSearch /> </span>
              </div>
            </div>
            {
              menuStatus == "Food" ?
                <div className="d-flex fs-13 ff-poppins-semiBold mb-2 p-2">
                  <div className="d-flex ms-2" onClick={handleVegToggle}>
                    <span>Veg</span>
                    <Toggle data={vegIcon} color="veg" isSelected={isVegSelected} />
                  </div>
                  {
                    isVegSelected ? <div className="d-flex" onClick={handleEggToggle}>
                      <span>Egg</span>
                      <Toggle data={eggIcon} color="egg" isSelected={isEggSelected} />
                    </div> : ""
                  }

                  <div className="d-flex" onClick={handleNonVegToggle}>
                    <span>Non-veg</span>
                    <Toggle data={nonvegIcon} color="nonveg" isSelected={isNonVegSelected} />
                  </div>

                </div> : ""
            }
            {productlist?.length > 0 ? (
              <div className="offcanvas-parentType3 position-relative " id="scroll" onClick={closeMenuList}>
                < div className="doorStepService p-0 " >
                  <div className="">
                    <div className="accordion px-3  scroller-style-y" id="accordionExample">
                      {productlist?.map((product, index) => {
                        return (
                          <div>
                            < div className="accordion-item " key={index} >
                              <h2 className="accordion-header " id={"heading" + index}>
                                <button className={`serviceMenu-but accordion-button ff-poppins-bold fs-16 text-white ${isItemOpen(index) ? "" : "collapsed"
                                  }`} type="button" onClick={() => toggleAccordion(index)} aria-expanded={isItemOpen(index) ? "true" : "false"}   >
                                  <p> {product.category_name} </p>
                                  <p>
                                    {isItemOpen(index) ? (<IoIosArrowUp style={{ fontSize: 20 }} />) : (<IoChevronDown style={{ fontSize: 20 }} />)}
                                  </p>
                                  <style>{`button::after {   display: none;  position: absolute;   content: '';   }`}  </style>
                                </button>
                              </h2>
                              <div id={"collapseOne" + index} className={`accordion-collapse collapse ${isItemOpen(index) ? "show" : ""}`} aria-labelledby={"heading" + index}  >
                                <div className="accordion-body">
                                  {product?.products?.length > 0 ? (
                                    product?.products?.map((item, itemIndex) => (
                                      <div key={itemIndex}>
                                        <div>
                                          <div className="productDiv">
                                            <div className="col-3 ">
                                              <div className="imgBox position-relative productImageDiv">
                                                <img src={item.cover_image.image == "" ? defaultimg : item.cover_image.image_url} className="productImage" />
                                              </div>
                                            </div>
                                            <div className="col-6 ps-2">
                                              <div className="ff-poppins-semiBold fs-12">
                                                {item?.food_type !== '0' ?
                                                  <img src={item.food_type == "1" ? vegIcon : item.food_type == "2" ? nonvegIcon : item.food_type == "3" ? eggIcon : ""} height="13px" width="13px" className="me-2" />
                                                  : ""}
                                                {item.product_name}
                                              </div>
                                              <div>
                                                {item.applied_card_status == "1" ? (
                                                  <div className="d-flex ">
                                                    <div className="ff-poppins-semiBold d-flex  ">
                                                      <span className="fs-11"><FaRupeeSign /> </span>  <div className="fs-12">{item.price} </div>
                                                    </div>
                                                    <span className="primary-text fs-10">
                                                      ({item.benefit_lable}){" "}
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div className="ff-poppins-semiBold d-flex me-1 ">
                                                    <span className="fs-11"><FaRupeeSign /> </span>  <div className="fs-12">{item.price} </div>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="ff-poppins-semiBold primary-text fs-9">
                                                {item.unit_quantity} {item.unit_type_name}
                                              </div>
                                              <div className="moreDetails ">
                                                <div className="accordion-item">
                                                  <h2 className="moreInfo m-0 fs-14" id={"headingSubOne"}  >
                                                    <button className="moreInfobtn primary-text ff-poppins-semiBold fs-10 px-0" type="button"
                                                      onClick={() => navigate(`/singleProductDetails/${merchantId.vendor_id}/${item.product_id}`,
                                                        { state: { productId: item.product_id, merchantId, flowtype: "EDIT_ORDER" }, })}  >
                                                      More Details
                                                      <BsChevronDown />
                                                    </button>
                                                  </h2>
                                                </div>
                                              </div>

                                              {item.stock_status == "1" ? (
                                                <>
                                                  <p className="ff-poppins-semiBold primary-text fs-9 m-0 ">
                                                    {item.stock_quantity - item.added_count}{" "}  left
                                                  </p>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className="col-3 d-flex justify-content-center align-items-end primary-text">
                                              <div className="ff-poppins-regular fs-9 primary-text ">
                                                {item.added_count > 0 ? (
                                                  <div>
                                                    <div className="row align-items-center w-100 fs-14 gx-0">
                                                      <div className="col-4">
                                                        <FiMinusCircle className="w-100 primary-text fontSizeCountIcon"
                                                          onClick={() => Add_Remove_productcount(item.product_id, "remove")} />
                                                      </div>
                                                      <div className="col-4 d-flex justify-content-center align-items-end primary-text">
                                                        {item.added_count}
                                                      </div>
                                                      <div className="col-4">
                                                        <LuPlusCircle className={`w-100 primary-text fontSizeCountIcon ${card?.offer_type == "4" ? item.added_count == "1" ? "opacity_Cls rounded-circle" : "" : item.added_count == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                                          onClick={() => {
                                                            setSelectItem(item)
                                                            if (item.addons == "" && item.added_addons == "") {
                                                              Add_Remove_productcount(item.product_id, "add")


                                                            }

                                                            else if (card?.offer_type == "4") {
                                                              if (item.added_count == "1") {
                                                                toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-custom-style toast-style-1", })
                                                              }


                                                            }

                                                            else if (item.addons != "") {
                                                              item.added_count == item.max_allowed_quantity ?
                                                                toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-custom-style toast-style-1", })
                                                                : openEditAddonModal(item.addons, item.added_addons, item.product_id, item.added_count)


                                                            }
                                                          }} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <div className="col-4 selectBtnProductDiv">
                                                      <button
                                                        className={`selectBtnProduct primary-text fs-12 ff-poppins-semiBold ${loadingButtonId == item.product_id ? "loading" : ""}`}
                                                        onClick={() => {
                                                          item?.addons == "" ? handleClick(item.product_id, item.price) : openAddonModal(item?.product_id);
                                                          setProductCost(item?.price);
                                                          setSelectItem(item)
                                                        }}>
                                                        <span>Select</span>
                                                        {/* )} */}
                                                      </button>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                  <></>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                  </div>


                  {/* Review and Pay Modal for selected product  */}

                  {isModelOpen && (
                    <div className="productsHeroDiv" >
                      {cardCount > 0 && (
                        <div className="reviewPayDivs" >
                          <div className="linetop-container" onClick={() => handleModelClose()}>
                            <div className="lineTop text-center primary-text" style={{ borderTop: "3px solid" }}   >

                            </div>
                          </div>
                          <div className="d-flex ff-poppins-bold">
                            <p className="primary-text d-flex">
                              <p className="me-1  ms-1">  {cardCount > 1 ? cardCount + " Products" : cardCount + " Product"}</p>
                              <span className="text-dark">added</span>{" "}
                            </p>
                          </div>
                          <button
                            className={`${card?.offer_type === "4" ? (card?.combo_count === cardCount ? "primary-bg" : "secondaryslot-bg") : "primary-bg"} reviewAndPayBtnProduct ff-balooPaaji2-bold`}
                            onClick={() => {
                              const flowtypeValue = flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : flowtype;

                              if (card?.offer_type === "4" && card?.combo_count === cardCount) {
                                navigate("/ReviewPay", { state: { merchantId, cartId, shipingtype, flowtype: flowtypeValue } });
                              } else {
                                navigate("/ReviewPay", { state: { merchantId, cartId, shipingtype, flowtype: flowtypeValue } });
                              }
                            }}
                          >
                            Review and Pay
                          </button>

                        </div>
                      )}
                    </div>
                  )}
                  {isMenuOpen && (
                    <div className="menu-div">
                      <div className="left-div"></div>
                      <div className="right-div">
                        <div className="dropup-center dropup" style={{ zIndex: 9999 }}>
                          <button id="menuButton" className="btn btn-secondary dropdown-toggle menuBtn" type="button" onClick={dropUpMenuPress} style={{ display: showMenus ? "none" : " ", zIndex: 9999 }} >
                            <RxHamburgerMenu className="menuIcon" />
                            Menu
                          </button>
                        </div>
                      </div>
                      {showMenus && (
                        <div className="menu-list-container  scroller-style-y" style={{ zIndex: 9998, maxHeight: "300px", overflowY: "hidden" }}  >
                          <ul className="dropdown-menu show " style={{ maxHeight: "300px", }} id="menuList">
                            {productlist.map((cate, index) => (
                              <li key={index} className="d-flex justify-content-between p-1 fs-12 ff-poppins-regular">
                                <button className="dropdown-item d-flex collapsed justify-content-between" onClick={() => { handleCategoryClick(index); }}
                                  data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-controls={"collapseOne" + index}  >
                                  <span className="truncate-text">   {cate?.category_name} </span>
                                </button>
                                <span className="me-1 text-light">{cate?.products?.length}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>)}


                </div >
                {/* style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15,  }} */}
                {/* Select product search modal  */}

              </div >
            ) :
              (
                <div className=" ReactLoading">
                  <p className="p-5 text-center secondary-text-1 ff-poppins-regular fs-14"> No Product added yet,add a product by pressing the add button! or wait till customer Service representative adds your Products </p>
                </div>
              )}

            <div className="offcanvas p-1 addOnModel_scroll" tabindex="-1" id="serachProductModalProduct" aria-labelledby="selectCustomerModalProductLabel" style={{ height: '101vh', width: 390 }}   >
              <div className="  ">
                <div className="searchModelTop">
                  <div class="modal-header modal-headerSearch d-flex justify-content-between  ms-2 me-2">
                    <h1 class="ff-poppins-semiBold fs-16 mt-2" id="exampleModalLabel">
                      Search
                    </h1>
                    <button type="button" className="btn-close " aria-label="Close" onClick={() => { closeserachProductModal(); setSearchitem(""); }}></button>
                  </div>
                  <div className="mb-3 ms-2 me-2">
                    <div className="search secondary-text-1">
                      <div  >
                        <span className="primary-text ff-poppins-semiBold">  <IoSearch />   </span>
                        <input type="text" placeholder="Search With the product" className="secondary-text-1 ff-poppins-regular fs-14" value={searchitem} onChange={(e) => setSearchitem(e.target.value)} />
                      </div>
                    </div>

                  </div>
                  {
                    menuStatus == "Food" ?
                      <div className="d-flex fs-14 ff-poppins-semiBold mb-2">
                        <div className="d-flex ms-2" onClick={handleVegToggle}>
                          <span>Veg</span>
                          <Toggle data={vegIcon} color="veg" isSelected={isVegSelected} />
                        </div>
                        {
                          isVegSelected ? <div className="d-flex" onClick={handleEggToggle}>
                            <span>Egg</span>
                            <Toggle data={eggIcon} color="egg" isSelected={isEggSelected} />
                          </div> : ""
                        }

                        <div className="d-flex" onClick={handleNonVegToggle}>
                          <span>Non-veg</span>
                          <Toggle data={nonvegIcon} color="nonveg" isSelected={isNonVegSelected} />
                        </div>

                      </div> :
                      ""
                    // <div className=" ReactLoading">
                    //   <p className="p-5 text-center secondary-text-1 ff-poppins-regular fs-14"> No Products  </p>
                    // </div>
                  }
              
                      <div>
                        <div className="p-3">
                          <h6 className="ff-poppins-regular secondary-text-1">Product</h6>
                        </div>
                        <div className="padding_Bottom">
                          {
                            filteredItems.length > 0 || searhModelItems.length > 0 ?
                              filteredItems?.map((item, itemIndex) => (
                                <div key={itemIndex} style={{ zIndex: "6666" }}>
                                  <div className="productDiv shadow ">
                                    <div className="col-3 ">
                                      <div className="imgBox productImageDiv">
                                        <img src={item.cover_image.image == "" ? defaultimg : item.cover_image.image_url} className="productImage" />

                                      </div>
                                    </div>
                                    <div className="col-6 ps-2">
                                      <div className="ff-poppins-semiBold fs-12">
                                        {item?.food_type !== '0' ?
                                          <img src={item.food_type == "1" ? vegIcon : item.food_type == "2" ? nonvegIcon : item.food_type == "3" ? eggIcon : ""} height="13px" width="13px" className="me-2" />
                                          : ""}
                                        {item.product_name}
                                      </div>
                                      <div>
                                        {item.applied_card_status == "1" ? (
                                          <div className="d-flex ">
                                            <div className="ff-poppins-semiBold d-flex  ">
                                              <span className="fs-11"><FaRupeeSign /> </span>  <div className="fs-12">{item.price} </div>
                                            </div>
                                            <span className="primary-text fs-10">
                                              ({item.benefit_lable}){" "}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="ff-poppins-semiBold d-flex ">
                                            <span className="fs-11"><FaRupeeSign /> </span>  <div className="fs-12">{item.price} </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="ff-poppins-regular primary-text fs-10 primary-text">
                                        {item.unit_quantity} {item.unit_type_name}
                                      </div>
                                      <div className="moreDetails primary-text">
                                        <div className="accordion-item ">
                                          <h2 className="moreInfo fs-14" id={"headingSubOne"}>
                                            <button className="moreInfobtn ff-poppins-regular fs-10 px-0" type="button"
                                              onClick={() => navigate(`/singleProductDetails/${merchantId.vendor_id}/${item.product_id}`, { state: { productId: item.product_id, merchantId } })}  >
                                              More Details
                                              <BsChevronDown />
                                            </button>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-3 d-flex justify-content-center align-items-center primary-text">
                                      <div className="ff-poppins-regular fs-9 primary-text ">
                                        {item.added_count > 0 ? (
                                          <div>
                                            <div className="row align-items-center w-100 gx-0">
                                              <div className="col-4">
                                                <FiMinusCircle
                                                  className="w-100 fs-24 primary-text"
                                                  onClick={() => Add_Remove_productcount(item.product_id, "remove", item.price)} />
                                              </div>
                                              <div className="col-4 d-flex justify-content-center align-items-end fs-20 primary-text">
                                                {item.added_count}
                                              </div>
                                              <div className="col-4">
                                                <LuPlusCircle className={`w-100 primary-text fontSizeCountIcon ${card?.offer_type == "4" ? item.added_count == "1" ? "opacity_Cls rounded-circle" : "" : item.added_count == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                                  onClick={() => {
                                                    setSelectItem(item)
                                                    if (item?.addons == "" && item.added_addons == "") {
                                                      Add_Remove_productcount(item.product_id, "add")
                                                    }

                                                    else if (card?.offer_type == "4") {
                                                      if (item.added_count == "1") {
                                                        toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-custom-style toast-style-1", })
                                                      }
                                                    }

                                                    else if (item.added_addons != "") {
                                                      item.added_count == item.max_allowed_quantity ?
                                                        toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-custom-style toast-style-1", })
                                                        : openEditAddonModal(item.addons, item.added_addons, item.product_id, item.added_count)
                                                    }
                                                  }} />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div>
                                            <div className="col-4 selectBtnProductDiv">
                                              <button className={`selectBtnProduct primary-text fs-12 ff-poppins-semiBold ${productid == item.product_id ? "loading" : ""}`}
                                                onClick={() => {
                                                  item?.addons == "" ? Add_Remove_productcount(item.product_id, "add") : openAddonModal(item.product_id);
                                                  setProductCost(item?.price);
                                                }} >
                                                <span className="fs-12">Select</span>
                                              </button>

                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                              :
                              <div className=" ReactLoading">
                                <p className="p-5 text-center secondary-text-1 ff-poppins-regular fs-14"> No Products  </p>
                              </div>
                          }
                        </div>
                      </div>
                      
                </div>



              </div>
            </div >
          </div>
          :
          <div className=" ReactLoading">
            <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
          </div>
      }

    </>
  );
};

export default Products;
