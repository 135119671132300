import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import axiosInstance, { Razorpay_Key } from "../../Services/axios";
import "../Membership/membershipList.css";
import useRazorpay from "react-razorpay";
import { useUser } from "../../Components/UserContext";
import { getDatabase, ref, onValue } from "firebase/database";
import Lottie from "lottie-react";
import SuccessLotie from "../../Assets/lottieFiles/success.json";
const BuyNow = () => {
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const currentUser = useUser(); // Access user context
  const [showContent, setShowContent] = useState(false);
  const location = useLocation();
  const [membershipDetails, setMembershipDetails] = useState([]);
  const [membershipCheckout, setMembershipCheckout] = useState();
  // console.log("PurchaseID, flowParent, vendorId, membership_ID.................",PurchaseID, flowParent, vendorId, membership_ID)
  const { PurchaseID, flowParent, vendorId, membership_ID } =
    location.state || {};
  const [userId, setUserId] = useState();
  const [offertime, setOffertime] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cartID, setCartID] = useState("");
  const [animationSrc, setAnimationSrc] = useState(null);
  const [orderDetail, setOrderDetail] = useState();
  const [paymentOrderId, setPaymentOrderId] = useState();
  const paymentSuccessModalRef = useRef(null);
  const orderSuccessModalRef = useRef(null);
  const toggleContent = () => {
    setShowContent(!showContent);
  };
  const [isLoading, setLoadingStatus] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const isItemOpen = (index) => openIndex === index;

  const element = document.getElementById("TermsAndCondition");

  function openBookForModal() {
    const element = document.getElementById("TermsAndCondition");
    if (element) {
      element.classList.add("show");
    }
  }
  function closeBookForModal() {
    const element = document.getElementById("TermsAndCondition");
    if (element) {
      element.classList.remove("show");
    }
  }

  useEffect(() => {
    membershipdetail();
    checkout();
  }, []);

  const membershipdetail = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        membership_id: membership_ID,
      };
      axiosInstance
        .post("/user/membership/get_vendor_membership_dateil", reqData)
        .then((res) => {
          setMembershipDetails(res.data.membership_detail);
          setOffertime(res.data.membership_detail.membership_availability);
        });
    } catch (error) {
      console.log("error --->", error);
    }
  };

  const checkout = () => {
    try { 
      let reqData = {
        vendor_id: vendorId,
        membership_id: membership_ID,
      }; 
      axiosInstance
        .post("/user/membership/membership_review_checkout", reqData)
        .then((res) => { 
          setMembershipCheckout(res.data);
          setCartID(res.data.cart_id);
          setPageLoaded(true);
        });
    } catch (error) {
      console.log("error --->", error);
    }
  };

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(
          "https://lottie.host/24705db4-5a9c-4d93-9c1b-217adf1bf628/z8KgTNPh81.json"
        );
        const animationData = await response.json();
        setAnimationSrc(animationData);
      } catch (error) {
        console.error("Error fetching animation data:", error);
      }
    };

    fetchAnimationData();
  }, []);

  const handlePayment = useCallback(
    (userData, orderData) => {
      const createOrderAndInitializePayment = async () => {
        const options = {
          key: Razorpay_Key,
          amount: orderData.amount,
          currency: orderData.currency,
          name: "PINGLE",
          description: "Payment",
          image: "https://pingle.in/user/assets/images/LogoUser.png",
          order_id: orderData.id,
          handler: (res) => { 

            // if (onPayment) {
            //     onPayment(res); // Passing the order ID to the parent component
            // }
          },
          prefill: {
            name: userData.name,
            email: userData.email,
            contact: userData.phone,
          },
          theme: {
            color: "#4E26A3",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      };

      createOrderAndInitializePayment();
    },
    [Razorpay]
  );

  const handleOkClick = () => {
    setShowModal(false);
    navigate("/VendorMembership", {
      state: {
        vendorId: vendorId,
      },
    });
  };

  // useEffect(() => {}, [
  //   flowParent,
  //   vendorId,
  //   membership_ID,
  //   PurchaseID,
  //   cartID,
  // ]);

  const confirmPayment = async () => {
    try {
      let reqData;
      let endpoint;

      if (flowParent === "extend") {
        setLoadingStatus(true);
        reqData = {
          vendor_id: vendorId,
          membership_id: membership_ID,
          membership_purchase_id: PurchaseID,
        };

        endpoint = "/user/membership/confirm_extend_membership_payment";
      } else {
        setLoadingStatus(true);
        reqData = {
          vendor_id: vendorId,
          membership_id: membership_ID,
          cart_id: cartID
        };
        endpoint = "/user/membership/confirm_payment_checkout";
      }

      const res = await axiosInstance.post(endpoint, reqData);
      const data = res.data;

      let userDetatil = {
        name: "",
        phone: "",
        email: "",
      };
      setPaymentOrderId(data.payment_recipt.id);
      setUserId(data.user_id);
      handlePayment(userDetatil, data.payment_recipt);
      // handleRazorPayPayment(paymentDetails);
      // setOrderDetail(data.payment_recipt);
    } catch (error) {
      console.log("Error:", error);
    }

  };

  useEffect(() => {
    if (!currentUser) return;

    const fetchPaymentStatus = async () => {
      try {
        const database = getDatabase();
        const orderRef = ref(database, `/payments/membership/${userId}`);
        const unsubscribe = onValue(orderRef, (snapshot) => {
          const data = snapshot.val();
          if (data && data.status === 2 && data.order_id === paymentOrderId) {
            openPaymentSuccessModal();
          }
        });
        return () => unsubscribe(); // Detach listener on cleanup
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };
    fetchPaymentStatus();
  }, [currentUser, paymentOrderId]);

  const openPaymentSuccessModal = () => {
    paymentSuccessModalRef.current.classList.add("show");
    paymentSuccessModalRef.current.style.display = "block";
  };

  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="">
      {pageLoaded ? (
        <>
          <div className="buyMain">
            <div className="box1 ">
              <div>
                <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                  <AiOutlineClose
                    // onClick={() => navigate(-1)}
                    className="ms-3 mt-3 fs-3"
                  />
                </div>
                <div className="p-4">
                  <div>
                    <h5 className="mb-3">
                      {membershipCheckout?.membership_detail.membership_name}
                    </h5>

                    <div className="ps-4">
                      <p className="m-0">
                        <span className="primary-text pe-2">
                          <BsCheckCircle />
                        </span>
                        {
                          membershipCheckout?.membership_detail
                            .membership_type_lable
                        }
                      </p>
                      <p className="">
                        <span className="primary-text pe-2">
                          <BsCheckCircle />
                        </span>
                        {
                          membershipCheckout?.membership_detail
                            .payment_type_lable
                        }
                      </p>
                    </div>
                  </div>

                  {membershipDetails ? (
                    <div className="border border-secondary-subtle rounded-4 m-0 px-4 py-1 mt-4 mb-5">
                      <p className="m-0">
                        {membershipDetails.price_duration} month
                      </p>
                      <p className="primary-text fs-15 ff-poppins-bold m-0">
                        ₹ {membershipDetails.price}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}

                  {membershipCheckout ? (
                    <>
                      <div class="accordion py-2" id="accordionExample-2">
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button class={`accordion-button accordion-buttonMembership ff-poppins-semiBold ${isItemOpen(0) ? "" : "collapsed"}`} type="button" aria-expanded={isItemOpen(0) ? "true" : "false"} onClick={() => toggleAccordion(0)}   >
                              Billing Details
                              <span className={`accordion-icon  text-white ${isItemOpen(0) ? "up" : "down"}`}   ></span>
                            </button>
                          </h2>
                          <div id="collapseOne" className={`accordion-collapse collapse ${isItemOpen(0) ? "show" : ""}`} data-bs-parent="#accordionExample-2" >
                            <div class="accordion-body">
                              <p>
                                Membership subscription initiated at{" "}
                                {formatDate(membershipCheckout.initiate_date)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="buyvalied">
                        <p className="m-0 py-2">
                          Validity Expires on{" "}
                          {formatDate(membershipCheckout.expiry_date)}
                        </p>
                      </div>
                      <div className="buyvalied d-flex justify-content-between py-2">
                        <p className="m-0 ff-poppins-semiBold">Amount to pay</p>
                        <p className="m-0">
                          ₹{membershipCheckout.payment_amount}
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="box2">
              <div className="px-4 pt-5 ">
                <button
                  onClick={() => confirmPayment()}
                  className={
                    (isLoading ? "loading w-50" : "") +
                    " " +
                    "payBtn w-100 rounded-pill primary-bg text-light border border-0 p-2"
                  }
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="loader-style-1"></span>
                  ) : (
                    <span> Pay Now</span>
                  )}
                </button>
                <p className="p-4 ff-poppins-regular fs-14">
                  By Continuing,you agree to our
                  <span
                    className="primary-text px-1"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottomDispute"
                    aria-controls="offcanvasBottom"
                  >
                    privacy policy
                  </span>
                  and
                  <span
                    className="primary-text px-1"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottomDispute"
                    aria-controls="offcanvasBottom"
                  >
                    terms of use
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* <div>
                    <p className="primary-text text-center">Raise Dispute</p>
                </div> */}

          <div
            class="offcanvas"
            tabindex="-1"
            id="offcanvasBottomDispute"
            aria-labelledby="offcanvasBottomLabel"
            style={{
              width: 390,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              maxHeight: "80vh",
            }}
          >
            <div class="offcanvas-body small">
              <div>
                <div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 ff-poppins-semiBold mb-3">Terms of Use</p>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <p className="m-0 ff-poppins-regular">
                    Thank your for choosing membership. By using our services
                    and product, you are agreeing to comply with and be bound by
                    the following terms and conditions of use.
                  </p>
                  <ol>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                  </ol>
                </div>
                <div>
                  <p className="m-0 ff-poppins-semiBold mb-3">Privacy Policy</p>
                  <p className="m-0 ff-poppins-regular">
                    Thank your for choosing membership. By using our services
                    and product, you are agreeing to comply with and be bound by
                    the following terms and conditions of use.
                  </p>
                  <ol>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                    <li>
                      The contesnt of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notics.
                    </li>
                  </ol>
                </div>
                <div>
                  <p className="m-0 ff-poppins-regular">
                    By using our services and product, you consent to the
                    collection and use of your personal information as described
                    in this privacy policy.
                  </p>
                </div>
                <div className="mb-5 pb-5">
                  <p className="m-0 ff-poppins-regular">
                    Please note that these terms and policies may be subject to
                    change. We recommend reviewing them periodically to stay
                    updated.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Lottie Modal */}
          <div className="">
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              ref={paymentSuccessModalRef}
              data-bs-backdrop="false"
            >
              <div className="modal-dialog modal-dialog-centered mainModal d-flex justify-content-center">
                <div
                  className="modal-content modal-success"
                  style={{ width: "80%" }}
                >
                  <div className="modal-header border-0">
                    <div>
                      <div>
                        {animationSrc && (
                          <div>
                            <Lottie
                              animationData={SuccessLotie}
                              background="black"
                              loop={false}
                              dt
                            />
                            {/* <dotlottie-player
                        src="https://lottie.host/20bac73e-8f95-48ee-9674-5377bb1c10fe/kSb8uFf6CF.lottie"
                        background="transparent"
                        speed="1"
                        style={{ width: 300, height: 300 }}
                        loop
                        autoplay
                        playMode={""}
                      ></dotlottie-player> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-body modal-bodyRP">
                    <div className="bookSentTextRP ff-poppins-bold">
                      PAYMENT SUCCESSFUL
                    </div>
                    <div className="bookSentTextRP ff-poppins-regular">
                      Redirect to your membership
                    </div>
                    <div></div>
                  </div>
                  <div className="modal-footer border-0">
                    <button
                      type="button"
                      className="btn okBtnRP"
                      onClick={() => {
                       
                        setShowModal(false);
                        navigate("/VendorMembership", {
                          state: {
                            vendorId: vendorId,
                          },
                        });
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BuyNow;
