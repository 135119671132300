import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import p_search_location from "../../Assets/Images/p_search_location.svg";
import p_store_dropdown_arrow from "../../Assets/Images/p_store_dropdown_arrow.svg";
import React, { useState, useEffect, useRef } from "react";
import { BiSolidOffer } from "react-icons/bi";
import { MdCardMembership } from "react-icons/md";
import "./searchStore.css";
import { IoSearch } from "react-icons/io5";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import {
  addRecentLocation,
  addSearchHistory,
  clearSearchHistory,
  getLocationTypes,
  getSearchFilter,
  getSearchHistory,
  getSearchVendor,
} from "../../Services/api";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Services/axios";
import OfferSearch from "../../Components/offCanvas/offerSearch";

import membership_icon from '../../Assets/Images/icons/membership_card.png';
import offer_icon from '../../Assets/Images/icons/offer_icon.png';
import MembershipSearch from "../../Components/offCanvas/membershipSearch";
import { useGeolocated } from "react-geolocated";
import { MdCancel } from "react-icons/md";
const SearchStore = () => {

  const rescheduleModalRef = useRef(null);
  const membershipSearchModalRef = useRef(null)
  const offerSearchModalRef = useRef(null)
  const searchSelectedModel = useRef(null);
  const [value, setValue] = useState("");
  const [showLocations, setShowLocations] = useState(false);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [searchHistoryList, setSearchHistoryList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorFilteredList, setVendorFilteredList] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const { from_Page } = location.state || {};
 

  // Filters
  const [fil_location, set_fil_location] = useState();
  const [fil_name, set_fil_name] = useState("");
  const [fil_type, set_fil_type] = useState(-1);
  const [fil_category, set_fil_category] = useState(0);
  const [vendor_category_list, set_vendor_category_list] = useState([]);
  const [locationModel, setLocationModel] = useState('1')
  const valueParam = useParams();
  const merchantId = valueParam;
  const [filteredItems, setFilteredItems] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const geocoder = new window.google.maps.Geocoder();
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  const [city, setCity] = useState("");
  useEffect(() => {
    getSearchFilter().then((res) => {
      res.data.vendor_type.map((item) => {
        item.isSelected = false;
      });
      setVendorTypes(res.data.vendor_type);
      set_fil_location(res.data.selected_location);
    });
    getRecentSearchHistory();
    // if (from_page == "serarch_offer") {
    //   openOfferSearchModal()
    // }
  }, [value, locationModel]);

  useEffect(() => {
    if (fil_type != -1) {
      getVendor();
    }

  }, [fil_location, fil_name, fil_type, fil_category, locationModel]);
  useEffect(() => {
    if (from_Page == "Member_ship") {
      openMembershipSearchModal()
    }else if(from_Page == "offers"){
      openOfferSearchModal()
    }
  }, [from_Page])
  function getRecentSearchHistory() {
    getSearchHistory().then((res) => {
      if (res.data.search_count > 0) {
        // setSearchHistoryList(res.data.search_results);
        const newSearches = res.data.search_results.filter((item) =>
          item.search_name.toLowerCase().includes(fil_name.toLowerCase())
        );
        setSearchHistoryList(newSearches);
      }
    });
  }

  // function searchVendorByName(e) {

  //   if (fil_type == -1) {
  //     set_fil_type(0);
  //   }
  //   set_fil_name(e.target.value);
  //   getRecentSearchHistory();
  // }

  const handleInputSearchChange = (event) => {
    setInputSearchValue(event.target.value);
    // searchVendorByName(event);
  };

  function searchVendorByName(e) {
    const searchText = e.target ? e.target.value : e;
    setInputSearchValue(searchText);

    if (fil_type === -1) {
      set_fil_type(0);
    }

    set_fil_name(searchText);

    // Update searchHistoryList with the new search text
    if (
      searchText !== "" &&
      !searchHistoryList.some((item) => item.search_name === searchText)
    ) {
      const newSearchHistoryList = [...searchHistoryList];
      newSearchHistoryList.unshift({ id: Date.now(), search_name: searchText });
      // setSearchHistoryList(newSearchHistoryList);
    }
    if (searchText == '') {
      setInputSearchValue('');
    }
  }

  function getVendor() {
    let param = {
      user_location: fil_location?.latlng,
      search_name: fil_name,
      search_type: fil_type,
      search_category: fil_category,
    };
    getSearchVendor(param).then((res) => {
      setShowLocations(true);
      setVendorList(res.data.search_results);

      if (fil_type == 1) {
        setVendorFilteredList(res.data.search_results);
      } else {
        setVendorFilteredList([]);
      }
    });
  }

  function selectCategory(item) {
    vendorTypes.map((obj) => {
      obj.isSelected = false;
    });

    let obj = vendorTypes.find((x) => x.vendor_type_id == item.vendor_type_id);

    if (obj) {
      let index = vendorTypes.indexOf(obj);
      vendorTypes.fill((obj.isSelected = !obj.isSelected), index, index++);

      forceUpdate();
    }
  }

  const clearAllSearches = () => {

    clearSearchHistory().then((res) => {

      if (res.data?.status == '1') {
        setSearchHistoryList([]);
      }
    });
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    // const checkedValues = checkboxes.filter(checkbox => checkbox.checked);
    // console.log('Checked checkboxes:', checkedValues);
    // You can perform further actions here, such as sending data to a server
  };

  const handleCheckboxChange = (index) => {
    set_vendor_category_list(prevCheckboxes => {
      const updatedCheckboxes = [...prevCheckboxes];
      updatedCheckboxes[index] = { ...updatedCheckboxes[index], checked: !updatedCheckboxes[index].checked };
      filterVendorsByCategory(updatedCheckboxes);
      return updatedCheckboxes;
    });
  };

  const filterVendorsByCategory = (category) => {
    const filterTypeIds = category
      .filter(obj => obj.checked == true)
      .map(obj => obj.vendor_category_id);

    const filteredObjects = vendorFilteredList.filter(obj => filterTypeIds.includes(obj.vendor_cat_id));

    if (filteredObjects.length > 0) {
      setVendorList(filteredObjects);
    } else {
      setVendorList(vendorFilteredList);
    }
  }

  useEffect(() => {

  }, [vendor_category_list]);

  useEffect(() => {
    if (vendorTypes.length > 0) {
      vendorTypes.map(x => {

        if (x?.vendor_categories?.length > 0) {
          x?.vendor_categories?.map(y => {
            y.checked = false;
          });
          set_vendor_category_list(x?.vendor_categories)
        }
      })

    }

  }, [vendorTypes]);

  function openVendorProfile(vendorId) {
    let param = {
      "search_vendor_id": vendorId
    }
    addSearchHistory(param).then((res) => {

      if (res.data?.status == '1') {
        navigate(`/vendor/${vendorId}`);
      }
    });
  }

  const openOfferSearchModal = () => {
    offerSearchModalRef.current.classList.add("show");
    offerSearchModalRef.current.style.display = "block";
  };

  const closeOfferSearchModal = () => {
    offerSearchModalRef.current.classList.remove("show");
  };

  const openMembershipSearchModal = () => {
    membershipSearchModalRef.current.classList.add("show");
    membershipSearchModalRef.current.style.display = "block";
  };

  const closeMembershipSearchModal = () => {
    membershipSearchModalRef.current.classList.remove("show");
  };
  // const openrescheduleModal = () => {
  //   rescheduleModalRef.current.classList.add("show");
  //   rescheduleModalRef.current.style.display = "block";
  // };

  // const closerescheduleModal = () => {
  //   rescheduleModalRef.current.classList.remove("show");
  //   rescheduleModalRef.current.style.display = "none";
  //   setLocationModel('1')
  // };
  // const openPermissonModal = () => {
  //   rescheduleModalRef.current.classList.add("show");
  //   rescheduleModalRef.current.style.display = "block";
  // };

  // const closePermissonModal = () => {
  //   rescheduleModalRef.current.classList.remove("show");
  //   rescheduleModalRef.current.style.display = "none";
  //   setLocationModel('1')
  // };

  // const fetchAddressFromCoordinates = async (latitude, longitude, apiKey) => {
  //   try {
  //     const response = await fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
  //     );

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();

  //     if (data.status === 'OK') {
  //       const address = data.results[0].formatted_address;
  //       const addressComponents = data.results[0].address_components;
  //       let city = '';

  //       for (let component of addressComponents) {
  //         if (component.types.includes("locality")) {
  //           city = component.long_name;
  //           break;
  //         } else if (component.types.includes("administrative_area_level_2")) {
  //           city = component.long_name;
  //         }
  //       }
  //       console.log('city===>>>', city);
  //       return { address, city };
  //     } else {
  //       throw new Error(`Geocoding API error: ${data.status}`);
  //     }
  //   } catch (error) {
  //     throw new Error(`Failed to retrieve address: ${error.message}`);
  //   }
  // };
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
  //   positionOptions: {
  //     enableHighAccuracy: false,
  //   },
  //   userDecisionTimeout: 5000,
  // });

  // useEffect(() => {
  //   const getAddress = async () => {
  //     if (coords) {
  //       try {
  //         const { address, city } = await fetchAddressFromCoordinates(coords.latitude, coords.longitude, 'AIzaSyC7cC8d67-AjIHnXizRFxTj1uFkOwnF2zo');
  //         setAddress(address);
  //         setCity(city);
  //       } catch (error) {
  //         setError(error.message);
  //       }
  //     }
  //   };

  //   getAddress();
  // }, [coords]);

  // useEffect(() => {
  //   if (locationModel == "1") {
  //     openrescheduleModal()
  //   }
  // }, [locationModel, address])
  // const handelLocation = () => {
  //   let param = {
  //     "latlng": coords.latitude + ',' + coords.longitude,
  //     "select_address": address,
  //     "select_locality": city
  //   }
  //   console.log('resssss', param);
  //   addRecentLocation(param).then((res => {
  //     if (res.data?.status == '1') {
  //       getSearchFilter()
  //     }
  //   }))

  //   closerescheduleModal()
  // }
  return (
    <>
      <div className="searchStore offcanvas-parentType2">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div className="d-flex justify-content-between align-items-center ms-2 me-2">
            <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => navigate("/")} />
            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Search</div>
          </div>
          <Link className="text-decoration-none" to={`/find-location`}>
            <div className="location-label d-flex align-items-center justify-content-center bg-white">
              <img src={p_search_location} width={"14px"} height={"14px"} alt="Location" />
              <div className="ff-poppins-bold fs-13 ms-1  text-truncate secondary-text-2">
                {fil_location?.address}
              </div>
            </div>
          </Link>
        </div>

        <div className="p-3">
          <input type="search" className="w-100 ff-poppins-semiBold fs-16" placeholder="Search store by name" onChange={searchVendorByName} value={inputSearchValue} />

          <div className="d-flex justify-content-around align-items-center p-3">
            <div className=" align-items-center text-center" onClick={() => { openOfferSearchModal() }}>
              <img src={offer_icon} width={"40px"} height={"40px"} />
              <div className="ff-balooPaaji2-bold fs-16 mt-1">OFFER</div>
            </div>
            <div className=" align-items-center text-center" onClick={() => { openMembershipSearchModal() }}>
              <img src={membership_icon} width={"40px"} height={"40px"} />
              <div className="ff-balooPaaji2-bold fs-16 mt-1">MEMBERSHIP</div>
            </div>
          </div>

          <div className="searchFilter scroller-style-x mt-4 mb-4">
            {vendorTypes.map((item) => {

              if (item.vendor_categories.length > 1) {
                return (
                  <div className={(item.isSelected ? "active " : " ") + "d-flex align-items-center"}
                    role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                    onClick={() => {
                      set_fil_type(item.vendor_type_id);
                      if (fil_type != item.vendor_type_id) { set_vendor_category_list(item.vendor_categories); } selectCategory(item);
                    }}  >
                    {item.vendor_type_name}
                    {item.vendor_categories.length > 1 && (
                      <img src={p_store_dropdown_arrow} width={"10px"} height={"10px"} alt="Location" className="ms-2" />)}
                  </div>
                );
              } else {
                return (
                  <div className={(item.isSelected ? "active " : " ") + "d-flex align-items-center"} role="button"
                    onClick={() => { set_fil_type(item.vendor_type_id); selectCategory(item); }}  >
                    {item.vendor_type_name}
                  </div>
                );
              }
            })}
          </div>
          {showLocations ? (
            <div className="recentSearch">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="ff-poppins-semiBold fs-16">Location</div>
                  <div className="ff-poppins-thin fs-18 secondary-text-2">
                    result
                  </div>
                </div>
                <button>
                  <div className="ff-poppins-light fs-11" onClick={() => setShowLocations(false)}  >
                    Recent Search
                  </div>
                </button>
              </div>

              <div className="storeList scroller-style-y">
                {vendorList.length > 0 ? (
                  vendorList.map((item) => {
                    return (
                      <div className="storeCard bg-white d-flex mb-3" role="button" onClick={() => openVendorProfile(item.vendor_id)}>
                        <img src={item.vendor_logo} width={"81px"} height={"81px"} className="userImg" />
                        <div>
                          <div className="ff-balooPaaji2-medium fs-17">
                            {item.vendor_company_name}
                          </div>
                          <div className="d-flex">
                            <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                              <div className="bullets me-2"></div>
                              MID {item.vendor_id}
                            </div>
                            <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                              <div className="bullets me-2"></div>
                              {item.vendor_cat_name}
                            </div>
                          </div>
                          <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                            <div className="bullets me-2"></div>
                            {item.vendor_address}
                          </div>
                          <div className="ff-poppins-regular fs-9">
                            {item.distance} -
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="ff-poppins-light fs-13">No Data Found</div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="recentSearch">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="ff-poppins-semiBold fs-16">Recent</div>
                  <div className="ff-poppins-thin fs-18 secondary-text-2">
                    searches
                  </div>
                </div>
                <button onClick={clearAllSearches}>
                  <div className="ff-poppins-light fs-11">Clear all</div>
                </button>
              </div>

              {searchHistoryList.length > 0 ? (
                <div className="list scroller-style-y">
                  <ul className="ps-0 mb-0">
                    {searchHistoryList.map((item) => {
                      return (
                        <li role="button" className="ff-poppins-regular fs-15 p-3 border-bottom" onClick={() => {
                          setInputSearchValue(item.search_name); searchVendorByName(item.search_name);
                        }}>
                          {item.search_name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className="list scroller-style-y d-flex justify-content-center align-items-center">
                  <div className="ff-poppins-light fs-13">No Recent Result</div>
                </div>
              )}
            </div>
          )}

          <div className="offcanvas serachFilter " style={{
            width: "-webkit-fill-available", borderTopLeftRadius: 10, borderTopRightRadius: 10, marginLeft: -15,
          }} tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel"  >
            {" "}
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasBottomLabel">
                Filter
              </h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"  ></button>
            </div>
            <div className="offcanvas-body small">
              {/* {vendor_category_list.map((item) => {
                return (
                  <div className="d-flex justify-content-between mt-1 mb-1">
                    <div className="ff-poppins-medium fs-14">
                      {item.vendor_category_name}
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value=""  />
                    </div>
                  </div>
                );
              })} */}

              <form onSubmit={handleSubmit}>
                {vendor_category_list.map((checkbox, index) => (
                  <div className="d-flex justify-content-between mt-1 mb-1" key={checkbox?.vendor_category_id} role="button">
                    <label className="ff-poppins-medium fs-14" role="button"
                      htmlFor={`checkbox-${checkbox?.vendor_category_id}`}>
                      {checkbox?.vendor_category_name}
                    </label>
                    <div className="form-check">
                      <input
                        role="button"
                        type="checkbox"
                        id={`checkbox-${checkbox?.vendor_category_id}`}
                        checked={checkbox?.checked}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </div>

                  </div>
                ))}
                {/* <button type="submit">Submit</button> */}
              </form>

              <hr />

              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <div
                      role="button"
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        vendor_category_list?.map(y => {
                          y.checked = false;
                        });
                        set_vendor_category_list(vendor_category_list);
                        setVendorList(vendorFilteredList);
                      }}
                      className="ff-poppins-medium fs-14 text-danger d-flex justify-content-center align-items-center btn-type-1">
                      Clear All
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      role="button"
                      data-bs-dismiss="offcanvas"
                      className="ff-poppins-medium fs-16 d-flex justify-content-center align-items-center primary-bg text-white btn-type-1">
                      Apply
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="offcanvas offcanvas-bottom"
          style={{
            width: "-webkit-fill-available",
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            height: '110dvh',
            bottom: 0,
            marginBottom: 0,
            backgroundColor: '#fff'
          }}
          ref={offerSearchModalRef} tabIndex="-1">
          <div type="button" className="d-flex justify-content-end ff-poppins-regular mt-1 fs-12 secondary-text-1 me-2" onClick={() => { closeOfferSearchModal() }} >Close </div>
          <div className="offcanvas-body small">
            <OfferSearch offer_from_Page={""} ></OfferSearch>
          </div>
        </div>

        {/* <div class="modal fade " id="exampleModalToggle" tabindex="-1" ref={rescheduleModalRef}>
          <div class="modal-dialog modal-dialog-centered mainModal " >
            <div class="modal-content mx-5   bg-white">
              <div className=" p-3 m-0">
                <div className="d-flex justify-content-between">
                  <p className=" ff-poppins-bold text-dark m-0">Current Location:</p>
                  <p onClick={() => closerescheduleModal()}> <MdCancel /> </p>
                </div>
                {
                  address == "" ?
                    <p> Please Give the Location Permission</p>
                    :
                    <p className="secondary-text  ">
                      {address && <p className="d-flex justify-content-center ff-poppins-regular m-0 fs-13"> {address}</p>}
                    </p>
                }

              </div>
              <div className="d-flex justify-content-center mb-3 fs-12 text-center ff-poppins-bold">
                <div class="primary-bg  col-5 selectLocationBtn rounded-3  text-light p-1" onClick={() => handelLocation()} >Confirm Location</div>
                <div class="primary-bg  col-5 ms-3 selectLocationBtn rounded-3 text-light p-1" onClick={() => navigate("/find-location",{ state: { fromPage: "SelectAddres" } })}  >Enter Location Manually </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="offcanvas offcanvas-bottom"
          style={{
            width: "-webkit-fill-available",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            height: '110dvh',
            bottom: 0,
            marginBottom: 0,
            backgroundColor: '#fff'
          }}
          ref={membershipSearchModalRef}
          tabIndex="-1">


          <div type="button" className="d-flex justify-content-end ff-poppins-regular mt-1 fs-12 secondary-text-1 me-2" onClick={() => { closeMembershipSearchModal() }} >Close </div>

          <div className="offcanvas-body small">
            <MembershipSearch Membership_from_Page={"search_page"} ></MembershipSearch>
          </div>
        </div>
      </div >
    </>
  );
};

export default SearchStore;
