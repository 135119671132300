import React, { useEffect, useRef, useState } from "react";
import DatePickerOffCanvas from "../offCanvas/datePickerOffCanvas";
import DatePicker from 'react-datepicker';
import axiosInstance from "../../Services/axios";
import ReviewPay from "../../Pages/Products/Reviewpay";
import { toast } from "react-toastify";

const DeliveryTime_Slot = ({ isOpen, toggleOffCanvas }) => {
    const locationModalRef = useRef(null)
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedOption, setSelectedOption] = useState('This week');
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [tempSelectedDate, setTempSelectedDate] = useState(null);
    const [selectedCustomDate, setSelectedCustomDate] = useState(null);
    const [isReviewAndConfirmOffCanvasOpen, setReviewAndConfirmOffCanvasOpen] = useState(false);
    const [weekDays, setWeekDays] = useState([])
    const [deliveryHours, setDeliveryHours] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [selectHour, setSelectHour] = useState('')
    const [cartId, setCartId] = useState("");
    const formatDate = (date) => {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    let currentDate = new Date();
    let formattedDate = formatDate(currentDate);
    const [reqDate, setReqDate] = useState(formattedDate)
    const [selectStatus, setSelectStatus] = useState(reqDate)
    useEffect(() => {
        setReviewAndConfirmOffCanvasOpen(selectedTime != '' ? true : false);
    }, [selectedTime]);

    const customDayClass = (date) => {
        const isTodayOrFuture = date >= new Date().setHours(0, 0, 0, 0); // Compare dates without considering time
        const isSelected = date.getTime() === tempSelectedDate?.getTime();
        return isSelected ? 'temp-selected-day' : (isTodayOrFuture ? 'future-day' : 'past-day');
    };
    const handleDayHover = (date) => {
        const isTodayOrFuture = date >= new Date().setHours(0, 0, 0, 0);
        const isPast = date < new Date().setHours(0, 0, 0, 0);
        if (!isTodayOrFuture && !isPast) {
            date.classList.add('hover-day');
        }
    }
    const handleCustomButtonClick = () => {
        setTempSelectedDate(selectedCustomDate); // Set temporary selected date
        setShowCustomDatePicker(true);
        setSelectedOption('Custom');
    };
    const handleCancelClick = () => {
        setShowCustomDatePicker(false);
        // Reset temporary selected date
        setTempSelectedDate(selectedCustomDate);
    };
    const handleOkClick = () => {
        setSelectedCustomDate(tempSelectedDate); // Update selectedCustomDate
        setShowCustomDatePicker(false);
        // getServiceTimeSlotForCustom(tempSelectedDate);  Pass tempSelectedDate to the function
    };
    const handleThisWeekClick = () => {
        setSelectedOption('This week');
        setShowCustomDatePicker(false);
    };

    const selectDeliverySlotTime = () => {
        try {

            let localProductDetail = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";
            let reqData = {
                "vendor_id": localProductDetail?.vendorId,
                "available_date": reqDate
            }
            setSelectStatus(reqData?.available_date)
            axiosInstance.post('/user/get_delivery_time_slots', reqData)
                .then((res) => {
                    setWeekDays(res?.data?.date_details)
                    setDeliveryHours(res?.data?.result)
                    setSelectHour('')
                })
        }
        catch {

        }
    }

    const handelSetTime = () => {
        let productDeliveryTime = {
            "selectHour": selectHour,
            "selectDate": selectStatus
        };
        localStorage.setItem("productDeliveryTime", JSON.stringify(productDeliveryTime));
        setIsLoading(true);

        Select_Delivery_Slot()
        // Simulate asynchronous action with setTimeout
        setTimeout(() => {
            setIsLoading(false);
            handelCloseModel()
        }, 3000);
    }
    const handelCloseModel = () => {
        setReviewAndConfirmOffCanvasOpen(false);
        toggleOffCanvas();
        setSelectHour('');
        setSelectStatus('')
    }
    useEffect(() => {
        selectDeliverySlotTime()

    }, [reqDate])
    const Select_Delivery_Slot = () => {
        try {
            let productCart = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";

            let reqData = {
                "cart_id": productCart?.cartId ? productCart?.cartId : "",
                "order_delivery_time": selectHour,
                "order_delivery_date": selectStatus
            }
            axiosInstance.post('/user/delivery_time_date_update', reqData)
                .then((res) => {
                    console.log('res', res);
                })
        }
        catch {
            toast.error('Try Again later', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }
    console.log("deliveryHours", deliveryHours);

    return (
        <div className={`offcanvas offcanvas-bottom datePickerOffCanvas  ${isOpen ? 'show' : ''}`} style={{ position: 'sticky', zIndex: 9998 }} tabindex="-1" aria-labelledby="selectCustomerModalLabel">
            <div className="offcanvas-body small">
                <div className='d-flex justify-content-center'>
                    <div className='custom-pill'></div>
                </div>

                <div>
                    <div className='d-flex justify-content-between header'>
                        <div className='ff-poppins-regular fs-12'>Pick your preferred date & time</div>
                        <div className='ff-poppins-medium fs-12' role='button' onClick={() => handelCloseModel()}>Cancel</div>
                    </div>

                    <div className='weekDays d-flex'>
                        {
                            weekDays?.map((item, index) => {
                                return (
                                    <div className='item'>
                                        <div className='ff-poppins-medium fs-12 mb-3'>{item.day_first}</div>
                                        <div className={`${item?.date_status == '0' ? "bg-danger text-light" : ""} ${selectStatus == item?.date ? "primary-bg text-light" : ""} date rounded-circle ff-poppins-medium fs-12 d-flex justify-content-center align-items-center`} role='button'
                                            onClick={() => setReqDate(item?.date)}> {item?.day}  </div>
                                    </div>
                                )
                            })}
                    </div>
                    <div className='timeOfDay  scroller-style-y'>

                        {
                            Array.isArray(deliveryHours?.Mor) && deliveryHours.Mor.length > 0 ?
                                <div>
                                    <div className='item d-flex align-items-center mb-3'>

                                        <div className='ff-poppins-medium fs-12 primary-text me-2'>Morning</div>
                                        <hr className='w-100' />
                                    </div>
                                    <div className='timeList d-flex flex-wrap'>
                                        {
                                            deliveryHours?.Mor?.map((item, index) => {

                                                return (
                                                    <div className={`${item.status == '0' ? "secondaryslot-bg" : ""} ${selectHour == item.time ? "primary-bg text-light" : ""} time d-flex justify-content-center align-items-center`} role='button'  >
                                                        <div className='ff-poppins-medium fs-12' onClick={() => {
                                                            if (item.status != '0') {
                                                                setSelectHour(item.time)
                                                            } else {

                                                            }
                                                        }
                                                        }>{item.time}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                : ""

                        }



                        {
                            Array.isArray(deliveryHours?.Ngt) && deliveryHours.Ngt.length > 0 ?
                                <div>
                                    <div className='item d-flex align-items-center mb-3'>
                                        <div className='ff-poppins-medium fs-12 primary-text me-2'>Afternoon</div>
                                        <hr className='w-100' />
                                    </div>
                                    <div className='timeList d-flex flex-wrap'>

                                        {
                                            deliveryHours?.Aft?.map((item, index) => {
                                                return (
                                                    <div className={`${item.status == '0' ? "secondaryslot-bg" : ""} ${selectHour == item.time ? "primary-bg text-light" : ""} time d-flex justify-content-center align-items-center`} role='button'  >
                                                        <div className='ff-poppins-medium fs-12' onClick={() => {
                                                            if (item.status != '0') {
                                                                setSelectHour(item.time)
                                                            } else {

                                                            }
                                                        }
                                                        }>{item.time}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                : ""
                        }

                        {
                            Array.isArray(deliveryHours?.Eve) && deliveryHours.Eve.length > 0 ?
                                <div>
                                    <div className='item d-flex align-items-center mb-3'>
                                        <div className='ff-poppins-medium fs-12 primary-text me-2'>Night</div>
                                        <hr className='w-100' />
                                    </div>
                                    <div className='timeList d-flex flex-wrap'>
                                        {
                                            deliveryHours?.Eve?.map((item, index) => {
                                                return (
                                                    <div className={`${item.status == '0' ? "secondaryslot-bg" : ""} ${selectHour == item.time ? "primary-bg text-light" : ""} time d-flex justify-content-center align-items-center`} role='button'  >
                                                        <div className='ff-poppins-medium fs-12' onClick={() => {
                                                            if (item.status != '0') {
                                                                setSelectHour(item.time)
                                                            } else {

                                                            }
                                                        }
                                                        }>{item.time}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                : ""
                        }

                        {
                            Array.isArray(deliveryHours?.Ngt) && deliveryHours.Ngt.length > 0 ?
                                <div>
                                    <div className='item d-flex align-items-center mb-3'>
                                        <div className='ff-poppins-medium fs-12 primary-text me-2'>Night</div>
                                        <hr className='w-100' />
                                    </div>

                                    <div className='timeList d-flex flex-wrap'>
                                        {
                                            deliveryHours?.Ngt?.map((item, index) => {
                                                return (
                                                    <div className={`${item.status == '0' ? "secondaryslot-bg" : ""} ${selectHour == item.time ? "primary-bg text-light" : ""} time d-flex justify-content-center align-items-center`} role='button'  >
                                                        <div className='ff-poppins-medium fs-12' onClick={() => {
                                                            if (item.status != '0') {
                                                                setSelectHour(item.time)
                                                            } else {

                                                            }
                                                        }
                                                        }
                                                        >{item.time}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                : ""
                        }


                        <div style={{ height: 135 }}></div>
                    </div>
                </div>

                <button style={{ borderRadius: "6px", margin: "15px 0px" }}
                    className={selectHour ? "sendPayLinkBtnRP primary-bg ff-poppins-semiBold" : "border sendPayLinkBtnRP rimary-bg-1 ff-poppins-semiBold secondary-text-2"
                    } onClick={() => { handelSetTime() }}   >
                    {isLoading ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                            <span className="visually-hidden">Loading...</span>
                        </>
                    ) : (
                        "Confirm Delivery time"
                    )}
                </button>
            </div>
            {/* <div className="d-none">
            <ReviewPay data={{ selectStatus, selectHour }} /> 
            </div> */}
        </div>
    )
}
export default DeliveryTime_Slot