import { useRef, useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import message_read from '../../Assets/Images/message_read.svg';
import message_unread from '../../Assets/Images/message_unread.svg';
import messenger_attachment from '../../Assets/Images/messenger_attachment.svg';
import messenger_send from '../../Assets/Images/messenger_send.svg';
import { createNewUserVendorChat, getChatList, sendAttachment, sendChatMessage, userBookingChatDetails, userServiceEnquiryChatDetails, userSingleChatDetailsNew } from '../../Services/api';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Assets/Styles/messenger.css';
import ImageCropperModal from '../../Components/modal/imageCropperModal';
import { IoIosArrowUp } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";

const EnqueryChat = () => {

    const navigate = useNavigate();
    const valueParam = useParams();
    // const [chatDetails, setChatDetails] = useState(null);
    const [serviceEnquiryChatDetail, setServiceEnquiryChatDetail] = useState(null);
    const [singleChatHistoryDetail, setSingleChatHistoryDetail] = useState(null);
    const [messageText, setMessageText] = useState(null);
    const containerRef = useRef(null);
    const [isMessageSending, setMessageSending] = useState(false);
    const [isImageCropperModaOpen, setIsImageCropperModaOpen] = useState(false);
    const [imageToCrop, setImageToCrop] = useState('');
    const fileRef = useRef(null);
    const [openIndices, setOpenIndices] = useState([]);
    useEffect(() => {
        getServiceEnquiryChatDetails()
    }, [])

    // useEffect(() => {

    //     if (chatDetails && chatDetails.status == '1') {
    //         getSingleChatDetails()
    //     }
    // }, [chatDetails])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month}, ${day < 10 ? '0' + day : day} ${year}`;
    };

    function getServiceEnquiryChatDetails() {

        let param = {
            "chat_id": valueParam.enquery_id
        };
        userServiceEnquiryChatDetails(param).then((res) => {
            // Grouping messages by msg_date
            const groupedMessages = res.data?.chat_history?.reduce((acc, message) => {
                const date = message.msg_date;
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(message);
                return acc;
            }, {}); 
            setSingleChatHistoryDetail(groupedMessages);

            setServiceEnquiryChatDetail(res.data);

            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.scrollIntoView({ block: 'end' });
                }
            }, 200);
        });
    }

    function sendMessage() {

        setMessageSending(true);
        let param = {
            "chat_id": valueParam.enquery_id,
            "message": messageText
        }
        sendChatMessage(param).then((res) => {

            if (res.data?.status == '1') {
                setMessageText('');
                getServiceEnquiryChatDetails();
                setMessageSending(false);
            }
        });
    }

    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file);

        if (base64) {
            setImageToCrop(base64);
            setIsImageCropperModaOpen(true);
        }
    }

    const convertBase64 = (file) => {

        if (file) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject('custom error', error);
                }
            })
        }
    }

    const onImageCropped = (e) => {

        if (e) {
            setMessageSending(true);
            const data = new FormData();
            data.append('file', e);
            data.append('chat_id', valueParam.enquery_id);

            sendAttachment(data)
                .then((res) => {

                    if (res.data.status === "1") {
                        getServiceEnquiryChatDetails();
                        setIsImageCropperModaOpen(false);
                    }

                    setMessageSending(false);

                })
                .catch((error) => {
                    // setLoadingStatus(false)
                    // toast(error.message, {
                    //     position: "bottom-center",
                    //     autoClose: 5000,
                    //     hideProgressBar: true,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    //     className: 'text-danger toast-style-1'
                    // });
                    setMessageSending(false);
                });
            setIsImageCropperModaOpen(false);
            clearFileInput();
        }
    }

    const clearFileInput = () => {
        if (fileRef.current) {
            fileRef.current.value = null;
        }
    };
    const isItemOpen = (index) => openIndices.includes(index);
    const toggleAccordion2 = (index) => {
        if (openIndices.includes(index)) {
            setOpenIndices(openIndices.filter((i) => i !== index));
        } else {
            setOpenIndices([...openIndices, index]);
        }
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>
                        Service Enquiry
                    </div>
                </div>
            </div>

            <div className='messengerList scroller-style-y'>

                {
                    serviceEnquiryChatDetail?.chat_count > 0
                        ?
                        <div ref={containerRef} className='p-2 messengerListContainer' style={{ paddingBottom: "3rem !important" }}>
                            {
                                Object.entries(singleChatHistoryDetail).map(([date, messages]) => (
                                    <div key={date}>
                                        {new Date().setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0) && (
                                            <div className='d-flex justify-content-center'>
                                                <div className='chatDayBadge'>Today</div>
                                            </div>
                                        )}
                                        {new Date().setHours(0, 0, 0, 0) !== new Date(date).setHours(0, 0, 0, 0) && (
                                            <div className='d-flex justify-content-center'>
                                                <div className='chatDayBadge'>{formatDate(date)}</div>
                                            </div>
                                        )}

                                        {
                                            messages.map(item => {

                                                if (item.msg_type == "service_enquiry") {
                                                    return (
                                                        <div className='d-flex justify-content-center'>
                                                            <div className='serviceEnquiryDetails mt-2 rounded-2 p-1 mb-2'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className='p-2'>
                                                                        <div className='ff-poppins-semiBold fs-14'>
                                                                            {item?.message?.service_detail?.service_name}
                                                                        </div>
                                                                        <div className='ff-poppins-semiBold fs-14'>
                                                                            Rs.{item?.message?.service_detail?.service_price}
                                                                        </div>
                                                                        <div class="accordion accordion-flush mt-3" id={"accordionFlushExample" + item?.message?.enquery_id}>
                                                                            <div class="accordion-item  bg-transparent">
                                                                                <h2 class="accordion-header bg-transparent" id={"flush-headingOne" + item?.message?.enquery_id}>
                                                                                    {/* <button class="accordion-button p-0  bg-transparent collapsed ff-poppins-regular fs-12 primary-text" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + item?.message?.enquery_id} aria-expanded="false" aria-controls={"flush-collapseOne" + item?.message?.enquery_id}> */}
                                                                                    <button className={`accordion-button p-0  bg-transparent collapsed ff-poppins-regular fs-12 primary-text ${isItemOpen(item?.message?.enquery_id) ? "" : "collapsed"}`} type="button"
                                                                                        onClick={() => toggleAccordion2(item?.message?.enquery_id)} aria-expanded={isItemOpen(item?.message?.enquery_id) ? "true" : "false"}  >
                                                                                        More Details
                                                                                    </button>
                                                                                    {/* <p>{isItemOpen(item?.message?.enquery_id) ? (<IoIosArrowUp style={{ fontSize: 20 }} />) : (<IoChevronDown style={{ fontSize: 20 }} />)}</p>
                                                                                    <style> {""}  {`button::after {  display: none; position: absolute;  content: '';   }`}   </style> */}
                                                                                </h2>
                                                                                {/* <div id={"flush-collapseOne" + item?.message?.enquery_id} class="accordion-collapse collapse" aria-labelledby={"flush-headingOne" + item?.message?.enquery_id} data-bs-parent={"#accordionFlushExample" + item?.message?.enquery_id}> */}
                                                                                <div id={"collapseOne" + item?.message?.enquery_id} className={`accordion-collapse collapse ${isItemOpen(item?.message?.enquery_id) ? "show" : ""}`} aria-labelledby={"heading" + item?.message?.enquery_id}>
                                                                                    <div className='ff-poppins-regular fs-12'>
                                                                                        {item?.message?.service_detail?.description}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <img src={item?.message?.service_detail?.image} width={'92px'} height={'92px'} className='rounded-3 p-1' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else if (item.msg_type == "chat") {
                                                    return (
                                                        <div className={`d-flex ${item.msg_align == 'right' ? 'justify-content-end' : 'justify-content-start'}`}>

                                                            {
                                                                item.msg_align == 'right'
                                                                    ?
                                                                    <div className={`msgTypeChat ${item.msg_align == 'right' ? 'rightAlign' : 'leftAlign'}`}>
                                                                        <div className='ff-poppins-regular fs-13'>{item?.message}</div>
                                                                        <div className='d-flex justify-content-end'>
                                                                            <div className='ff-poppins-regular fs-10 me-1'>{item?.msg_time}</div>
                                                                            {
                                                                                item?.read_status == '1'
                                                                                    ?
                                                                                    <img src={message_read} width={'15px'} height={'15px'} />
                                                                                    :
                                                                                    <img src={message_unread} width={'15px'} height={'15px'} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='d-flex'>
                                                                        <img src={item.msg_writer_image} width={'40px'} height={'40px'} className='rounded-circle me-1' />
                                                                        <div className={`msgTypeChat ${item.msg_align == 'right' ? 'rightAlign' : 'leftAlign'}`}>
                                                                            <div className='ff-poppins-regular fs-13'>{item?.message}</div>
                                                                            <div className='d-flex justify-content-end'>
                                                                                <div className='ff-poppins-regular fs-10 me-1'>{item?.msg_time}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                else if (item.msg_type == "attachment") {
                                                    let widthRatio = Number(item?.message?.width_ratio);
                                                    let heightRatio = Number(item?.message?.height_ratio);
                                                    let size = 0;
                                                    let imageHeight = 0;
                                                    let imageWidth = 0;


                                                    if (widthRatio > heightRatio) {
                                                        imageWidth = 250;
                                                        size = 250 / widthRatio;
                                                        imageHeight = heightRatio * size;
                                                    } else {
                                                        imageHeight = 250;
                                                        size = 250 / heightRatio;
                                                        imageWidth = widthRatio * size;
                                                        if (imageWidth < 100) {
                                                            imageWidth = 100;
                                                            size = 100 / widthRatio;
                                                            imageHeight = heightRatio * size;
                                                        }
                                                    }


                                                    return (
                                                        <div className={`d-flex ${item.msg_align == 'right' ? 'justify-content-end' : 'justify-content-start'}`}>
                                                            <div className='msgTypeAttachment'>
                                                                <div>
                                                                    <img src={item?.message?.file_url} width={imageWidth} height={imageHeight} />
                                                                </div>
                                                                <div className='d-flex justify-content-end p-1'>
                                                                    <div className='ff-poppins-regular fs-10 me-1 text-white'>{item?.msg_time}</div>
                                                                    {
                                                                        item?.read_status == '1'
                                                                            ?
                                                                            <img src={message_read} width={'15px'} height={'15px'} />
                                                                            :
                                                                            <img src={message_unread} width={'15px'} height={'15px'} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                    </div>
                                ))
                            }
                        </div>
                        :
                        <></>
                }
            </div>

            {
                singleChatHistoryDetail && singleChatHistoryDetail != null &&

                <div class="messageInputSection input-group">

                    {
                        !isMessageSending
                            ?
                            <>
                                <span class="input-group-text attachment">
                                    <input className='d-none' type='file' accept="image/*" onChange={handleFileRead} ref={fileRef} />
                                    <img src={messenger_attachment} width={'24px'} height={'24px'} alt='Attachment' role='button' onClick={() => { fileRef.current?.click() }} />
                                </span>
                                <textarea class="form-control ff-poppins-regular fs-18" rows={1} placeholder='Write your message...' value={messageText} onChange={ev => setMessageText(ev.target.value)}></textarea>
                            </>
                            :
                            <div className='d-flex align-items-center ps-3 bg-white' style={{ width: '343px' }}>
                                <div class="spinner-border primary-text spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div className='ff-balooPaaji2-medium fs-15 ps-3'>Sending...</div>
                            </div>
                    }

                    <span className={(isMessageSending || messageText == '' ? 'pe-none ' : '') + 'input-group-text sendBtn'} role='button' onClick={() => sendMessage()}>
                        <img src={messenger_send} width={'24px'} height={'24px'} alt='Send' role='button' />
                    </span>
                </div>
            }

            <ImageCropperModal
                isOpen={isImageCropperModaOpen}
                inputImage={imageToCrop}
                onSelect={onImageCropped}
                onCancel={() => {
                    setIsImageCropperModaOpen(false);
                    clearFileInput();
                }}
                enableRatio={true}
                isBlolbOutput={true}
            />
        </>
    )
}

export default EnqueryChat