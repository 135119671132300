import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../Services/axios';
import defaultimg from "../../Assets/Images/default-placeholder.png";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
const OfferCard = ({ data, offer_from_Page }) => {

    const navigate = useNavigate();
    const expiredate = data.expiry_date || data.duration;
    // console.log("data", data);
    // console.log("offer_from_Page", offer_from_Page);

    return (
        <div>
            <div className='mt-4 p-2 '>
                <div className=' offerCard  mb-2' style={{ border: '1px solid #ebebeb', borderRadius: 10 }} >
                    <div className='d-flex p-2 offerBottam'>
                        <div className='col-4 '>

                            <div>
                                {data.offer_image ? <img src={data.offer_image} className='offerimg' /> : <img src={data.offers_image} className='offerimg' />}
                            </div>
                        </div>
                        <div className='col-1'></div>
                        <div className='col-7 m-0'>
                            <h6 className='m-0 ff-poppins-bold fs-15'>{data.offer_name} </h6>

                            {
                                data.offer_type == '1' ?
                                    <p className='m-0 fs-12 ff-poppins-semiBold primary-text'>
                                        Get {data.discount} {data.discount_amount}   <span> {data.discount_type == '1' ? '₹' : data.discount_type == '2' ? '%' : <></>}</span>  Offer
                                    </p> :
                                    data.offer_type == "2" ?
                                        <p className='m-0 fs-12 ff-poppins-semiBold primary-text'>
                                            Get Offer
                                        </p> :
                                        data.offer_type == '3' ?
                                            <p className='m-0 fs-12 ff-poppins-semiBold primary-text'>
                                                Buy Get Offer
                                            </p> :
                                            data.offer_type == '4' ?
                                                <p className='m-0 fs-12 ff-poppins-semiBold primary-text'>
                                                    Pick any 2 at combo <MdOutlineCurrencyRupee /> {data.offer_price} offer
                                                </p> : ""
                            }
                            {
                                offer_from_Page == "serarch_offer" ?
                                    <div className='d-flex justify-content-between border_Bootam_Line pb-2 primary-text'>
                                        <h5 className='m-0 ff-poppins-bold fs-16 '>{data?.vendor_company_name}</h5>
                                        <span className='fs-11 ff-poppins-regular mt-1'><IoLocationSharp />{data?.distance} km</span>
                                    </div> : ""
                            }
                            <div className='border-Bottom-Solid'></div>

                            {/* <hr ></hr> */}
                            <p className='ff-poppins-regular fs-9 offer_description mt-2'> {data.offer_description}</p>
                            <p className='fs-9 m-0 primary-text ff-poppins-semiBold ' >Expire Date : {new Date(expiredate.substring(0, 10)).toDateString().substring(4, 20)}</p>
                        </div>
                    </div>
                    {
                        offer_from_Page == "Offer_list" ?
                            data.usage_limit_status == '1' || data.usage_limit_status == '0' ?
                                <div className='offerLimit primary-text  px-2 py-1 m-0 d-flex justify-content-between fs-9' >
                                    <p className='m-0'>Usage Limit : {
                                        data.usage_limit_status == 1 ? data.usage_limit : data.usage_limit_status == 0 ? 'Unlimited' : <></>
                                    }
                                    </p>
                                    <p className='m-0'>Your Limit : {data.usage_limit_per_user} </p>
                                </div> : <></>
                            : ""
                    }
                </div>
            </div>
        </div>
    )
}
export default OfferCard