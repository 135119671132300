import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import p_dummyImage from '../../Assets/Images/p_dummyImage.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { addUserFamilyMember, addUserVehicle, deleteFamilyMember, deleteUserVehicle, getVehicleTypeAndCategory, uploadFamilyMemberImage, uploadVehicleImage, userSingleFamilyMemberDetail, userSingleVehicleDetail, userUpdateSingleVehicleDetail } from '../../Services/api';
import p_nav_edit from '../../Assets/Images/p_nav_edit.svg';
import ImageCropperModal from '../../Components/modal/imageCropperModal';
import p_options_vertical from '../../Assets/Images/p_options_vertical.svg';
import { BsThreeDotsVertical } from "react-icons/bs";
const VehicleRegister = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const { frompage, vendorId, AddPage } = location.state || {};
    const [isLoading, setLoadingStatus] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(p_dummyImage);
    const [profilePhotoName, setProfilePhotoName] = useState('');
    const [value, setValue] = useState('');
    const fileRef = useRef(null);
    const [vendorid, setVendorid] = useState('')
    const [typeList, setTypeList] = useState([]);
    const [vehicleType, setVehicleType] = useState('');
    const [categoryList, setCategoryList] = useState([]); 
    const [modalOpen, setModalOpen] = useState(false);
    const [isImageCropperModaOpen, setIsImageCropperModaOpen] = useState(false);
    const [imageToCrop, setImageToCrop] = useState('');
    const deleteModelref = useRef(null)
    const [formData, setFormData] = useState({
        "type": "",
        "category": "",
        "brand": "",
        "model": "",
        "register_number": "",
        "purchase_year": "",
        "about": "",
        "vehicle_image": "",
        "vehicle_id": ""
    });
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
        setIsOpen(false)
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    useEffect(() => {

        if (location.state && location.state?.vehicle_id) {
            userSingleVehicleDetail(location.state).then((res) => {

                setFormData({
                    type: res.data?.vehicle_detail?.type,
                    category: res.data?.vehicle_detail?.category,
                    brand: res.data?.vehicle_detail?.brand,
                    model: res.data?.vehicle_detail?.model,
                    register_number: res.data?.vehicle_detail?.register_number,
                    purchase_year: res.data?.vehicle_detail?.purchase_year,
                    about: res.data?.vehicle_detail?.about,
                    vehicle_image: res.data?.vehicle_detail?.image_name,
                    vehicle_id: res.data?.vehicle_detail?.id,
                });
                setVehicleType(res.data?.vehicle_detail?.type)
                setProfilePhoto(res.data?.vehicle_detail?.image);
            });
        }

        getVehicleTypeAndCategory().then((res) => {
            setTypeList(res.data?.result);
        });
    }, [value]);

    useEffect(() => {

        if (typeList.length > 0 && formData.type != '') {
            let category = typeList.find(x => x.type_id === formData.type);
            setCategoryList(category.categories);
            setFormData(prevData => ({
                ...prevData,
                category: formData.category
            }));
        }
    }, [typeList, vehicleType]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

        if (event.target.name === 'type') {
            setFormData(prevData => ({
                ...prevData,
                category: ''
            }));
            let category = typeList.find(x => x.type_id === event.target.value);
            setCategoryList(category.categories);
        }
    };

    const dispErrorMsg = (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });
    }

    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file);

        if (base64) {
            setImageToCrop(base64);
            setIsImageCropperModaOpen(true);
        }
    }

    const onImageCropped = (e) => {

        if (e) {
            let body = {
                "vehicle_image": e
            }
            uploadVehicleImage(body)
                .then((res) => {

                    if (res.data.status === "1") {
                        setProfilePhoto(res.data.results);
                        setProfilePhotoName(res.data.img_name);
                        setFormData(prevData => ({
                            ...prevData,
                            vehicle_image: res.data.img_name
                        }));

                        setIsImageCropperModaOpen(false);
                    }
                    setLoadingStatus(false);

                })
                .catch((error) => {
                    setLoadingStatus(false)
                    toast(error.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
            clearFileInput();

        }
    }

    const convertBase64 = (file) => {

        if (file) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject('custom error', error);
                }
            })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (formData.type == '') {
            dispErrorMsg('Vehicle Type field required')
            return
        }
        else if (formData.category == '') {
            dispErrorMsg('Vehicle Category field required')
            return
        }
        else if (formData.brand == '') {
            dispErrorMsg('Vehicle Brand Name field required')
            return
        }
        else if (formData.model == '') {
            dispErrorMsg('Vehicle Model Name field required')
            return
        }
        else if (formData.register_number == '') {
            dispErrorMsg('Vehicle Register Number field required')
            return
        }
        else if (formData.purchase_year == '') {
            dispErrorMsg('Vehicle Model Year field required')
            return
        }
        else if (formData.about == '') {
            dispErrorMsg('About Vehicle field required')
            return
        }

        setLoadingStatus(true);

        if (formData.vehicle_id != '') {
            console.log("formData formData formData", formData);

            userUpdateSingleVehicleDetail(formData)
                .then((res) => {
                    setLoadingStatus(false);

                    if (res.data?.status === '1') {
                        if (AddPage == "vehicle") {
                            navigate(`/door-step-service/${vendorId}`, { state: { AddPage: "vehicle" } })
                        }
                        else {
                            navigate('/profile', { state: { divRef: 'myVehicles' } })
                        }
                    }

                })
                .catch((error) => {
                    setLoadingStatus(false)
                    toast(error.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
        } else {
            addUserVehicle(formData)
                .then((res) => {
                    setLoadingStatus(false);

                    if (res.data?.status === '1') {
                        if (AddPage == "vehicle") {
                            navigate(`/door-step-service/${vendorId}`, { state: { AddPage: "vehicle" } })
                        }
                        else {
                            navigate('/profile', { state: { divRef: 'myVehicles' } })
                        }
                    }

                })
                .catch((error) => {
                    setLoadingStatus(false)
                    toast(error.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
        }
    }

    const deleteVehicle = () => {
        deleteUserVehicle(location.state).then((res) => {

            if (res.data?.status === '1') {
                navigate('/profile', { state: { divRef: 'myVehicles' } })
            }
        })
    }

    const clearFileInput = () => {
        if (fileRef.current) {
            fileRef.current.value = null;
        }
    };
    useEffect(() => {
        let localServiceDetail = localStorage.getItem("serviceDetail") ? JSON.parse(localStorage.getItem("serviceDetail")) : "";
        setVendorid(localServiceDetail.vendorId);

    }, [])
   
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
 console.log("frompage",frompage,vendorid);
 

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className='d-flex justify-content-between col-12'>
                    <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                        <img
                            src={p_nav_previous}
                            width={'24px'}
                            height={'16px'}
                            alt='Previous Button'
                            role='button'
                            onClick={() => {
                                if (frompage === "ServiceBookingFlow") {
                                    navigate(`/door-step-service/${vendorid}`, { state: { AddPage: 'AddMember' } });
                                } else {
                                    navigate('/profile', { state: { divRef: 'myVehicles' } });
                                }
                            }}
                        />
                        <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>
                            Vehicle {formData?.vehicle_id ? " Profile Edit" : "Register"}
                        </div>
                    </div>
                    {/* <div className='mt-2'>   <BsThreeDotsVertical />  </div> */}
                    {
                        formData?.vehicle_id &&
                        <div className="profileEdit position-relative">

                            <img src={p_options_vertical} width="27px" height="27px" role="button" onClick={toggleDropdown} alt="Profile Options" />
                            {isOpen && (

                                <ul className="delete_dropdown position-absolute top-0 end-0 primary-bg p-0" aria-labelledby="profile-options"
                                    style={{ display: isOpen ? 'block' : 'none', marginTop: 35 }} onClick={() => openModal()}>
                                    <li className='delete_dropdown_ul '>
                                        <span className="delete_dropdown-item ms-2  text-light ff-poppins-bold fs-12" role="button">Delete</span>
                                    </li>
                                </ul>

                            )}
                        </div>
                    }


                </div>
            </div>

            <div className='formPage scroller-style-y' >
                <div className='text-center'>
                    <img className='bg-white rounded-3' src={profilePhoto} width={'110px'} height={'110px'} alt='Default image' />
                    <div className='d-flex justify-content-center'>
                        <input className='d-none' type='file' accept="image/*" onChange={handleFileRead} ref={fileRef} />
                        <div className='addPhotoBtn d-flex justify-content-center align-items-center primary-bg text-white ff-poppins-regular fs-16 fw-600' role='button' onClick={() => { fileRef.current?.click() }}>Add Photo</div>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    {/* Vehicle Type  */}
                    <select className="form-select border-0 ff-poppins-regular fs-16 mb-3" aria-label="Default select example" name="type" value={formData.type} onChange={handleChange} >
                        <option value='' selected disabled>Vehicle Type</option>
                        {
                            typeList.map(item => (
                                <option key={item.type_id} value={item.type_id}>{item.type_name}</option>
                            ))
                        }
                    </select>

                    {/* Vehicle Category  */}
                    <select className="form-select border-0 ff-poppins-regular fs-16 mb-3" aria-label="Default select example" name="category" value={formData.category} onChange={handleChange} >
                        <option value='' selected disabled>Vehicle Category</option>
                        {
                            categoryList.map(item => (
                                <option key={item?.catrgory_id} value={item?.catrgory_id}>{item?.category_name}</option>
                            ))
                        }
                    </select>

                    {/* Vehicle Brand Name  */}
                    <input type='text' className='mb-3 ps-3 pe-3 pt-0 pb-0 w-100 ff-poppins-regular fs-14 bg-white text-dark' placeholder='Vehicle Brand Name' name="brand" maxLength={20} value={formData.brand} onChange={handleChange} />

                    {/* Vehicle Model Name  */}
                    <input type='text' className='mb-3 ps-3 pe-3 pt-0 pb-0 w-100 ff-poppins-regular fs-14 bg-white text-dark' placeholder='Vehicle Model Name' maxLength={20} name="model" value={formData.model} onChange={handleChange} />

                    {/* Vehicle Register Number  */}

                    <input type='text' className='m-0 ps-3 pe-3 pt-0 pb-0 w-100 ff-poppins-regular fs-14 bg-white text-dark' placeholder='Vehicle Register Number' name="register_number" maxLength={12} value={formData.register_number} onChange={handleChange} />
                    {/* <span className=' ms-2 fs-11 mb-4 ff-poppins-regular text-secondary'>Please use the format TN-00-BH0000</span> */}

                    {/* Vehicle Model Year  */}
                    <input type='text' className='mt-3 mb-3 ps-3 pe-3 pt-0 pb-0 w-100 ff-poppins-regular fs-14 bg-white text-dark' placeholder='Vehicle Model Year' maxLength={4} name="purchase_year"  value={formData.purchase_year} onChange={handleChange} />

                    {/* About Vehicle  */}
                    <textarea className=' w-100 border-0 ff-poppins-regular fs-14' rows={5} maxLength={120} placeholder="About Vehicle" name="about" value={formData.about} onChange={handleChange}></textarea>
                    <div className='d-flex justify-content-end ff-poppins-regular mb-3 fs-14'>{formData.about.length} / 120</div>

                    <div className='d-flex justify-content-center'>
                        <button className='submitBtn d-flex justify-content-center align-items-center primary-bg ff-poppins-regular fs-16 fw-600 text-white border-0'>
                            {formData.vehicle_id != '' ? 'UPDATE' : 'ADD'} VEHICLE</button>
                    </div>
                </form>
            </div>

            {/* Delete Modal  */}
            {
                modalOpen &&
                <div class={`modal   d-flex align-items-center`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <div class="modal-body ps-5 pe-5 pt-4 pb-4">
                                <div className='text-center'>Are you sure you want to delete this Vehicle</div>

                                <div className='d-flex justify-content-between mt-3'>
                                    <button type="button" class="btn btn-secondary" onClick={() => closeModal()}>Cancel</button>
                                    <button type="button" class="btn btn-danger" onClick={() => deleteVehicle()} data-bs-dismiss="modal">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            <ImageCropperModal
                isOpen={isImageCropperModaOpen}
                inputImage={imageToCrop}
                onSelect={onImageCropped}
                onCancel={() => {
                    setIsImageCropperModaOpen(false);
                    clearFileInput();
                }}
            />
        </>
    )
}

export default VehicleRegister;