import React, { useEffect, useRef, useState, useCallback } from "react";
import p_nav_previous from "../../../Assets/Images/p_nav_previous.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import OtpInput from "react-otp-input";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdHome } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { FaHourglassHalf } from "react-icons/fa";
import { RiShareFill } from "react-icons/ri";
import { BiMessageDetail } from "react-icons/bi";
import { PiPhoneCallFill } from "react-icons/pi";
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import { MdWork } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import axiosInstance, { Razorpay_Key } from "../../../Services/axios";
import "../../../Assets/Images/STAR_OUTLINE.png";
import defaultperson from "../../../Assets/Images/defaultperson.jpg";
import { IoIosStarOutline } from "react-icons/io";
import { IoMdStar } from "react-icons/io";
import { useUser } from "../../../Components/UserContext"; // Adjust the path according to your file structure
import { getDatabase, ref, onValue } from "firebase/database";
import VerticalStepper from "../../../Components/stepper";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Offcanvas } from "bootstrap";
import Loading from "react-loading";
import AppointmentCancelled from "../../../Assets/Images/Appointment.jpg";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import SuccessLotie from "./../../../Assets/lottieFiles/success.json";
import useRazorpay from "react-razorpay";
import { CiCalendar } from "react-icons/ci";
import { BsClock } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import { GoDotFill } from "react-icons/go";



const ProductPass = () => {
  const [Razorpay] = useRazorpay();
  const currentUser = useUser(); // Access user context
  const locations = useLocation();
  const { orderid, flowtype, fromPage } = locations.state || {};

  const navigate = useNavigate();
  const paymentSuccessModalRef = useRef(null);
  const canceldisputeModalRef = useRef(null);
  const cancelRefundModalRef = useRef(null);
  const cancelConfirmModalRef = useRef(null);
  const editOrderModalRef = useRef(null);
  const [showContentBI, setShowContentBI] = useState(false);
  const [activeButton, setActiveButton] = useState("ticket");
  const [showDetails, setShowDetails] = useState(false);
  const [showStaffDetails, setShowStaffDetails] = useState(false);
  const [product, setProduct] = useState('');
  const [location, setLocation] = useState();
  const [staff, setStaff] = useState([]);
  const [user, setUser] = useState([]);
  const [vendor, setVendor] = useState('');
  const [orderdetail, setOrderdetail] = useState([]);
  const [gststatus, setGststatus] = useState(false);
  const [payment, setPayment] = useState([]);
  const [deliverystep, setDeliverystep] = useState([]);
  const [cancelorder, setCancelorder] = useState([]);
  const [refund, setRefund] = useState([]);
  const [refundstatus, setRefundstatus] = useState([]);
  const [ratecount, setRatecount] = useState('');
  const [feedback, setFeedback] = useState('');
  const [ratings, setRatings] = useState([false, false, false, false, false]);
  const [showContent, setShowContent] = useState(false);
  const [buyproduct, setBuyproduct] = useState([]);
  const [getproduct, setGetproduct] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [userId, setUserId] = useState();
  const [paymentOrderId, setPaymentOrderId] = useState();
  const [dispute, setDispute] = useState('')
  const [activeTab, setActiveTab] = useState("services");
  const [pilotRatecount, setPilotRatecount] = useState('');
  const [pilotfeedback, setPilotFeedback] = useState('');
  const [pilotratings, setPilotRatings] = useState([false, false, false, false, false]);
  const [showaddons, setShowaddons] = useState(false);
  const [showAddonsState, setShowAddonsState] = useState({});
  const [editOrder, setEditOrder] = useState("");
 

  const toggleContent = () => {
    setShowContent(!showContent);
  };
  const toggleaddon = () => {
    setShowaddons(!showaddons)
  }
  useEffect(() => {
    if (
      product?.payment_details?.balence_request?.request_status == 1 &&
      product.payment_details.payment_status == "0"
    ) {
      openBookForModal();
    } else {
      closeBookForModal();
    }
  }, [payment]);

  useEffect(() => {
    if (!currentUser) return; // Check if user is not logged in
    const database = getDatabase();
    const orderRef = ref(database, `/orders/${orderid}`);
    const unsubscribe = onValue(orderRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        getproductDetails();
      }
    });

    return () => unsubscribe(); // Detach listener on cleanup
  }, [currentUser, orderid]);

  useEffect(() => {
    if (!currentUser) return; // Check if user is not logged in
    const database = getDatabase();
    const orderRef = ref(database, `/payments/product_order/${userId}`);
    // const orderRef = ref(database, `/payments/product_order/23U00002`);
    const unsubscribe = onValue(orderRef, (snapshot) => {
      const data = snapshot.val();
      if (data.status === 2 && data.order_id === paymentOrderId) {
        openPaymentSuccessModal();
      }

    });
    return () => unsubscribe(); // Detach listener on cleanup
  }, [currentUser, paymentOrderId]);

  useEffect(() => {
    getproductDetails();
    localStorage.removeItem("cartID");
  }, []);

  useEffect(() => {
    if (offcanvasRef.current) {
      const bsOffcanvas = new Offcanvas(offcanvasRef.current, {
        backdrop: true,
        scroll: true,
      });

      // Optional: Setup a trigger button or similar activation method
      // Example of setting up a trigger button
      const triggerButton = document.getElementById("triggerButtonId");
      triggerButton.addEventListener("click", function () {
        bsOffcanvas.toggle();
      });
    }
  }, []);

  const handleStarClick = (index) => {
    const newRatings = [...ratings];
    for (let i = 0; i < newRatings.length; i++) {
      if (i <= index) {
        newRatings[i] = true;
      } else {
        newRatings[i] = false;
      }
    }
    setRatings(newRatings);
    setRatecount(index + 1);
  };
  const handlePiltoStarClick = (index) => {
    const pilotnewRatings = [...ratings];
    for (let i = 0; i < pilotnewRatings.length; i++) {
      if (i <= index) {
        pilotnewRatings[i] = true;
      } else {
        pilotnewRatings[i] = false;
      }
    }
    setPilotRatings(pilotnewRatings);
    setPilotRatecount(index + 1);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const toggleStaffDetails = () => {
    setShowStaffDetails(!showStaffDetails);
  };

  const [otp, setOtp] = useState("");
  const handleChangeOTP = (newOtp) => {
    const sanitizedOtp = newOtp.replace(/\D/g, "");
    setOtp(sanitizedOtp);
  };
  const toggleActive = (button) => {
    setActiveButton(button);
  };
  const toggleContentBI = () => {
    setShowContentBI(!showContentBI);
  };

  const getproductDetails = () => {
    try {
      let reqData = {
        order_id: orderid,
      }; 

      axiosInstance
        .post("user/products/get_order_details", reqData)
        .then((res) => { 
          let data = res.data;
          setProduct(data);
          setEditOrder(data?.edit_order_detail)

          setLocation(data.delevery_address);
          setStaff(data.delivery_staff_detail);
          setUser(data.user_detail);
          setVendor(data.vendor_detail);
          setOrderdetail(data.order_products);
          setGststatus(data.gst_status);
          setPayment(data.payment_details);
          if (Array.isArray(data.delivery_steps_detail)) {
            setDeliverystep(data.delivery_steps_detail);
          }
          setCancelorder(data.order_cancel_detail);
          setRefundstatus(data.order_cancel_detail);
          setBuyproduct(data.offer_details.buy_products);
          setGetproduct(data.offer_details.get_products);
          if (data?.edit_order_status == "1" && data?.edit_order_detail?.request_status == "1") {
            openEditorderModal()
          }
          if (data?.rating_status == "1") {
            handleStarClick(
              data?.rating_detail?.rate_count ? Number(data?.rating_detail?.rate_count) - 1 : 0
            );
            setFeedback(data?.rating_detail?.rate_description);
          }
          if (data?.pilot_rating_status == true) {
            handlePiltoStarClick(
              data?.pilot_rating_detail?.rate_count ? Number(data?.pilot_rating_detail?.rate_count) - 1 : 0
            )
            setPilotFeedback(data?.pilot_rating_detail?.pilot_feedback)
          }

        }).catch((err) => {
          // if (err.response.status == 400 || err.response.status == 500) {
          //   toast(err.response.data.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: true,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "light",
          //     className: "text-danger toast-style-1",
          //   });
          // }
         

        });
    } catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };
  useEffect(() => {
    if (product?.edit_order_status == "1" && product?.edit_order_detail?.request_status == "1") {
      openEditorderModal()
    }
  }, [product])
  const getrefund = () => {
    try {
      let reqdata = {
        order_id: orderid,
      };
      axiosInstance
        .post("/user/products/get_refund_info", reqdata)
        .then((res) => {
          setRefund(res.data.refund_message);

          cancelRefundModalRef.current.classList.add("show");
          cancelRefundModalRef.current.style.display = "block";
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {

            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const cancel_order = () => {
    try {
      let reqData = {
        order_id: orderid,
      };
      axiosInstance
        .post("/user/products/confirm_cancel_order", reqData)
        .then((res) => {
          cancelRefundModalRef.current.classList.remove("show");
          cancelRefundModalRef.current.style.display = "none";
          cancelConfirmModalRef.current.classList.add("show");
          cancelConfirmModalRef.current.style.display = "block";

        });
      setLoading(true); // Start loading
      setButtonClicked(true);

    } catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const starRating = () => {
    try {
      let reqData = {
        order_id: orderid,
        rate_count: ratecount,
        rate_description: feedback,
      };
      if (product.rating_status) {
        reqData["rate_id"] = orderid;
      }
      axiosInstance.post("user/products/submit_order_rating", reqData)
        .then((res) => {
          toast("Rating has been submitted.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-success toast-style-1",
          });
        })
        .catch((err) => {
        
          let errorMessage = 'An unexpected error occurred';
          if (err.response) {

            switch (err.response.status) {
              case 400:
                errorMessage = 'Rating Count field is required';
                break;
              case 500:
                errorMessage = 'Feedback is required';
                break;
              default:
                errorMessage = ` ${err.response.data.message || 'Unknown error'}`;
                break;
            }
          } else if (err.request) {
            // The request was made but no response was received
            errorMessage = 'No response was received from the server';
          } else {
            // Something happened in setting up the request that triggered an error
            errorMessage = 'An error occurred: ' + err.message;
          }

          toast(errorMessage, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-danger toast-style-1", // Use a different class or styling for error messages
          });
        });
    }
    catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  }

  const PilotstarRating = () => {
    try {
      let reqData = {
        order_id: orderid,
        rate_count: pilotRatecount,
        pilot_feedback: pilotfeedback,
      };
      if (product.rating_status) {
        reqData["rate_id"] = orderid;
      } 
      axiosInstance.post("user/products/submit_order_pilot_rating", reqData)
        .then((res) => { 
          toast("Rating has been submitted.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-success toast-style-1",
          });
        })
        .catch((err) => { 
          let errorMessage = 'An unexpected error occurred';
          if (err.response) {

            switch (err.response.status) {
              case 400:
                errorMessage = 'Rating Count field is required';
                break;
              case 500:
                errorMessage = 'Feedback is required';
                break;
              default:
                errorMessage = ` ${err.response.data.message || 'Unknown error'}`;
                break;
            }
          } else if (err.request) {
            // The request was made but no response was received
            errorMessage = 'No response was received from the server';
          } else {
            // Something happened in setting up the request that triggered an error
            errorMessage = 'An error occurred: ' + err.message;
          }

          toast(errorMessage, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-danger toast-style-1", // Use a different class or styling for error messages
          });
        });
    }
    catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  }
  const raisedispute = () => {
    try {
      let reqData = {
        order_id: orderid,
        reason: dispute
      }

      axiosInstance.post('user/products/raise_dispute', reqData)
        .then((res) => {
          closeDisputeModal()
          getproductDetails()
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {

            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    }
    catch {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  }
  const cancelBalanceRequest = () => {
    try {
      const reqData = {
        order_id: orderid,
      };

      axiosInstance.post("user/products/reject_balance_payment_request", reqData)
        .then((res) => {

        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {

            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (err) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const closeCencalConfirmModal = () => {
    cancelConfirmModalRef.current.classList.remove("show");
    cancelConfirmModalRef.current.style.display = "none";
  };

  const payBalance = () => {


    let userDetatil = {
      name: "",
      phone: "",
      email: "",
    };

    setPaymentOrderId(payment.balence_request?.payment_order_id);
    setUserId(user?.user_id);
    handlePayment(userDetatil, payment.balence_request.order);
  };

  const cancelEditorderRequest = () => {
    try {
      const reqData = {
        order_id: orderid,
      };

      axiosInstance.post("user/products/user_cancel_edit_order_request", reqData)
        .then((res) => { 

          closeEditorderModal()
          getproductDetails()
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {

            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (err) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const acceptEditorderRequest = () => {
    try {
      const reqData = {
        order_id: orderid,
      }; 

      axiosInstance.post("/user/products/accept_and_edit_order", reqData)
        .then((res) => {
          let EditOrder_Detail = {
            orderid: orderid,
            shippingtype: product?.shipping_type,
            vendorId:vendor?.vendor_id
          }
          localStorage.setItem('EditOrder_Detail', JSON.stringify(EditOrder_Detail));
          navigate(`/products/${vendor?.vendor_id}`, { state: { flowtype: "EDIT_ORDER" } })
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (err) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const offcanvasRef = useRef(null);

  const handleOkClick = () => {
    setShowModal(false);
    closeCencalConfirmModal();

  };
  const copyToClipboard = () => {
    const textToCopy = vendor.vendor_share_link;
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);

    toast("Link copied!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      className: "text-dark toast-style-1",
    });

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handlePayment = useCallback(
    (userData, orderData) => {
      const createOrderAndInitializePayment = async () => {
        const options = {
          key: Razorpay_Key,
          amount: orderData.amount,
          currency: orderData.currency,
          name: "PINGLE",
          description: "Payment",
          image: "https://pingle.in/user/assets/images/LogoUser.png",
          order_id: orderData.id,
          handler: (res) => {


            if (res) {
              openPaymentSuccessModal();
              // onPayment(res); // Passing the order ID to the parent component
            }
          },
          prefill: {
            name: userData.name,
            email: userData.email,
            contact: userData.phone,
          },
          theme: {
            color: "#4E26A3",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      };

      createOrderAndInitializePayment();
    },
    [Razorpay]
  );

  const openPaymentSuccessModal = () => {
    paymentSuccessModalRef.current.classList.add("show");
    paymentSuccessModalRef.current.style.display = "block";
  };

  const handleSuccessOkClick = () => {
    paymentSuccessModalRef.current.classList.remove("show");
    paymentSuccessModalRef.current.style.display = "none";
    // navigate('/');
  };

  const openDisputeModal = () => {
    canceldisputeModalRef.current.classList.add("show");
    canceldisputeModalRef.current.style.display = "block";
  };

  const closeDisputeModal = () => {
    canceldisputeModalRef.current.classList.remove("show");
    canceldisputeModalRef.current.style.display = "none";
    // navigate('/');
  };
  const openEditorderModal = () => {
    editOrderModalRef.current.classList.add("show");
    editOrderModalRef.current.style.display = "block";

  };

  const closeEditorderModal = () => {
    editOrderModalRef.current.classList.remove("show");
    editOrderModalRef.current.style.display = "none";
    // navigate('/');
  };
  function openBookForModal() {
    document
      .getElementById("selectCustomerModalProductRP")
      .classList.add("show");
  }

  function closeBookForModal() {
    var modal = document.getElementById("selectCustomerModalProductRP");
    modal.classList.remove("show");
  }


  const toggleAddon = (index) => {
    setShowAddonsState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const dateOptions = { day: '2-digit', month: 'long', year: 'numeric' };

  const hoursFromNow = (neededHour) => {
    let date = new Date();
    date.setHours(date.getHours() + neededHour); // Adjusting the time by needed hours

    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero to minutes if necessary

    return `${hours}:${minutes} ${ampm}`
  };

  let dateOption = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  };

  return (
    <div>
      <div className="min-vh-100">
        <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
          <div className="d-flex justify-content-between align-items-center ms-2 me-2">
            <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => {
              fromPage == "DashBoard" ? navigate("/dashboard") : fromPage == "ReviewPay" ? navigate("/", { state: { fromPage: "ProductPass" } }) : navigate("/", { state: { fromPage: "ProductPass" } });
            }
            } />
            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
              Product pass
            </div>
          </div>
        </div>
        <div className="servicePassHead scroller-style-y">
          <div className="servicePassCard">
            <div className="">
              <div class="TicketPass-Div">
                {
                  product?.shipping_type == "2" ?
                    <div className="w3-bar w3-black d-flex justify-content-center w-100">
                      <div class="Switch-T-S nav flex-column nav-pills me-3" id="v-pills-tab"
                        role="tablist" aria-orientation="vertical" className="d-flex justify-content-between"  >
                        <div className="col-6">
                          <button class="px-5 nav-link active w-100 ff-poppins-semiBold w3-bar-item w3-button"
                            id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home"
                            type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"  >
                            Ticket
                          </button>
                        </div>
                        <div className="col-6">
                          <button class=" nav-link w-100 ff-poppins-semiBold w3-bar-item w3-button"
                            style={{ paddingRight: 40, paddingLeft: 40 }} id="v-pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" >
                            Schedule
                          </button>
                        </div>
                      </div>
                    </div> : ""
                }

                <div class="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
                    {product.order_status == 2 || product.delivery_status == 5 ? (
                      <div>
                        <div className="ratingContainer">
                          <div className="ff-poppins-semiBold text-center">
                            Your product has been delivered. Please rate the product.
                          </div>
                          <div className="feedback">
                            {ratings.map((filled, index) => (
                              <div key={index} onClick={() => handleStarClick(index)}>
                                {filled ? <IoMdStar color="gold" size={30} /> : <IoIosStarOutline color="gold" size={30} />}
                              </div>
                            ))}
                          </div>
                          <fieldset className="feedbackInput">
                            <input className="feedbackEnter bg-white" value={feedback} onChange={(e) => setFeedback(e.target.value)} type="text" />
                            <button type="submit" className="form-submit ff-poppins-semiBold" onClick={() => starRating()}  >
                              POST
                            </button>
                          </fieldset>
                        </div>
                        <div>
                          {staff?.delivery_staff_type == "pilot" && (
                            <div className="ratingContainer">
                              <div className="ff-poppins-semiBold text-center">
                                Your Delivery has been Complete. Please rate the Delivery Agent.
                              </div>
                              <div className="feedback">
                                {pilotratings.map((filled, index) => (
                                  <div key={index} onClick={() => handlePiltoStarClick(index)}>
                                    {filled ? <IoMdStar color="gold" size={30} /> : <IoIosStarOutline color="gold" size={30} />}
                                  </div>
                                ))}
                              </div>
                              <fieldset className="feedbackInput">
                                <input className="feedbackEnter bg-white" value={pilotfeedback} onChange={(e) => setPilotFeedback(e.target.value)} type="text" />
                                <button type="submit" className="form-submit ff-poppins-semiBold" onClick={() => PilotstarRating()}   >
                                  POST
                                </button>
                              </fieldset>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                        <img src={product.order_qr} className="h-100 w-100 p-3" />
                      </div>
                    )}
                  </div>


                  <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0"  >
                    <div className="stepper-Box">
                      {/* Check if deliverystep is not undefined before rendering VerticalStepper */}
                      {deliverystep && deliverystep.length > 0 && (
                        <VerticalStepper steps={deliverystep} />
                      )}
                    </div>
                  </div>
                </div>
              </div>


              <div id="London" className={`w3-container city`}  >
                <div class="tab-content" id="pills-tabContent"></div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="servicePassCard">
                <div>
                  <div className="headerDivPP d-flex">
                    <div className="col-8 ms-2">
                      <p className="m-0 fs-13 ff-poppins-semiBold">
                        <b>{product.order_date_string}</b>
                      </p>
                      <p className="bookIdSP fs-13 m-0 ff-poppins-semiBold">
                        <b>
                          Booking ID - <span>{product.order_id} </span>
                        </b>
                      </p>
                    </div>
                    {product.order_status == 3 ? (
                      <div>
                        <button className="unpaidbtn fs-14 p-1 ff-poppins-semiBold">Cancelled</button>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <div className="d-flex flex-column align-items-center otpBorderProuductDiv">
                          <div className="otpBorderProuduct"></div>
                        </div>
                        <div className="me-2">
                          <p className="m-0 fs-13 ff-poppins-semiBold">
                            <b>OTP:</b>
                          </p>
                          <p className="bookIdSP fs-13 m-0 ff-poppins-semiBold">
                            <b>{product.order_code} </b>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {product.shipping_type == "2" ? (
                  <div>
                    <h6 className="ms-2 m-0 pb-3 mb-0 ff-poppins-bold">
                      <b>Delivery Addres</b>
                    </h6>
                    <div className="addresHeadDivPP">
                      <div className="addressHomeDivPP">
                        <div className="homeIConDivPP">
                          {location.location_type_id == 1 ? (
                            <IoMdHome className="homeIconPP" />
                          ) : location.location_type_id == 2 ? (
                            <MdWork className="homeIconPP" />
                          ) : location.location_type_id == 3 ? (
                            <IoLocationSharp className="homeIconPP" />
                          ) : (
                            <p>No Icon</p>
                          )}
                        </div>
                      </div>

                      <div className="addTextDivPP">
                        <h6 className="ff-poppins-semiBold">
                          <b>{location.location_type} </b>
                        </h6>
                        <h6 className="addressTextPP fs-12 m-0 ff-poppins-regular numberOfLines">
                          {location.address}
                        </h6>
                        <h6 className="addressTextPP fs-12 m-0 ff-poppins-regular">
                          {`${(location.distance?.text || "").substring(0, 10)}-${(location.duration?.text || "").substring(0, 10)}`}
                        </h6>
                      </div>
                      <div className="arrowDivPP">
                        <IoIosArrowForward />
                      </div>
                    </div>

                    <h6 className="ms-2 m-0 pt-3 ff-poppins-bold">
                      <b>Delivery Staff</b>
                    </h6>

                    <div className="bookForHeadDivRP">
                      {product.delivery_staff_status == true ? (
                        <div className="staffCardHead">
                          <div className="col-3">
                            <div className="staffProfileDiv">
                              {
                                staff?.delivery_staff_type == "pilot" ?
                                  <img src={staff.pilot_image == "" ? defaultperson : staff.pilot_image} onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg'; }} className="petProfileRP" />
                                  :
                                  <img src={staff.staff_image == "" ? defaultperson : staff.staff_image} onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg'; }} className="petProfileRP" />
                              }
                            </div>
                          </div>
                          <div className="col-9 staffContentHeadDiv">
                            <p className="staffNameStaff ff-poppins-bold m-0">{staff?.delivery_staff_type == "pilot" ? staff.pilot_name : staff.staff_name}</p>
                            <p className="ff-poppins-regular fs-12 m-0"> {staff?.delivery_staff_type == "pilot" ? staff?.phone_number : staff?.staff_designation}</p>
                            <div>
                              {showStaffDetails ? (
                                <div className="staffAboutDiv">
                                  <p className="staffSpecializationText fs-12 m-0 ff-poppins-regular">
                                    {product.staff_specialist}
                                  </p>
                                  <hr className="staffHr" />
                                  {/* <button className="petBehaviorRP">FRIENDLY</button> */}
                                  <p className="staffAboutStaff fs-12 ff-poppins-regular addersClamped-text"> {
                                    staff?.delivery_staff_type == "pilot" ? staff?.about : staff?.staff_about} </p>
                                  <div className="moreInfoDiv" onClick={toggleStaffDetails} >
                                    <p className="moreInfoText ff-poppins-semiBold">
                                      Less info
                                    </p>
                                    <IoIosArrowDropupCircle className="" />
                                  </div>
                                </div>
                              ) : (
                                <div className="moreInfoDiv" onClick={toggleStaffDetails}  >
                                  <p className="moreInfoText ff-poppins-semiBold">
                                    More info
                                  </p>
                                  <IoIosArrowDropdownCircle />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" ff-poppins-semiBold staffCardHead fs-13 d-flex justify-content-center border border-2 rounded-3">
                          <p className="align-self-center mt-3"> Staff not assign  </p>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {
                  product?.delivery_slot_status == '1' ?
                    < div className="mt-2">
                      <div className="border border-2 rounded-3">
                        <div className="p-2">
                          <div className="d-flex justify-content-start primary-text">
                            <span className="ff-balooPaaji2-bold fs-16 mt-1">Delivery Time</span>
                          </div>
                          <p className="fs-12 ff-poppins-regular secondary-text m-0">Choose time when order will be delivered</p>
                          <hr />
                          {
                            product?.delivery_slot_type == '2' ?
                              <div className="d-flex justify-content-center">
                                <p className="ff-poppins-bold fs-14 m-0 primary-text">Any Time Delivery</p>
                              </div> :
                              <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-around col-4 ff-poppins-regular">
                                  <div className="me-1 primary-text ff-balooPaaji2-bold">
                                    <CiCalendar />
                                  </div>
                                  <div className="fs-12 mt-1">
                                    {new Date(product?.order_delivery_date_time?.delivery_date.substring(0, 10)).toDateString().substring(4, 20)}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-around col-4 ff-poppins-regular">
                                  <div className="fs-12">
                                    <span className="me-1 primary-text ff-balooPaaji2-bold">
                                      <BsClock />
                                    </span>
                                    {product?.order_delivery_date_time?.delivery_time}
                                  </div>
                                </div>
                              </div>

                          }

                        </div>
                      </div>
                    </div> : <></>
                }

                <h6 className="ms-2 m-0 pt-3 ff-poppins-bold">
                  <b>Booking For</b>
                </h6>

                <div className="bookForHeadDivRP">
                  <div className="staffCardHead">
                    <div className="col-3">
                      <div className="staffProfileDiv">
                        <img src={user?.user_img} className="petProfileRP" />
                      </div>
                    </div>
                    <div className="col-9 staffContentHeadDiv">
                      <p className="staffNameStaff ff-poppins-bold">
                        <b>{user?.user_name} </b>
                      </p>
                      {/* <div className='ff-poppins-regular fs-10 mb-1'>
                        {product.booking_details?.booking_for?.gender} - {product.booking_details?.booking_for?.age} Years - {product.booking_details?.booking_for?.weight} Kg
                      </div> */}
                      <div className='ff-poppins-regular fs-12 m-0'>
                        MySelf
                      </div>
                      <div>
                        {showDetails ? (
                          <div className="staffAboutDiv">
                            <p className="staffSpecializationText fs-12 m-0 ff-poppins-regular">
                              {user?.user_gender}
                            </p>
                            <div className="moreInfoDiv" onClick={toggleDetails}    >
                              <p className="moreInfoText ff-poppins-semiBold">
                                Less info
                              </p>
                              <IoIosArrowDropupCircle />
                            </div>
                          </div>
                        ) : (
                          <div className="moreInfoDiv" onClick={toggleDetails}>
                            <p className="moreInfoText ff-poppins-semiBold">
                              More info
                            </p>
                            <IoIosArrowDropdownCircle />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex SPCard" >
                    <div className="col-3" onClick={() => navigate(`/vendor/${vendor?.vendor_id}`)}>
                      <img src={vendor?.vendor_logo} className="followerImage" alt="No Follower Profile" />
                    </div>
                    <div className="col-7" onClick={() => navigate(`/vendor/${vendor?.vendor_id}`)}>
                      <div className="followerDetDiv">
                        <div className="ff-poppins-semiBold fs-14">{vendor?.vendor_company_name}</div>
                        <div className="ff-poppins-medium fs-12 text-secondary">&#x2022; MID {vendor?.vendor_id} </div>
                        <div className="ff-poppins-medium fs-12 text-secondary">&#x2022; {vendor?.vendor_type_name} </div>
                        <div className="ff-poppins-medium fs-12 text-secondary singleline-text">&#x2022; {vendor?.vendor_address} </div>
                      </div>
                    </div>
                    <div className="col-2 FollowerHistoryDiv" onClick={() => navigate(`/history/${vendor.vendor_id}`)} >
                      <FaHourglassHalf className="hourClassIConFollower" />
                      <p className="followerHistoryP">History</p>
                    </div>
                  </div>
                </div>

                <div className="FHSIConsSP">
                  <div className="d-flex align-items-center">
                    <a href={`tel:${vendor?.vendor_mbl}`} className="text-decoration-none" >
                      <div className="d-flex justify-content-center align-items-center contactDetails">
                        <PiPhoneCallFill className="IconsFHSSP" />
                        <div className="ms-2 ff-poppins-semiBold fs-14 text-white">
                          Call
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="hourGlassIconSP">
                    <div className="d-flex align-items-center" onClick={() => navigate(`/chat/booking/${orderid}`, { state: { chatFlow: 'order' } })}  >
                      <BiMessageDetail className="IconsFHSSP" />
                      <p className="m-0 ms-2 fw-bold">Text</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center " onClick={() => copyToClipboard()} >
                      <RiShareFill className="IconsFHSSP" />
                      <p className="m-0 ms-2 fw-bold">Share</p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex justify-content-between mt-4" onClick={toggleContentBI} >
                    <div>
                      <h6 className="bookInfoTextRP ff-poppins-bold">
                        <b>Billng Information</b>
                      </h6>
                    </div>
                    <div>
                      {showContentBI ? (
                        <SlArrowUp className="me-2" />
                      ) : (
                        <SlArrowDown className="me-2" />
                      )}
                    </div>
                  </div>
                  {showContentBI && (
                    <div>
                      <div>
                        <p className="ff-poppins-regular fs-13">
                          Product Initiated at {product?.order_date_string} by{" "}
                          {user.user_name}
                        </p>
                      </div>
                    </div>
                  )}
                  <hr className="my-2" />

                  <div className="">
                    {product?.applied_card_detail?.offer_type == "3" ? (
                      <div className="servicePriceHeadDiv">
                        <h6 className="serviceTextRP">
                          <b>Buy Products</b>
                        </h6>
                        {buyproduct?.map((list, index) => {
                          return (
                            <div className="d-flex justify-content-between fs-14">
                              <p className="m-0"> {list?.product_name}</p>
                              <p className="m-0">
                                {list?.added_count} x {list?.price}
                              </p>
                            </div>
                          );
                        })}
                        <div className="totalBar fs-14 m-0 d-flex justify-content-between p-1 mt-2">
                          <p className="m-0 text-success">Total</p>
                          <p className="m-0 text-success">
                            Rs {product?.offer_details?.buy_products_total}
                          </p>
                        </div>

                        <h6 className="serviceTextRP mt-4">
                          <b>Get Products(Free)</b>
                        </h6>
                        {getproduct?.map((list, index) => {
                          return (
                            <div className="d-flex justify-content-between fs-14">
                              <p className="m-0"> {list?.product_name}</p>
                              <p className="m-0">
                                {list?.added_count} x {list?.price}
                              </p>
                            </div>
                          );
                        })}
                        <div className="totalBar fs-14 m-0 d-flex justify-content-between p-1 mt-2">
                          <p className="m-0 text-success">Total</p>
                          <p className="m-0 text-success">
                            Rs {product?.offer_details?.get_products_total}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h6 className="serviceTextRP ff-poppins-semiBold">
                          <b>Products</b>
                        </h6>

                        {orderdetail?.map((products, index) => {
                          return (
                            <div>
                              <div className="d-flex justify-content-between ff-poppins-regular fs-13" key={index}  >
                                <p className="m-0 ff-poppins-regular">  {products?.product_name}  </p>
                                <div className="d-flex">
                                  {
                                    products?.added_addons_details?.length > 0 ?
                                      <div onClick={() => toggleAddon(index)} style={{ cursor: 'pointer' }}>
                                        {showAddonsState[index] ? (
                                          <SlArrowUp className="me-2" />
                                        ) : (
                                          <SlArrowDown className="me-2" />
                                        )}
                                      </div>
                                      : ""
                                  }
                                  <p className="m-0 ms-2">
                                    {products?.added_count} x {Number(products?.price) + Number(products?.addons_cost)}
                                  </p>
                                </div>
                              </div>
                              <div className="m-0 fs-12 col-10">
                                {showAddonsState[index] ? (
                                  products?.added_addons_details?.map((addon, addonIndex) => (
                                    <div className="mt-1 d-flex justify-content-between ff-poppins-regular fs-12 ">
                                      <div>  {addon?.addon_name} - {addon?.name}  </div>
                                      <div>  {products?.added_count} x {addon?.price}  </div>
                                    </div>
                                  ))
                                ) : (
                                  products?.added_addons_details?.map((addon) => addon.name.trim()).join(', ')
                                )}
                              </div>
                            </div>


                          );
                        })}
                        {product?.card_applied_status == "1" ? (
                          <div className="totalBar fs-13 m-0 p-1 mt-2">
                            {product?.discount_status == "1" ? (
                              <div className="d-flex justify-content-between">
                                <p className="m-0 text-success">
                                  {product?.discount_detail?.lable}{" "}
                                </p>
                                <p className="m-0 text-success">
                                  Rs {product?.discount_detail?.discount_amount}
                                </p>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between">
                                <p className="m-0 text-success">Total</p>
                                <p className="m-0 text-success">
                                  Rs {product?.discount_price}
                                </p>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div style={{ borderBottomColor: "gray", borderBottomWidth: 2, borderBottomStyle: "dashed", marginTop: 10, }}></div>
                        )}
                      </div>
                    )}
                  </div>
                  {
                    product?.redeemed_loyalty?.loyalty_status == "1" ?
                      <div className="ff-poppins-regular fs-13 mt-2">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 ff-poppins-regular">Subtotal</p>
                          <p className="m-0 ff-poppins-regular">
                            {product.sub_toatl}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0 ff-poppins-regular">Pingle Points redeemed{(product?.redeemed_loyalty?.loyalty_points)}</p>
                          <p className="m-0 ff-poppins-regular">
                            - {product.sub_toatl}
                          </p>
                        </div>
                      </div>
                      :
                      <div className="servicePriceHeadDiv dashBorderRP ff-poppins-regular fs-13">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 ff-poppins-regular">Subtotal</p>
                          <p className="m-0 ff-poppins-regular">
                            {product.sub_toatl}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0 ff-poppins-regular">Packing Cost</p>
                          <p className="m-0 ff-poppins-regular">
                            {product.packing_charge}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0 ff-poppins-regular"> Platform Charge</p>
                          <p className="m-0 ff-poppins-regular">
                            {product.user_platform_charges}
                          </p>
                        </div>
                        {gststatus == true ? (
                          <div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 ff-poppins-regular"> SGST</p>
                              <p className="m-0 ff-poppins-regular">
                                {product.sgst_amount}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 ff-poppins-regular"> CGST </p>
                              <p className="m-0 ff-poppins-regular">
                                {product.cgst_amount}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                  }


                  <div className="dashBorderRP"></div>

                  {
                    // cash after delivery
                    payment.payment_method == '1' ?
                      <div className="ff-poppins-bold mt-3 fs-14 border-Bottom-Dashed">
                        <div className="d-flex justify-content-between">
                          <p className="">Total</p>
                          <p className="d-flex me-2 fs-14">
                            {payment.payment_status == 0 ? (
                              <button className="unpaidbtn me-2 fs-12 ff-poppins-semiBold ">
                                UNPAID
                              </button>
                            ) : (
                              <button className="paidBtnSP me-2 fs-12 ff-poppins-semiBold">
                                PAID
                              </button>
                            )}
                            {product.total_amount}
                          </p>
                        </div>
                      </div>
                      : payment.payment_method == '2' ?
                        <div className="ff-poppins-bold mt-3 fs-15">
                          <div className="d-flex justify-content-between">
                            <p className="">Total</p>
                            <p className="d-flex me-2 fs-14">
                              {payment.payment_status == 0 ? (
                                <button className="unpaidbtn me-2 fs-12 ff-poppins-semiBold ">
                                  UNPAID
                                </button>
                              ) : (
                                <button className="paidBtnSP me-2 fs-12 ff-poppins-semiBold">
                                  PAID
                                </button>
                              )}
                              {product.total_amount}
                            </p>
                          </div>
                        </div>
                        :
                        payment.payment_method == '3' ?
                          <div>
                            <div className="ff-poppins-bold mt-3 fs-15">
                              <div className="d-flex justify-content-between">
                                <p className="">Total</p>
                                <p className="d-flex me-2 fs-14">
                                  {product.total_amount}
                                </p>
                              </div>
                            </div>
                            <div className="ff-poppins-bold fs-15">
                              <div className="d-flex justify-content-between">
                                <p className="">Advance amount</p>
                                <p className="d-flex me-2 fs-14">
                                  <button className="paidBtnSP fs-12 ff-poppins-semiBold me-1">
                                    PAID
                                  </button>
                                  {payment.advance_amount}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p className="">Balance amount</p>
                                <p className="d-flex me-2 fs-14">
                                  {payment.balance_status == 0 ? (
                                    <button className="unpaidbtn fs-12 ff-poppins-semiBold me-1">
                                      UNPAID
                                    </button>
                                  ) : (
                                    <button className="paidBtnSP fs-12 ff-poppins-semiBold me-1">
                                      PAID
                                    </button>
                                  )}
                                  {payment.balance_amount}
                                </p>
                              </div>
                            </div>
                          </div> :
                          payment.payment_method == '4' ?
                            <div className="ff-poppins-bold mt-2  fs-14 border-top-Dashed">
                              <div className="d-flex justify-content-between mt-2" >
                                <p className="">Total</p>
                                <p className="d-flex me-2 fs-14">
                                  <button className="paidBtnSP me-2 fs-12 ff-poppins-semiBold"> PAID  </button>  0
                                </p>
                              </div>
                            </div>
                            : <></>
                  }





                  {product.order_status == 3 ? (
                    <div>
                      <h6 className="m-0 ff-poppins-semiBold fs-14">
                        Cancel Information
                      </h6>
                      <p className="fs-12 m-0 p-0 ff-poppins-regular">{cancelorder.cancel_description} </p>
                      <h6 className="m-0 ff-poppins-semiBold fs-14">Refund Information</h6>
                      <p className="fs-12 m-0 p-0 ff-poppins-regular">{cancelorder.refund_description} </p>
                      {cancelorder.refund_status == "1" ? (
                        <div>
                          <h6 className="m-0 ff-poppins-semiBold fs-14">Refund Status</h6>
                          <p className="fs-12 m-0 p-0 ff-poppins-regular">
                            {cancelorder.refund_process_status == "1" ? (
                              "Refund Processing"
                            ) : cancelorder.refund_process_status == "2" ? (
                              "Refund Complete"
                            ) : cancelorder.refund_process_status == "3" ? (
                              "Refund Faild"
                            ) : (
                              <></>
                            )}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  {product.dispute == true ?
                    <div>
                      <div>
                        <h5 className="fs-15 ff-poppins-bold m-0">Dispute Status</h5>
                        <p className="secondary-text-1 fs-13 ">{product?.dispute_detail?.dispute_status_name} </p>
                      </div>
                      <div>
                        <h5 className="fs-15 ff-poppins-bold m-0">Dispute description</h5>
                        <p className="secondary-text-1 fs-13">{product?.dispute_detail?.dispute_description} </p>
                      </div>
                      <div>
                        <h5 className="fs-15 ff-poppins-bold m-0">Dispute reason</h5>
                        <p className="secondary-text-1 fs-13">{product?.dispute_detail?.dispute_reason} </p>
                      </div>
                    </div> : <></>
                  }
                </div>
              </div>
            </div>{" "}
            {product.order_status == 1 && product.delivery_status <= 4 ? (
              <><div className="footerBtnSP">
                <button className="rescheduleCancelBtnPP ff-poppins-semiBold" onClick={() => getrefund()} >
                  Cancel</button>
              </div>
                <a href="https://pingle.in/user/refund-policy.php" className="text-dark text-decoration-none fs-12 text-center ff-poppins-semiBold" target="_blank" rel="noopener noreferrer">
                  <div className="text-center mb-3">
                    Read our <span className="primary-text">Cancellation Policy</span> and <span className="primary-text">Refund Policy</span>
                  </div>

                </a>
              </>
            ) : (
              <></>
            )}
          </div>
          {
            product.dispute == true ?
              <div>
                <p className="primary-text text-center fs-14 ff-poppins-semiBold mt-4" onClick={openDisputeModal}>Raise Dispute</p>
              </div> : <></>
          }


          {product.payment_details?.balence_request?.request_status == 1 &&
            product.payment_details.payment_status == "0" ? (
            <></>
          ) : (
            // <div className="offcanvas-parentType3 position-relative">
            //   <div class="modal-content p-3">
            //     <div class="">
            //       <h1
            //         class="d-flex ff-poppins-semiBold justify-content-center fs-16 mb-3"
            //         id="staticBackdropLabel"
            //       >
            //         Pay Balance
            //       </h1>
            //     </div>
            //     <div class="">
            //       <p className="m-0 fs-15 ff-poppins-regular text-center mb-1">
            //         <span>IK Salon</span> Has requested you to pay the balance
            //         amount
            //       </p>
            //       <p className="m-0 ff-poppins-regular text-primary text-center fs-15 mb-3">
            //         {payment.balance_amount}
            //       </p>
            //     </div>
            //     <div class="d-flex justify-content-center gap-3">
            //       <button class="btn btn-success px-5 rounded-pill"  > Pay </button>
            //       <button type="button" class="btn btn-danger px-5 rounded-pill" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
            //       >
            //         Reject
            //       </button>
            //     </div>
            //   </div>
            // </div>
            <></>
          )}
        </div>

        {/* Cancel modal  */}

        <div class="offcanvas mainModal CancelProduct cancelBtnModalProductPass"
          style={{ position: "sticky !important", minHeight: "55px !important", borderTopLeftRadius: 15, borderTopRightRadius: 15 }} tabindex="-1" aria-labelledby="offcanvasBottomLabel" ref={cancelRefundModalRef} >
          <div className="refundContentModal mb-2 refundModal">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-center">
                <p className="m-0 ff-poppins-bold fs-14">Cancel Product</p>
              </div>
              <div onClick={() => { cancelRefundModalRef.current.classList.remove("show"); }} >
                <p className="ff-balooPaaji2-semiBold p-2 m-0 pg-primary"   >
                  <IoMdCloseCircle />
                </p>
              </div>
            </div>
            <p className="m-0 fs-12 ff-poppins-regular" >
              Are you sure you want to cancel the product and get refund? Please
              read the read the refund policy before clicking the 'confirm'
              button.
            </p>
            <p className="mt-3 mb-2 ff-poppins-bold fs-14"> Refund amount </p>
            <p className="m-0 mb-2 ff-poppins-regular fs-12">{refund}</p>
            <Link
              to={"https://pingle.in/user/refund-policy.php"}
              className="text-dark text-decoration-none fs-14 text-center"
            >
              <div className="text-center ff-poppins-regular fs-12">
                Read our{" "}
                <span className="primary-text"> Cancellation Policy </span> and{" "}
                <span className="primary-text">Refund Policy</span>
              </div>
            </Link>
          </div>
          <button className="text-center text-white rescheduleCancelBtnPP ff-poppins-semiBold"
            style={{ marginTop: 15, position: "relative", padding: "10px 20px", backgroundColor: "#primary-bg", color: "white", border: "0px", borderRadius: "5px", height: "40px", lineHeight: "20px", verticalAlign: "middle", }}
            onClick={() => {
              if (!buttonClicked) {
                cancel_order();
              }
            }}
            disabled={buttonClicked}
          >
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Loading type="spinningBubbles" color="#fff" height={20} width={20} />
              </div>
            )}
            {!loading && "Confirm"}
          </button>

        </div>

        <div className={`modal fade`} id="exampleModalConfirm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={cancelConfirmModalRef}  >
          <div class="modal-dialog m-0 modal-dialog-centered mainModal px-5 d-flex">
            <div class="modal-content px-4 py-2">
              <div class="modal-body d-flex justify-content-center ff-poppins-bold">
                Order Cancelled
              </div>

              <img src={AppointmentCancelled} alt="My Image" />

              <div class="mb-4 d-flex text-center justify-content-center ff-poppins-regular">
                The order has been cancelled successfully.
              </div>
              <button class="text-center text-white rescheduleCancelBtnPP ff-poppins-semiBold" onClick={handleOkClick}   >
                OK{" "}
              </button>
            </div>
          </div>
        </div>

        <div class="modal fade p-3" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1"    >
          <div class="modal-dialog modal-dialog-centered mainModal p-4">
            <div class="modal-content p-3">
              <div class="">
                <h1 class="d-flex ff-poppins-semiBold justify-content-center fs-16 mb-3" id="staticBackdropLabel"   >
                  Cancel Request
                </h1>
              </div>
              <div class="">
                <p className="m-0 fs-15 ff-poppins-regular text-center mb-3">
                  Are you sure want to cancel the payment request?
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <button class="btn primary-bg px-5 rounded-pill text-white" data-bs-dismiss="modal" onClick={() => cancelBalanceRequest()}   >
                  Confirm
                </button>
                <button type="button" class="btn secondary-bg-1 px-5 rounded-pill" data-bs-target="#exampleModalToggle" data-bs-toggle="modal"   >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`modal fade`} id="successMoadl" tabIndex="-1" aria-labelledby="successMoadl" aria-hidden="true" ref={paymentSuccessModalRef} data-bs-backdrop="false" style={{ backgroundColor: "#fff3" }} >
          <div className="modal-dialog modal-dialog-centered mainModal">
            <div className="modal-content modal-success" style={{ width: "80%" }}>
              <div className="modal-header">
                <div>
                  <div>
                    <div>
                      <Lottie animationData={SuccessLotie} loop={false} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body modal-bodyRP">
                <div className="text-center fw-bold primary-text">
                  Payment Success.
                </div>
                <div className="text-center fw-bold">
                  Redirecting you to your ticket.
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn okBtnRP" onClick={handleSuccessOkClick}    >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="offcanvas" tabindex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390 }} ref={canceldisputeModalRef}  >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title ff-poppins-semiBold fs-16" id="offcanvasBottomLabel">Dispute reason</h5>
            <button type="button" class="btn-close" onClick={closeDisputeModal}></button>
          </div>
          <div class="offcanvas-body small">
            <textarea className="w-100 rounded-2 p-2 mb-3" placeholder="please provide details of your dispute reason."
              value={dispute}
              onChange={(e) => setDispute(e.target.value)}>
            </textarea>
            <button className="w-100 primary-bg border-0 text-white ff-poppins-semiBold py-2 rounded" onClick={() => raisedispute()}>Raise Dispute</button>
          </div>
        </div>
      </div>

      <div className="offcanvas offcanvas-bottomm offcanvass main" style={{ height: 90 }} data-bs-scroll="true" data-bs-target="#staticBackdrop" tabIndex="-1" id="selectCustomerModalProductRP" aria-labelledby=""  >
        <div className=" ">
          <div className="d-flex justify-content-center">
            <div class="modal-content p-3 bg-white">
              <div class="">
                <h1 class="d-flex ff-poppins-semiBold justify-content-center fs-16 mb-3" id="staticBackdropLabel"  >
                  Pay Balance
                </h1>
              </div>
              <div class="">
                <p className="fs-13 text-center">
                  <span> {vendor?.vendor_company_name}</span> Has requested you
                  to pay the balance amount
                </p>
                <p className="m-0 ff-poppins-regular fs-14 text-primary text-center mb-3">
                  (Rs.{payment?.balance_amount})
                </p>
              </div>
              <div class="d-flex justify-content-center gap-3">
                <button class="PayBtn px-5 rounded-pill" onClick={payBalance}>
                  {" "}
                  Pay{" "}
                </button>
                <button
                  type="button"
                  class="rejectBtn px-5v rounded-pill"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="offcanvas addOnModel_scroll p-2"
        style={{ maxHeight: "100%", borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390 }} tabindex="-1" aria-labelledby="offcanvasBottomLabel" ref={editOrderModalRef} >
        <div className="mb-2">
          <p className="m-0 mb-3 fs-12 ff-poppins-regular mt-2">{vendor?.vendor_company_name} has requested you to edit the order products</p>

          <div className="mt-2">
            <div className="position-relative ">
              <div className="position-absolute top-0 start-50 translate-middle bg-white fs-14 ff-poppins-semiBold">Order Products</div>
              <div className="edit_Order_Box   mt-2">
                {
                  (() => {
                    let requestedProducts = editOrder?.requested_products ? JSON.parse(editOrder?.requested_products) : [];
                    return orderdetail?.map((proId, index) => {
                      requestedProducts.includes(proId?.product_id)
                      return (
                        <div>
                          <div className="d-flex justify-content-between ff-poppins-regular fs-13 mt-1 mb-1" key={index}  >
                            <div className="m-0 ff-poppins-regular fs-12">  {proId?.product_name} </div>
                            <div className="d-flex ">
                              {
                                proId?.added_addons_details?.length > 0 ?
                                  <div onClick={() => toggleAddon(index)} style={{ cursor: 'pointer' }}>
                                    {showAddonsState[index] ? (
                                      <SlArrowUp className="me-2" />
                                    ) : (
                                      <SlArrowDown className="me-2" />
                                    )}
                                  </div>
                                  : ""
                              }
                              <p className="m-0 fs-12 ms-2">
                                {proId?.added_count} x {Number(proId?.price) + Number(proId?.addons_cost)}
                              </p>
                            </div>
                          </div>
                          {
                            proId?.added_addons_details?.length > 0 ?
                              <div className="m-0 fs-10 col-10 ff-poppins-regular">
                                {showAddonsState[index] ? (
                                  proId?.added_addons_details?.map((addon, addonIndex) => (
                                    <div className="mt-1 d-flex justify-content-between">
                                      <div>  {addon?.addon_name} - {addon?.name}  </div>
                                      <div>  {proId?.added_count} x {addon?.price}  </div>
                                    </div>
                                  ))
                                ) : <span className="fs-10"> ({proId?.added_addons_details?.map((addon) => addon.name.trim()).join(', ')})</span>}
                              </div> : ""
                          }

                        </div>
                      );
                    });
                  })()
                }
              </div>
            </div>
            <div className="position-relative mt-4">
              <div className="position-absolute top-0 start-50 translate-middle bg-white fs-14 ff-poppins-semiBold">
                Changed Products
              </div>
              <div className="edit_Order_Box mt-2">
                {orderdetail?.length > 0 &&
                  orderdetail?.map((data, index) => {
                    let requestedProducts = editOrder?.requested_products ? JSON.parse(editOrder?.requested_products) : [];
                    const checkedIndex = requestedProducts?.findIndex((p) => p.productId === data?.product_id);

                    if (checkedIndex > -1) {
                      const selectedProduct = requestedProducts[checkedIndex];

                      return (
                        <div className="mt-1 mb-1" key={index}>
                          <div className="ff-poppins-regular fs-12">
                            <div className="m-0 primary-text fs-12">{data?.product_name}</div>
                            <span className="fs-10">
                              {data?.added_addons_details
                                ? data?.added_addons_details?.map((addon) => addon?.name?.trim()).join(", ")
                                : ""}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between fs-11 ff-poppins-regular mt-2">
                            <div>
                              <p className="m-0">Order Product: {data?.added_count}</p>
                              <p className="m-0">
                                Available Now: {data?.added_count - selectedProduct?.unAvailableCount}
                              </p>
                            </div>
                            <div>
                              <div className="mt-3">
                                {selectedProduct?.availableType === 4 ? (
                                  <span className="primary-text">Permanently Unavailable</span>
                                ) : (
                                  <>
                                    {"Next Available From: "}
                                    {selectedProduct?.availableType === 1 ? (
                                      <span className="primary-text">
                                        {hoursFromNow(selectedProduct?.availableTime)}
                                      </span>
                                    ) : selectedProduct?.availableType === 2 ? (
                                      <span className="primary-text">Tomorrow</span>
                                    ) : selectedProduct?.availableType === 3 ? (
                                      <span className="primary-text">
                                        {selectedProduct?.availableDate
                                          ? new Date(selectedProduct?.availableDate).toLocaleDateString(
                                            "en-GB",
                                            dateOption
                                          )
                                          : new Date().toLocaleDateString("en-GB", dateOption)}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {index < orderdetail.length - 1 && (
                            <div className="border_bottom-Dashed mb-2"></div>
                          )}
                        </div>
                      );
                    }
                    return null; // Return null if product is not found
                  })}
              </div>

            </div>
          </div>

          <div className="ff-poppins-regular fs-12 mt-2" style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
            <p className="m-0 ff-poppins-semiBold">Reason :</p>
            <p className="ms-2 mb-2 ff-poppins-regular">{editOrder?.request_reason}</p>
            <div className="d-flex justify-content-between">
              <button className="bg-success text-light ff-poppins-regular p-1 fs-12 border-0 rounded-4 col-5 ms-4" onClick={() => acceptEditorderRequest()} >Accept or Edit Order</button>
              <button className="bg-danger text-light ff-poppins-regular p-1 fs-12 border-0  rounded-4 col-5 me-4" onClick={() => cancelEditorderRequest()}>Reject & Cancel Order</button>
            </div>
          </div>



        </div>
      </div>
    </div>
  );
};

export default ProductPass;
