import { useNavigate } from 'react-router-dom';
import p_nav_previous from '../Assets/Images/p_nav_previous.svg';
import { deleteMyAccount } from '../Services/api';
import { useState } from 'react';

const AboutPingle = () => {

  const navigate = useNavigate();

  function deleteAccount() {
    deleteMyAccount().then((res) => {
      localStorage.removeItem("token");
      sessionStorage.clear();
      localStorage.clear();
      navigate('/login')
    });
  }

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
          <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>About</div>
        </div>
      </div>

      <div className='scroller-style-y m-3 p-1 d-flex flex-column justify-content-between'>
        <div className='ff-poppins-regular fs-16'>
          Pingle is a hyperlocal booking app that helps you to book appointments with hyperlocal businesses. Our platform offers a gamified booking experience, allowing you to search for services, book appointments, reschedule appointments, make payments and win loyalty points. With Pingle's reliable service and unparalleled convenience, discover and book services from the comfort of your home. In nutshell, Pingle makes it easy for you.
        </div>

        <div className=''>
          <a href='https://pingle.in/user/privacy_policy.php' target='_blank' className='text-decoration-none text-dark'>
            <div className='ff-poppins-regular fs-16 text-center linkBtn' role='button'>
              Privacy Policy
            </div>
          </a>
          <a href='https://pingle.in/user/terms-conditons.php' target='_blank' className='text-decoration-none text-dark'>
            <div className='ff-poppins-regular fs-16 text-center linkBtn' role='button'>
              Terms and Conditions
            </div>
          </a>
          {/* <div className='ff-poppins-regular fs-16 text-center linkBtn' role='button' data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Delete account
                    </div> */}

          <div className="ff-poppins-regular fs-16 text-center linkBtn" role="button" onClick={openModal}>
            Delete account
          </div>

          <div className='ff-poppins-regular fs-10 text-center mb-3'>
            App Version PU 1 .2 .1
          </div>
        </div>
      </div>


      {/* Modal */}
      {modalOpen && (
        <div className="modal fade show successModal" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true">
          <div className="modal-dialog modal-dialog-centered modal-sm m-0">
            <div className="modal-content">
              <div className="modal-body ps-5 pe-5 pt-4 pb-4">
                <div className="text-center ff-poppins-semiBold fs-16 mb-3">Delete account</div>
                <div className="text-center ff-poppins-regular fs-12">Are you sure you want to delete your account?</div>

                <div className="d-flex justify-content-between mt-3">
                  <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                  <button type="button" className="btn btn-danger" onClick={deleteAccount}>Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Backdrop */}
      {modalOpen && <div className="modal-backdrop fade show"></div>}
    </>
  )
}

export default AboutPingle