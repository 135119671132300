import React from 'react';


const VerticalStepper = ({ steps }) => {
  return (
    <div style={{ width: '100%', maxWidth: 360 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        {steps && Array.isArray(steps) && steps.map((step, index) => {
          return (
            <div key={index} style={{ position: 'relative', display: 'flex', alignItems: 'center', marginBottom: 18 }}>
              <div style={{ width: 14, height: 14, zIndex: 10, top: 4, borderRadius: '50%', backgroundColor: index === 0 ? 'green' : (step.status === '1' ? 'green' : 'gray'), marginRight: 10, flexShrink: 0 }}></div>
              <div style={{ flexGrow: 1 }} className='fs-13 ff-poppins-regular m-0'>
                <span  style={{ marginBottom: 4 }}>{step.lable}</span><br></br>
                {/* Call formatDate function to format the date */}
                {step.date && <span style={{ fontSize: 12, color: 'gray' }}>
                  {new Date(step.date.substring(0, 10)).toDateString().substring(4, 20)}

                </span>}
              </div>
              {index !== steps.length - 1 &&
                <>
                  <div style={{ position: 'absolute', left: 6, top: 17, width: 2, height: 49, backgroundColor: steps[index + 1].status === '1' ? 'green' : 'gray' }}></div>
                  <div style={{ position: 'absolute', left: 6, top: 50, width: 2, height: 48, backgroundColor: steps[index + 1].status === '1' ? 'green' : 'gray' }}></div>
                </>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalStepper;
