import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import p_storeprofile_map from '../../Assets/Images/p_storeprofile_map.svg';
import p_storeprofile_history from '../../Assets/Images/p_storeprofile_history.svg';
import p_storeprofile_follow from '../../Assets/Images/p_storeprofile_follow.svg';
import p_storeprofile_share from '../../Assets/Images/p_storeprofile_share.svg';
import p_dash_call from '../../Assets/Images/p_dash_call.svg';
import p_dash_text from '../../Assets/Images/p_dash_text.svg';
import p_right_arrow from '../../Assets/Images/p_right_arrow.svg';
import p_storeprofile_logo_background from '../../Assets/Images/p_storeprofile_logo_background.svg';
import p_storeprofile_logo from '../../Assets/Images/p_storeprofile_logo.svg';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSearchVendor, getVendorProfile } from '../../Services/api';

const StoreProfile = () => {
    const valueParam = useParams();
    const [profile, setProfile] = useState();
    const [profileDetails, setProfileDetails] = useState();

    useEffect(() => {

        getVendorProfile(valueParam).then((res) => {
            setProfile(res.data?.vendor_profile);
            setProfileDetails(res.data);
          
        })
    }, []);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' />
                    <div className='ff-poppins-medium fs-16 ms-3'>{profile?.vendor_details?.vendor_company_name}</div>
                </div>
            </div>

            <div className='storeProfile scroller-style-y'>
                <div className='imageBanner'>

                </div>

                <div className='details'>

                    <div className='bg-white p-3 rounded-1'>
                        <div className='ff-poppins-semiBold fs-16 primary-text'>{profile?.vendor_details?.vendor_company_name}</div>
                        <div className='ff-poppins-regular fs-10'>MID : {profile?.vendor_details?.vendor_id}</div>
                        <div className='ff-poppins-semiBold fs-10'>{profile?.vendor_details?.vendor_type_name}</div>

                        <div className='mt-3 pt-1'>
                            <div className='ff-poppins-semiBold fs-10'>Address</div>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-9 ff-poppins-regular fs-10 ps-0'>
                                        {profile?.vendor_details?.vendor_address}
                                    </div>
                                    <div className='col-3 ff-poppins-regular fs-10 text-center border-start'>
                                        <img src={p_storeprofile_map} width={'14px'} height={'20px'} alt='Map' />
                                        <div>Map</div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center pt-3 mt-2'>
                                <div className='info text-center'>
                                    <img src={p_storeprofile_follow} width={'15px'} height={'20px'} alt='Follow' />
                                    <div className='ff-poppins-regular fs-10 mt-1'>{profileDetails.follow_show_message}</div>
                                </div>
                                <div className='info text-center'>
                                    <img src={p_storeprofile_history} width={'12px'} height={'20px'} alt='Follow' />
                                    <div className='ff-poppins-regular fs-10 mt-1'>History</div>
                                </div>
                                <div className='info text-center'>
                                    <img src={p_storeprofile_share} width={'16px'} height={'18px'} alt='Follow' />
                                    <div className='ff-poppins-regular fs-10 mt-1'>Share</div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className='contactCard primary-bg mt-2'>
                        <div className='text-white details'>
                            <div className='ff-poppins-regular fs-12'>Captain</div>
                            <div className='ff-poppins-semiBold fs-14'>{profile?.contact_details?.contact_name}</div>
                        </div>
                        <div className='mt-3 pt-1 text-end'>
                            <button className='text-center primary-text ff-poppins-semiBold fs-12 border-0 bg-white rounded-1 me-2'>Call</button>
                            <button className='text-center primary-text ff-poppins-semiBold fs-12 border-0 bg-white rounded-1'>Text</button>
                        </div>
                    </div>

                    <div className='bg-white rounded-1 p-3 mt-2'>
                        <div className='ff-poppins-semiBold fs-16'>Book a Staff/Service</div>
                        <div className='ff-poppins-regular fs-10'>Lorem ipsum dolor sit amet consectetur. Semper elementum dignissim pretium.</div>

                        {
                            profile?.vendor_details?.staff_app_status == '1'
                                ?
                                <div className='primary-bg mt-3 pt-1 rounded ff-poppins-medium fs-12 text-white'>
                                    <div className='d-flex'>
                                        <div className='w-50 p-2 text-center border-end m-1'>
                                            Select Staff
                                        </div>
                                        <Link className='text-decoration-none text-white w-50 ' to={`/door-step-service/${profile?.vendor_details?.vendor_id}`}>
                                            <div className='p-2 text-center m-1'>
                                                Select Service
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                :
                                <div className='d-flex justify-content-end'>
                                    <div className='w-50 primary-bg mt-3 pt-1 rounded ff-poppins-medium fs-12 text-white'>
                                        <div className='p-2 text-center m-1'>
                                            Select Service
                                        </div>
                                    </div>
                                </div>

                        }

                    </div>

                    {
                        profile?.vendor_details?.doorstep_status == '1'
                            ?
                            <div className='bg-white rounded-1 p-3 mt-2'>
                                <div className='ff-poppins-semiBold fs-16'>Door step service</div>
                                <div className='ff-poppins-regular fs-10'>Lorem ipsum dolor sit amet consectetur. Semper elementum dignissim pretium.</div>

                                <div className='primary-bg mt-3 pt-1 rounded ff-poppins-medium fs-12 text-white'>
                                    <div className='d-flex'>
                                        <div className='w-50 p-2 text-center border-end m-1'>
                                            Select Staff
                                        </div>
                                        <div className='w-50 p-2 text-center m-1'>
                                            Select Service
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }

                    <div className='bg-white rounded-1 p-3 mt-2'>
                        <div className='ff-poppins-semiBold fs-16'>Membership Cards</div>
                        <div className='ff-poppins-regular fs-10'>Buy memberships and get great benefits on your purchases.</div>

                        <div className='primary-bg mt-3 pt-1 rounded ff-poppins-medium fs-12 text-white'>
                            <div className='d-flex'>
                                <div className='w-50 p-2 text-center border-end m-1'>
                                    My Cards
                                </div>
                                <div className='w-50 p-2 text-center m-1'>
                                    Buy Now
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='loyaltyCard mt-2 white-bg pt-3 pb-3 ps-2 pe-2'>
                        <div className='banner d-flex justify-content-center align-items-center position-relative' style={{ backgroundImage: `url(${p_storeprofile_logo_background})` }}>
                            <img src={p_storeprofile_logo} width={'95px'} height={'95px'} alt='Pingle Logo' />
                            <div className='position-absolute text-center points'>
                                <div className='ff-poppins-bold fs-48 primary-text'>0</div>
                                <div className='ff-poppins-semiBold fs-14'>Loyalty Points</div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='text-center'>
                                <div className='button ff-poppins-semiBold fs-14 primary-bg text-light d-flex justify-content-center align-items-center rounded-pill'>
                                    Redeem
                                    <img src={p_right_arrow} width={'24px'} height={'24px'} alt='Text' />
                                </div>
                                <div className='ff-poppins-medium fs-14 mt-3'>Offers Eligible</div>
                            </div>
                        </div>
                    </div>

                    <div className='aboutCard mt-2 white-bg'>
                        <div className='ff-poppins-semiBold fs-24 primary-text title'>About</div>
                        <div className='ff-poppins-semiBold fs-24 primary-text'>Working hours</div>

                        <table className='w-100 mt-2'>
                            {
                                profile?.working_hours?.map(item => {
                                    if (item.day_status) {
                                        return (
                                            <tr key={item.day}>
                                                <td className='ff-poppins-regular fs-14'>{item.day}</td>
                                                <td className='ff-poppins-regular fs-13 text-end text-danger'>{item.day_status_msg}</td>
                                            </tr>
                                        )
                                    } else {
                                        return (
                                            <tr>
                                                <td className='ff-poppins-regular fs-14'>{item.day}</td>
                                                <td className='ff-poppins-regular fs-13 text-end'>{item.timing}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </table>

                    </div>
                </div>
            </div>
        </>
    )
}

export default StoreProfile