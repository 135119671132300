import React, { useCallback, useEffect, useState } from 'react';
import '../../Assets/Styles/offCanvas.css';
import { cartAddServiceTimeslot, checkValidCart, confirmBooking, getMyProfileDetails, serviceTimeSlotDetailsNew } from '../../Services/api';
import { useNavigate, useParams } from 'react-router-dom';
//firebase
import { getDatabase, ref, set, onValue } from "firebase/database";
import database from '../../Services/firebase-service/firebase';
import ReactLoading from 'react-loading';
import useRazorpay from "react-razorpay";
import { toast } from 'react-toastify';
import axiosInstance, { Razorpay_Key } from '../../Services/axios';

const PayNowOffCanvas = ({ isOpen, paymentMethod, payAmount, toggleOffCanvas, getbookingId }) => {

    const navigate = useNavigate();
    const valueParam = useParams(); // URL parameter hook
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);


    const [paymentOrderId, setPaymentOrderId] = useState();
    const [userId, setUserId] = useState();
    const [Razorpay] = useRazorpay();
    const [offerServiceRedeemStatus, setofferServiceRedeemStatus] = useState()
    const [loyaltyPoints, setLoyaltyPoints] = useState()
    // function addServiceTimeSlot() {
    //     let localServiceDetail = localStorage.getItem('serviceDetail')
    //         ? JSON.parse(localStorage.getItem('serviceDetail'))
    //         : '';

    //     let param = {
    //         "vendor_id": valueParam.vendor_id,
    //         "cart_app_id": (localServiceDetail && localServiceDetail?.cart_app_id) ? localServiceDetail?.cart_app_id : '',
    //         "booking_type": "1",
    //         "date": date,
    //         "time_section": section,
    //         "time_slot": slot,
    //         "service_type": (localServiceDetail && localServiceDetail?.serviceType) ? localServiceDetail?.serviceType : '',
    //     };

    //     cartAddServiceTimeslot(param).then((res) => {

    //         if (res.data?.status == '1') {
    //             navigate(`/review-and-pay/${valueParam.vendor_id}`)
    //         }
    //     })
    // }

    useEffect(() => {
        // if (!currentUser) return; // Check if user is not logged in
        const database = getDatabase();
        const orderRef = ref(database, `/payments/service_booking/${userId}`);
        // const orderRef = ref(database, `/payments/product_order/23U00002`);
        const unsubscribe = onValue(orderRef, snapshot => {
            const data = snapshot.val();
            console.log(`firebase value data`, data);
            if (data?.status === 2 && data?.order_id === paymentOrderId) {
                toggleOffCanvas()
                console.log("booking_ID", data.booking_id);
                
                getbookingId(data.booking_id)

                // openPaymentSuccessModal()
            }
            // if (data.status === 3 && data.order_id === paymentOrderId) {
            // }
        });
        return () => unsubscribe(); // Detach listener on cleanup

    }, [paymentOrderId]);
    useEffect(() => {
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
        let offer_redeem_status = localServiceDetail.offer_status ? localServiceDetail.offer_status : '';
        let loyalty_redeem_points = localServiceDetail.loyalty_points ? localServiceDetail.loyalty_points : '';
        setLoyaltyPoints(loyalty_redeem_points)
        setofferServiceRedeemStatus(offer_redeem_status)
    }, [])
    function payNow() {
        // Set loading state to true when the payment process starts
        setIsLoading(true);

        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';

        let param = {
            "vendor_id": valueParam.vendor_id,
            "cart_app_id": localServiceDetail.cart_app_id,
            "by_pingle_points": 0,
            "payment_method": paymentMethod
        };

        checkValidCart(param).then((res) => {
            let userDetail = {
                name: "",
                phone: "",
                email: "",
            };
            console.log("checkValidCart", res);

            if (res.data.status == '1') {
                setIsValid(true)
                if (paymentMethod == '3') {
                    bookingConfirm();
                } else {
                    setPaymentOrderId(res.data?.payment?.id);
                    setUserId(res.data?.user_id);
                    handlePayment(userDetail, res.data?.payment);
                }
            }
            setIsLoading(false);
        }).catch((error) => {
            if (error.response.status == 400) {
                // Toast.show({
                //     type: 'error',
                //     text1: error.response.data.message,
                //     position: 'bottom',
                //     bottomOffset: 50,
                // }); 

                toast(error.response.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
                setIsLoading(false)
                setIsValid(false)
            }
            if (error.response.status == 500) {
                toast('something went wrong please try again', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
                setIsLoading(false)
            }
        });
    }

    function bookingConfirm() {
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';

        let param = {
            "cart_app_id": localServiceDetail.cart_app_id,
        };

        confirmBooking(param).then((res) => {
            console.log("Booking res ===>>>", res);

            if (res.data.status == '1') {
                console.log("Booking res ===>>>", res);

                navigate('/', { replace: true });
            }
        });
    }

    const handlePayment = useCallback((userData, orderData) => {
        const createOrderAndInitializePayment = async () => {
            const options = {
                key: Razorpay_Key,
                amount: orderData.amount,
                currency: orderData.currency,
                name: "PINGLE",
                description: "Payment",
                image: 'https://pingle.in/user/assets/images/LogoUser.png',
                order_id: orderData.id,
                handler: (res) => {
                    console.log("res===============", res);

                    // if (onPayment) {
                    //     onPayment(res); // Passing the order ID to the parent component
                    // }
                },
                prefill: {
                    name: userData.name,
                    email: userData.email,
                    contact: userData.phone,
                },
                theme: {
                    color: "#4E26A3",
                },
            };
            console.log("options", options);

            const rzpay = new Razorpay(options);
            rzpay.open();
        };

        createOrderAndInitializePayment();
    }, [Razorpay]);
    const redeem_loyalty = () => {
        try {
            let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
            let vendor_id = localServiceDetail.vendorId ? localServiceDetail.vendorId : '';
            let cart_id = localServiceDetail.cart_app_id ? localServiceDetail.cart_app_id : '';
            let loyalty_id = localServiceDetail.loyalty_offerID ? localServiceDetail.loyalty_offerID : '';
            let service_type = localServiceDetail.serviceType ? localServiceDetail.serviceType : '';

            let reqData = {
                "vendor_id": vendor_id,
                "offer_id": loyalty_id,
                "cart_app_id": cart_id,
                "note_desc": ""
            }
            axiosInstance.post('/user/redeem_vendor_loyalty_offer', reqData)
                .then((res) => {
                    navigate('/')
                })
        }
        catch {

        }
    }

    return (
        isOpen ?
            <div className={`px-2 py-4`} style={{ width: '100%', position: 'sticky', bottom: 0, backgroundColor: '#fff', borderTopLeftRadius: 20, borderTopRightRadius: 20, marginTop: 10 }}>
                <div className="offcanvas-body small">

                    <textarea style={{ width: '100%', borderRadius: 6, height: 100 }} placeholder='Add notes and instructions if any' className='p-2'></textarea>

                    {
                        offerServiceRedeemStatus == '1' ?
                            <button type='button' className='btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white payNowBtn mt-3' onClick={() => redeem_loyalty()} >
                                Redeem Now
                            </button> :
                            paymentMethod == '3' ?
                                <button type='button' className='btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white payNowBtn mt-3'
                                    onClick={() => payNow()} >
                                    Booking Confirm
                                </button>
                                :
                                <>
                                    <div className='toPay'>
                                        <span className='ff-poppins-regular fs-13 primary-text'>To Pay </span>
                                        <span className='ff-poppins-bold fs-13'>RS {payAmount}</span>
                                    </div>

                                    <button type='button'
                                        className='btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white payNowBtn'
                                        onClick={() => payNow()}
                                        disabled={isLoading && isValid}
                                        style={{ position: 'relative' }} // Set position relative for button
                                    >
                                        {isLoading && (
                                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                <ReactLoading type={'spinningBubbles'} color={'#fff'} height={'20px'} width={'20px'} />
                                            </div>
                                        )}
                                        {!isLoading && 'Pay Now'}
                                    </button>
                                </>
                    }
                </div>
            </div> : <></>

    )
};

export default PayNowOffCanvas;