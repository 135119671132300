import { useCallback, useRef, useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import message_read from '../../Assets/Images/message_read.svg';
import message_unread from '../../Assets/Images/message_unread.svg';
import messenger_attachment from '../../Assets/Images/messenger_attachment.svg';
import messenger_send from '../../Assets/Images/messenger_send.svg';
import { createNewUserVendorChat, getChatList, sendAttachment, sendChatMessage, userSingleChatDetailsNew } from '../../Services/api';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../Assets/Styles/messenger.css';
import ImageCropperModal from '../../Components/modal/imageCropperModal';
import ChatParticipantOffCanvas from '../../Components/offCanvas/chatParticipantOffCanvas.js';
import { FaCamera } from "react-icons/fa";
import Webcam from 'react-webcam';
import { TbCapture } from "react-icons/tb";
import { MdCameraswitch } from "react-icons/md";
import { RiArrowGoBackFill } from "react-icons/ri";
import { IoSendSharp } from "react-icons/io5";
const SingleChat = () => {

    const navigate = useNavigate();
    const valueParam = useParams();
    const webcamRef = useRef(null);
    const showcamRef = useRef(null);
    const [chatDetails, setChatDetails] = useState(null);
    const [singleChatDetail, setSingleChatDetail] = useState(null);
    const [singleChatHistoryDetail, setSingleChatHistoryDetail] = useState(null);
    const [messageText, setMessageText] = useState(null);
    const containerRef = useRef(null);
    const location = useLocation();
    const [senderImage, setSenderImage] = useState(null);
    const [senderName, setSenderName] = useState(null);
    const fileRef = useRef(null);
    const [facingMode, setFacingMode] = useState('user');
    const [isImageCropperModaOpen, setIsImageCropperModaOpen] = useState(false);
    const [imageToCrop, setImageToCrop] = useState('');
    const [isChatParticipantOffCanvasOpen, setChatParticipantOffCanvasOpen] = useState(false);
    const [chatParticipants, setChatParticipants] = useState([]);
    const [isMessageSending, setMessageSending] = useState(false);
    const [croppedArea, setCroppedArea] = useState(null);
    const [showWebcam, setShowWebcam] = useState(false);
    const [newText, setNewText] = useState('')
    const [imageSrc, setImageSrc] = useState(null);
    const [cameraOption, setCameraOption] = useState('1')
    const openwebCamModal = () => {
        showcamRef.current.classList.add("show");
        showcamRef.current.style.display = "block";
        setShowWebcam(true);
        setImageSrc(null)
    };
    const closewebCamModal = () => {
        showcamRef.current.classList.remove("show");
        showcamRef.current.style.display = "none";
    };
    const handleCameraIconClick = () => {
        navigate('/WebcamCaptureWithCrop');
    };

    useEffect(() => {

        let param = {
            "vendor_id": valueParam.sender_id
        }
        createNewUserVendorChat(param).then((res) => {
            setChatDetails(res.data);
        });

        if (location.state && location.state.vendorName) {
            setSenderImage(location.state.vendorImage);
            setSenderName(location.state.vendorName);
        }
    }, [])

    useEffect(() => {

        if (chatDetails && chatDetails.status == '1') {
            getSingleChatDetails()
        }
    }, [chatDetails])

    function getSingleChatDetails() {

        let param = {
            "vendor_id": valueParam.sender_id,
            "chat_id": chatDetails.chat_id
        };

        userSingleChatDetailsNew(param).then((res) => {
            // Grouping messages by msg_date
           
            const groupedMessages = res.data?.chat_history?.reduce((acc, message) => {
                const date = message.msg_date;
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(message);
                return acc;
            }, {});
            setSingleChatHistoryDetail(groupedMessages);
            setSingleChatDetail(res.data);
            setChatParticipants(res.data?.chat_participants)

            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.scrollIntoView({ block: 'end' });
                }
            }, 200);
        });
    }

    function sendMessage() {

        setMessageSending(true);
        let param = {
            "chat_id": chatDetails.chat_id,
            "message": newText
        }
        sendChatMessage(param).then((res) => {

            if (res.data?.status == '1') {
                setNewText('');
                getSingleChatDetails();
                setMessageSending(false);
            }
        });
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month}, ${day < 10 ? '0' + day : day} ${year}`;
    };

    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file);

        if (base64) {
            setImageToCrop(base64);
            setIsImageCropperModaOpen(true);
        }
    }

    const convertBase64 = (file) => {

        if (file) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject('custom error', error);
                }
            })
        }
    }

    const onImageCropped = (e) => {

        if (e) {
            setMessageSending(true);
            const data = new FormData();
            data.append('file', e);
            data.append('chat_id', chatDetails.chat_id);

            sendAttachment(data)
                .then((res) => {

                    if (res.data.status === "1") {
                        getSingleChatDetails();
                        setIsImageCropperModaOpen(false);
                    }

                    setMessageSending(false);

                })
                .catch((error) => {

                    setMessageSending(false);
                });
            setIsImageCropperModaOpen(false);
            clearFileInput();
        }
    }

    const clearFileInput = () => {
        if (fileRef.current) {
            fileRef.current.value = null;
        }
    };

    const toggleChatParticipantOffCanvas = () => {
        setChatParticipantOffCanvasOpen(!isChatParticipantOffCanvasOpen);
    };
    const switchCamera = () => {
        setFacingMode((prevState) => (prevState === 'user' ? 'environment' : 'user'));

    };
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        // setShowWebcam(false)
        // setImageToCrop(imageSrc);
        // setIsImageCropperModaOpen(true);
        // closewebCamModal() 
        setImageSrc(imageSrc);
    }, [webcamRef]);
    const handelnavigate = () => {
        setShowWebcam(false);
        closewebCamModal()
    };

    const opponentProfileShowStatus = (section, index, item) => {
        let currentChat = section[index] ? section[index].msg_align == 'left' : section[index]
        let preChat = section[index + 1] ? section[index + 1].msg_align == 'left' : section[index + 1]
        let nextChat = section[index + 1] ? section[index + 1].msg_align == 'left' : section[index + 1]
        let nextChatsender_id = section[index - 1] ? section[index - 1].msg_writer_id : section[index - 1]
        let currentChatsender_id = section[index] ? section[index].msg_writer_id : section[index]
        let previousChatsender_id = section[index + 1] ? section[index + 1].msg_writer_id : section[index + 1]
        let imageshowStatus = true
        if (item.msg_align == 'left') {
            if (preChat == true && currentChat == true) {
                imageshowStatus = false
                if (nextChatsender_id == currentChatsender_id && currentChatsender_id != previousChatsender_id) {
                    imageshowStatus = true
                }
                if (nextChatsender_id != currentChatsender_id && currentChatsender_id != previousChatsender_id) {
                    imageshowStatus = true
                }
                if (nextChatsender_id == undefined && nextChatsender_id != currentChatsender_id) {
                    imageshowStatus = true
                    if (nextChatsender_id == undefined && nextChatsender_id != currentChatsender_id && currentChatsender_id == previousChatsender_id) {
                        imageshowStatus = false
                    }
                }
            }
        }
        return imageshowStatus;
    }

    const retake = () => {
        setImageSrc(null);
    };

    const handleOK = () => {
        // Handle the OK action, e.g., save the image or proceed to the next step
        if (imageSrc) {
            // onImageCropped(imageSrc);
            setShowWebcam(false)
            setImageToCrop(imageSrc);
            setIsImageCropperModaOpen(true);
            closewebCamModal()
        }
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white w-100 shadow-none">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2 w-100">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='d-flex ms-2 ps-1 w-100' role='button' onClick={toggleChatParticipantOffCanvas}>
                        <img src={singleChatDetail?.sender_image} width={'37px'} height={'37px'} className='me-1 rounded-2' />
                        <div className='lh-sm ms-2'>
                            <div className='ff-balooPaaji2-bold fs-20'>{singleChatDetail?.sender_name}</div>
                            <div className='ff-poppins-regular fs-10'>
                                {
                                    singleChatDetail && singleChatDetail != null
                                        ?
                                        <>
                                            {
                                                singleChatDetail?.sender_activity_status == '1'
                                                    ? 'Online' : 'Offline'
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='messengerList scroller-style-y'>

                {
                    singleChatDetail && singleChatDetail != null
                        ?
                        <>
                            {
                                singleChatDetail?.chat_count > 0
                                    ?
                                    <div ref={containerRef} className='p-2 messengerListContainer' style={{ paddingBottom: "3rem !important" }}>
                                        {
                                            Object.entries(singleChatHistoryDetail).map(([date, messages]) => {
                                                return (

                                                    <div key={date}>
                                                        {new Date().setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0) && (
                                                            <div className='d-flex justify-content-center'>
                                                                <div className='chatDayBadge'>Today</div>
                                                            </div>
                                                        )}
                                                        {new Date().setHours(0, 0, 0, 0) !== new Date(date).setHours(0, 0, 0, 0) && (
                                                            <div className='d-flex justify-content-center'>
                                                                <div className='chatDayBadge'>{formatDate(date)}</div>
                                                            </div>
                                                        )}

                                                        {messages.map((message, index) => {
                                                            let imageshow = opponentProfileShowStatus(messages, index, message)
                                                            if (message.msg_type == "book") {
                                                                return (
                                                                    <div className={`d-flex ${message.msg_align == 'right' ? 'justify-content-end' : 'justify-content-start'}`}
                                                                        role='button' onClick={() => navigate(`/chat/booking/${message?.message?.booking_id}`)}>

                                                                        <div className='msgTypeBook'>
                                                                            <div className='d-flex userDetails'>
                                                                                <img src={message?.message?.user_img} width={'60px'} height={'60px'} className='userImg me-2' />
                                                                                <div className='lh-sm text-white'>
                                                                                    <div className='ff-poppins-medium fs-13'>{message?.message?.book_user_name}</div>
                                                                                    <div className='ff-balooPaaji2-regular fs-12'>{message?.message?.book_user_id}</div>
                                                                                    <div className='ff-poppins-medium fs-12'>{message?.message?.book_date_time}</div>
                                                                                    <div className='ff-balooPaaji2-regular fs-12'>{message?.message?.service_gender} - {message?.message?.book_service_id}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between msgFooter'>
                                                                                <div className='ff-poppins-regular fs-12 discussBooking'>{`Discuss booking>>>`}</div>
                                                                                <div className='ff-poppins-regular fs-12 text-secondary'>{message?.msg_time}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            if (message.msg_type == "order") {
                                                                return (
                                                                    <div className={`d-flex ${message.msg_align == 'right' ? 'justify-content-end' : 'justify-content-start'}`}
                                                                        role='button' onClick={() => navigate(`/chat/booking/${message?.message?.order_id}`, { state: { chatFlow: 'order' } })}>
                                                                        <div className='msgTypeBook'>
                                                                            <div className='d-flex userDetails'>
                                                                                <img src={message?.message?.user_detail?.user_img} width={'60px'} height={'60px'} className='userImg me-2' />
                                                                                <div className='lh-sm text-white'>
                                                                                    <div className='ff-poppins-medium fs-13'>{message?.message?.user_detail?.user_name}</div>
                                                                                    <div className='ff-balooPaaji2-regular fs-12'>{message?.message?.user_detail?.user_id}</div>
                                                                                    <div className='ff-poppins-medium fs-12'>{message?.message?.order_date_string}</div>
                                                                                    <div className='ff-balooPaaji2-regular fs-12'>{message?.message?.order_products[0]?.product_name}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between msgFooter'>
                                                                                <div className='ff-poppins-regular fs-12 discussBooking'>{`Discuss booking>>>`}</div>
                                                                                <div className='ff-poppins-regular fs-12 text-secondary'>{message?.msg_time}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            else if (message.msg_type == "chat") {
                                                                return (
                                                                    <div className={`d-flex ${message.msg_align == 'right' ? 'justify-content-end' : 'justify-content-start'}`}>

                                                                        {
                                                                            message.msg_align == 'right'
                                                                                ?
                                                                                <div className={`msgTypeChat ${message.msg_align == 'right' ? 'rightAlign' : 'leftAlign'}`}>
                                                                                    <div className='ff-poppins-regular fs-13' style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: message?.message.replace(/\n/g, '<br>') }} >
                                                                                    </div>
                                                                                    <div className='d-flex justify-content-end'>
                                                                                        <div className='ff-poppins-regular fs-10 me-1'>{message?.msg_time}</div>
                                                                                        {
                                                                                            message?.read_status == '1'
                                                                                                ?
                                                                                                <img src={message_read} width={'15px'} height={'15px'} />
                                                                                                :
                                                                                                <img src={message_unread} width={'15px'} height={'15px'} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className='d-flex'>
                                                                                    {imageshow ? <img src={message.msg_writer_image} width={'40px'} height={'40px'} className='rounded-circle me-1' /> : <div style={{ height: 40, width: 40 }}></div>}
                                                                                    <div className={`msgTypeChat ${message.msg_align == 'right' ? 'rightAlign' : 'leftAlign'}`}>
                                                                                        <div className='ff-poppins-regular fs-13'>{message?.message}</div>
                                                                                        <div className='d-flex justify-content-end'>
                                                                                            <div className='ff-poppins-regular fs-10 me-1'>{message?.msg_time}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        }


                                                                    </div>
                                                                )
                                                            }

                                                            else if (message.msg_type == "service_enquiry") {
                                                                return (
                                                                    <div className={`d-flex ${message.msg_align == 'right' ? 'justify-content-end' : 'justify-content-start'}`}
                                                                        role='button'>
                                                                        <div className='msgTypeServiceEnquiry'>
                                                                            <div className='serviceDetails d-flex justify-content-between lh-lg'>
                                                                                <div className='w-100'>
                                                                                    <div className='ff-poppins-semiBold fs-13 w-100' onClick={() => navigate(`/chat/enquery/${message?.message?.enquery_id}`)}>{message?.message?.service_detail?.service_category_name}</div>
                                                                                    <div className='ff-poppins-semiBold fs-12 w-100' onClick={() => navigate(`/chat/enquery/${message?.message?.enquery_id}`)}>Rs.{message?.message?.service_detail?.service_price}</div>
                                                                                    <div className='ff-poppins-regular fs-12 w-100' onClick={() => navigate(`/chat/enquery/${message?.message?.enquery_id}`)}>{message?.message?.service_detail?.service_name}</div>
                                                                                    <div class="accordion accordion-flush mt-1" id={"accordionFlushExample" + message?.message?.enquery_id}>
                                                                                        <div class="accordion-item  bg-transparent">
                                                                                            <h2 class="accordion-header bg-transparent" id={"flush-headingOne" + message?.message?.enquery_id}>
                                                                                                <button class="accordion-button p-0  bg-transparent collapsed ff-poppins-regular fs-12 primary-text" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + message?.message?.enquery_id} aria-expanded="false" aria-controls={"flush-collapseOne" + message?.message?.enquery_id}>
                                                                                                    More Details
                                                                                                </button>
                                                                                            </h2>
                                                                                            <div id={"flush-collapseOne" + message?.message?.enquery_id} class="accordion-collapse collapse" aria-labelledby={"flush-headingOne" + message?.message?.enquery_id} data-bs-parent={"#accordionFlushExample" + message?.message?.enquery_id}>
                                                                                                <div className='ff-poppins-regular fs-12'>
                                                                                                    {message?.message?.service_detail?.description}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <img src={message?.message?.service_detail?.image} width={'92px'} height={'92px'} className='rounded-2' onClick={() => navigate(`/chat/enquery/${message?.message?.enquery_id}`)} />
                                                                            </div>
                                                                            <div className='ff-poppins-regular fs-13 text-white mt-1' onClick={() => navigate(`/chat/enquery/${message?.message?.enquery_id}`)}>{message?.message?.enquery_question}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            else if (message.msg_type == "attachment") {
                                                                let widthRatio = Number(message?.message?.width_ratio);
                                                                let heightRatio = Number(message?.message?.height_ratio);
                                                                let size = 0;
                                                                let imageHeight = 0;
                                                                let imageWidth = 0;


                                                                if (widthRatio > heightRatio) {
                                                                    imageWidth = 250;
                                                                    size = 250 / widthRatio;
                                                                    imageHeight = heightRatio * size;
                                                                } else {
                                                                    imageHeight = 250;
                                                                    size = 250 / heightRatio;
                                                                    imageWidth = widthRatio * size;
                                                                    if (imageWidth < 100) {
                                                                        imageWidth = 100;
                                                                        size = 100 / widthRatio;
                                                                        imageHeight = heightRatio * size;
                                                                    }
                                                                }


                                                                return (
                                                                    <div className={`d-flex ${message.msg_align == 'right' ? 'justify-content-end' : 'justify-content-start'}`}>
                                                                        <div className='msgTypeAttachment'>
                                                                            <div>
                                                                                <img src={message?.message?.file_url} width={imageWidth} height={imageHeight} />
                                                                            </div>
                                                                            <div className='d-flex justify-content-end p-1'>
                                                                                <div className='ff-poppins-regular fs-10 me-1 text-white'>{message?.msg_time}</div>
                                                                                {
                                                                                    message?.read_status == '1'
                                                                                        ?
                                                                                        <img src={message_read} width={'15px'} height={'15px'} />
                                                                                        :
                                                                                        <img src={message_unread} width={'15px'} height={'15px'} />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <div className='ff-poppins-regular fs-12 text-center mt-5 pt-5'>
                                        No message found
                                    </div>
                            }
                        </>
                        :
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div class="spinner-border primary-text" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                }
            </div>

            {
                singleChatDetail && singleChatDetail != null &&
                <div class="messageInputSection input-group">
                    {
                        !isMessageSending ?
                            <>
                                <span class="input-group-text attachment">
                                    <input className='d-none' type='file' accept="image/*" onChange={handleFileRead} ref={fileRef} />
                                    <img src={messenger_attachment} width={'24px'} height={'24px'} alt='Attachment' role='button' onClick={() => { fileRef.current?.click() }} />
                                </span>
                                <input type="text" class="form-control MessageTextBox ff-poppins-regular fs-18 border-0" placeholder='Write your message...' value={newText} onChange={ev => setNewText(ev.target.value)} />
                                {newText == '' ? <FaCamera className='primary-text fs-24  mt-2' onClick={() => openwebCamModal()} /> : ""}
                                <span class="input-group-text  border-0" style={{ borderBottom: 'none' }} role='button' onClick={() => {
                                    if (newText === '') { }
                                    else { sendMessage() }
                                }}>
                                    {newText === '' ?
                                        <IoSendSharp className='secondary-text-1 fs-24 border-0' /> :
                                        <IoSendSharp className='primary-text fs-24 border-0' />}
                                </span>
                            </>
                            :
                            <div className='d-flex align-items-center ps-3 bg-white' style={{ width: '343px' }}>
                                <div class="spinner-border primary-text spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div className='ff-balooPaaji2-medium fs-15 ps-3'>Sending...</div>
                            </div>
                    }
                </div>
            }

            <ImageCropperModal
                isOpen={isImageCropperModaOpen}
                inputImage={imageToCrop}
                onSelect={onImageCropped}
                onCancel={() => {
                    setIsImageCropperModaOpen(false);
                    clearFileInput();
                }}
                enableRatio={true}
                isBlolbOutput={true}
                videoConstraints={{ facingMode }} />

            <ChatParticipantOffCanvas
                isOpen={isChatParticipantOffCanvasOpen}
                toggleOffCanvas={toggleChatParticipantOffCanvas}
                particpants={chatParticipants}
            />


            <div class="modal fade" ref={showcamRef} data-bs-keyboard="false" data-bs-backdrop="false" style={{ backgroundColor: "#fff" }}
                tabindex="-1" aria-hidden="true" aria-labelledby="exampleModalLabel">
                <div class="modal-dialog " style={{ width: 390 }}>
                    <div class="modal-content">
                        {/* <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
                        <div class="modal-body">
                            {showWebcam && (

                                imageSrc ? (
                                    <div>
                                        <img src={imageSrc} alt="Captured" style={{ height: '80vh', width: '100%', backgroundColor: '#e8e9ff' }} />
                                        <div className='d-flex justify-content-between mt-1 primary-text'>
                                            <span onClick={handelnavigate} className='primary-text ff-poppins-bold mt-2'>Cancel</span>
                                            <RiArrowGoBackFill className='fs-30 mt-2' onClick={retake} />
                                            <span className='primary-text ff-poppins-bold mt-2' onClick={handleOK}>OK</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            style={{ height: '80vh', width: '100%', backgroundColor: '#e8e9ff' }}
                                            videoConstraints={{ facingMode }}
                                        />
                                        {/* <button onClick={capture}>Capture</button> */}
                                        <div className='d-flex justify-content-between mt-1 primary-text'>
                                            <span onClick={handelnavigate} className='primary-text ff-poppins-bold mt-2'>Cancel</span>
                                            <TbCapture onClick={capture} className='fs-48' data-bs-dismiss="modal" />
                                            <MdCameraswitch onClick={switchCamera} className='fs-34 mt-2' />
                                        </div>
                                    </div>
                                )
                            )}
                            <div  >
                                {/* <RiArrowGoBackFill  className='fs-30 mt-2' /> */}



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleChat