import React, { useEffect, useState } from 'react';
import '../../Assets/Styles/offCanvas.css';
import { serviceTimeSlotDetailsNew } from '../../Services/api';
import ReviewAndConfirmOffCanvas from './reviewAndConfirm';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './customDatePickerStyles.css';
import { toast } from 'react-toastify';

const DatePickerOffCanvas = ({ isOpen, toggleOffCanvas }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDay, setselectedDay] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedTimeSection, setSelectedTimeSection] = useState('');
    const [selectedTimeSolt, setSelectedTimeSlot] = useState('');
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [selectedCustomDate, setSelectedCustomDate] = useState(null);
    const [tempSelectedDate, setTempSelectedDate] = useState(null);
    const [selectedOption, setSelectedOption] = useState('This week');
    const [timeSlotValue, setTimeSlotValue] = useState();
    const [isReviewAndConfirmOffCanvasOpen, setReviewAndConfirmOffCanvasOpen] = useState(false); // Date picker visibility state

    const handleThisWeekClick = () => {
        setSelectedOption('This week');
        setShowCustomDatePicker(false);
    };

    useEffect(() => {
        if (isOpen) {
            getServiceTimeSlotForThisWeek(null);
        }
        // if (selectedCustomDate) {
        //     getServiceTimeSlotForCustom(selectedCustomDate); // Pass selectedCustomDate here
        // }
    }, [isOpen]);

    useEffect(() => {
        setReviewAndConfirmOffCanvasOpen(selectedTime != '' ? true : false);
    }, [selectedTime]);

    function getServiceTimeSlotForThisWeek(date) {

        let localServiceDetail = localStorage.getItem('serviceDetail')
            ? JSON.parse(localStorage.getItem('serviceDetail'))
            : '';

        let param = {
            "cart_app_id": (localServiceDetail && localServiceDetail?.cart_app_id) ? localServiceDetail?.cart_app_id : '',
            "booking_type": (localServiceDetail && localServiceDetail?.reschedule && localServiceDetail?.reschedule == true) ? '2' : "1",
            "date": date ? new Date(date) : new Date(),
            "service_type": (localServiceDetail && localServiceDetail?.serviceType) ? localServiceDetail?.serviceType : '',
        };
        serviceTimeSlotDetailsNew(param).then((res) => {
          
            if (res.data?.status == '1') {
                // date
                let date = new Date(res.data?.select_date);
                let day = date.toLocaleDateString("en-US", { day: 'numeric' })
                let month = date.toLocaleDateString("en-US", { month: 'short' })
                let year = date.toLocaleDateString("en-US", { year: 'numeric' })
                let dateFormat = day + ' ' + month + ' ' + year;
                setSelectedDate(dateFormat);
                setTimeSlotValue(res.data);
            }
        })
    }

    function getServiceTimeSlotForCustom(date) {
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';

        let params = {
            "cart_app_id": (localServiceDetail && localServiceDetail.cart_app_id) ? localServiceDetail.cart_app_id : '',
            "booking_type": (localServiceDetail && localServiceDetail?.reschedule && localServiceDetail?.reschedule == true) ? '2' : "1",
            "date": date,
            "service_type": (localServiceDetail && localServiceDetail.serviceType) ? localServiceDetail.serviceType : '',
        };
        serviceTimeSlotDetailsNew(params).then((res) => {
            if (res.data?.status === '1') {
                let date = new Date(res.data?.select_date);
                let day = date.toLocaleDateString("en-US", { day: 'numeric' });
                let month = date.toLocaleDateString("en-US", { month: 'short' });
                let year = date.toLocaleDateString("en-US", { year: 'numeric' });
                let dateFormat = day + ' ' + month + ' ' + year;
                setSelectedDate(dateFormat);
                setTimeSlotValue(res.data);
            }
        });
    }


    function selectDayAndTime(day, time, section, slot) {
        setselectedDay(day);
        setSelectedTime(time);
        setSelectedTimeSection(section);
        setSelectedTimeSlot(slot);
    }

    const handleCustomButtonClick = () => {
        setTempSelectedDate(selectedCustomDate); // Set temporary selected date
        setShowCustomDatePicker(true);
        setSelectedOption('Custom');
    };

    const handleCancelClick = () => {
        setShowCustomDatePicker(false);
        // Reset temporary selected date
        setTempSelectedDate(selectedCustomDate);
    };

    const handleOkClick = () => {
        setSelectedCustomDate(tempSelectedDate); // Update selectedCustomDate
        setShowCustomDatePicker(false);
        getServiceTimeSlotForCustom(tempSelectedDate); // Pass tempSelectedDate to the function
    };


    const customDayClass = (date) => {
        const isTodayOrFuture = date >= new Date().setHours(0, 0, 0, 0); // Compare dates without considering time
        const isSelected = date.getTime() === tempSelectedDate?.getTime();
        return isSelected ? 'temp-selected-day' : (isTodayOrFuture ? 'future-day' : 'past-day');
    };

    const handleDayHover = (date) => {
        const isTodayOrFuture = date >= new Date().setHours(0, 0, 0, 0);
        const isPast = date < new Date().setHours(0, 0, 0, 0);
        if (!isTodayOrFuture && !isPast) {
            date.classList.add('hover-day');
        }
    }


    const resetTimeslot = () => {
        setSelectedTimeSection('')
        setSelectedTimeSlot('')
        setSelectedTime('')
    }


    return (
        <>
            <div className={`offcanvas offcanvas-bottom datePickerOffCanvas  ${isOpen ? 'show' : ''}`} style={{ position: 'sticky', zIndex: 9998 }} tabindex="-1" aria-labelledby="selectCustomerModalLabel">
                <div className="offcanvas-body small">
                    <div className='d-flex justify-content-center'>
                        <div className='custom-pill'></div>
                    </div>

                    <div>
                        <div className='d-flex justify-content-between header'>
                            <div className='ff-poppins-regular fs-12'>Pick your preferred date & time</div>
                            <div className='ff-poppins-medium fs-12' role='button' onClick={() => { setReviewAndConfirmOffCanvasOpen(false); toggleOffCanvas() }}>Cancel</div>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <div className="custom-timeslot">
                                <div className="btn-group btnGroup-type-1" role="group" aria-label="Basic radio toggle button group" >
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                    <label className={`btn ff-poppins-medium fs-12 me-2 ${selectedOption === 'This week' ? 'active' : ''}`} htmlFor="btnradio1" onClick={handleThisWeekClick}>This week</label>

                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                    <label className={`btn ff-poppins-medium fs-12 ${selectedOption === 'Custom' ? 'active' : ''}`} htmlFor="btnradio2" onClick={handleCustomButtonClick} style={{ cursor: 'pointer' }}>Custom</label>
                                </div>

                                {showCustomDatePicker && (
                                    <div className="custom-datepicker-container ">
                                        <DatePicker
                                            selected={tempSelectedDate}
                                            onChange={(date) => {
                                                setTempSelectedDate(date); // Update tempSelectedDate
                                            }}
                                            inline
                                            minDate={new Date()}
                                            dayClassName={customDayClass}
                                            onMouseOver={handleDayHover}
                                            dateFormat="yyyy-MM-dd"
                                            showTimeSelect={false}
                                            utcOffset={new Date().getTimezoneOffset()} // Set timezone offset
                                        />

                                        <div className='calenderButton'>
                                            <span onClick={handleCancelClick} style={{ cursor: 'pointer', marginRight: '20px' }}>Cancel</span>
                                            <span onClick={handleOkClick} style={{ cursor: 'pointer' }}>OK</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='text-end'>
                                <div className='ff-poppins-semiBold fs-12 primary-text'>{selectedDate}</div>
                                <div className='ff-poppins-regular fs-12 text-secondary'>Pick an available time slot</div>
                            </div>
                        </div>

                        {selectedOption === 'This week' && (
                            <div className='weekDays d-flex'>
                                {timeSlotValue?.date_details?.map(item => (
                                    <div className='item'>
                                        <div className='ff-poppins-medium fs-12 mb-3'>{item.day_first}</div>
                                        <div
                                            className={
                                                (item.date == timeSlotValue.select_date ? 'selectedDate ' : ' ') +
                                                (item.date_status == 2 ? 'disabled pe-none ' : ' ') +
                                                'date rounded-circle ff-poppins-medium fs-12 d-flex justify-content-center align-items-center'
                                            }
                                            role='button' onClick={() => getServiceTimeSlotForThisWeek(item.date)}
                                        >
                                            {item.day}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className='timeOfDay  scroller-style-y'>

                            {
                                timeSlotValue?.morning_section_status == 1 &&
                                <>
                                    <div className='item d-flex align-items-center mb-3'>
                                        <div className='ff-poppins-medium fs-12 primary-text me-2'>Morning</div>
                                        <hr className='w-100' />
                                    </div>
                                    <div className='timeList d-flex flex-wrap'>

                                        {
                                            timeSlotValue?.morning_section?.map(item => (
                                                <div
                                                    className={
                                                        (item.mor_time == selectedTime ? 'selected ' : '') +
                                                        (item.mor_active_status == 2 ? 'disabled pe-none ' : '') +
                                                        'time d-flex justify-content-center align-items-center'
                                                    }
                                                    role='button' onClick={() => {
                                                        selectDayAndTime(timeSlotValue?.date_details[0].day_date, item.mor_time, item.section_value, item.mor_value);
                                                    }}
                                                >
                                                    <div className='ff-poppins-medium fs-12'>{item.mor_time}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }

                            {
                                timeSlotValue?.afternoon_section_status == 1 &&
                                <>
                                    <div className='item d-flex align-items-center mb-3'>
                                        <div className='ff-poppins-medium fs-12 primary-text me-2'>Afternoon</div>
                                        <hr className='w-100' />
                                    </div>
                                    <div className='timeList d-flex flex-wrap'>
                                        {
                                            timeSlotValue?.afternoon_section.map(item => (
                                                <div
                                                    className={
                                                        (item.after_time == selectedTime ? 'selected ' : '') +
                                                        (item.after_active_status == 2 ? 'disabled pe-none ' : '') +
                                                        'time d-flex justify-content-center align-items-center'
                                                    }
                                                    role='button' onClick={() => {
                                                        selectDayAndTime(timeSlotValue?.date_details[0].day_date, item.after_time, item.section_value, item.after_value);
                                                    }}
                                                >
                                                    <div className='ff-poppins-medium fs-12'>{item.after_time}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }

                            {
                                timeSlotValue?.evening_section_status == 1 &&
                                <>
                                    <div className='item d-flex align-items-center mb-3'>
                                        <div className='ff-poppins-medium fs-12 primary-text me-2'>Evening</div>
                                        <hr className='w-100' />
                                    </div>
                                    <div className='timeList d-flex flex-wrap'>
                                        {
                                            timeSlotValue?.evening_section.map(item => (
                                                <div
                                                    className={
                                                        (item.evening_time == selectedTime ? 'selected ' : '') +
                                                        (item.evening_active_status == 2 ? 'disabled pe-none ' : '') +
                                                        'time d-flex justify-content-center align-items-center'
                                                    }
                                                    role='button' onClick={() => {
                                                        selectDayAndTime(timeSlotValue?.date_details[0].day_date, item.evening_time, item.section_value, item.evening_value);
                                                    }}
                                                >
                                                    <div className='ff-poppins-medium fs-12'>{item.evening_time}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            {
                                timeSlotValue?.night_section_status == 1 &&
                                <>
                                    <div className='item d-flex align-items-center mb-3'>
                                        <div className='ff-poppins-medium fs-12 primary-text me-2'>Night</div>
                                        <hr className='w-100' />
                                    </div>
                                    <div className='timeList d-flex flex-wrap'>
                                        {
                                            timeSlotValue?.night_section.map(item => (
                                                <div
                                                    className={
                                                        (item.night_time == selectedTime ? 'selected ' : '') +
                                                        (item.night_active_status == 2 ? 'disabled pe-none ' : '') +
                                                        'time d-flex justify-content-center align-items-center'
                                                    }
                                                    role='button' onClick={() => {
                                                        selectDayAndTime(timeSlotValue?.date_details[0].day_date, item.night_time, item.section_value, item.night_value);
                                                    }}
                                                >
                                                    <div className='ff-poppins-medium fs-12'>{item.night_time}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            <div style={{ height: 135 }}></div>
                        </div>
                    </div>

                    {/* Date Picker */}

                </div>
            </div>
            <ReviewAndConfirmOffCanvas
                isOpen={isReviewAndConfirmOffCanvasOpen}
                date={selectedDate}
                day={selectedDay}
                time={selectedTime}
                section={selectedTimeSection}
                slot={selectedTimeSolt}
                resetTimeslot={resetTimeslot}
            // toggleOffCanvas={toggleReviewAndConfirmOffCanvas}
            />
        </>
    );
};

export default DatePickerOffCanvas;