import { useEffect, useRef, useState } from "react";
import React from "react";
import p_nav_previous from "../../../Assets/Images/p_nav_previous.svg";
import ion_cart_outline from "../../../Assets/Images/ion_cart-outline.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../Services/axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { FaRupeeSign } from "react-icons/fa";
import defaultimg from "../../../Assets/Images/default-placeholder.png";
import { toast } from "react-toastify";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
// Menu
import vegIcon from "../../../Assets/Images/vegpngoutput.png"
import eggIcon from "../../../Assets/Images/eggpngoutput.png"
import nonvegIcon from "../../../Assets/Images/nomvegoutput.png"

// Addon 
import { IoMdClose } from "react-icons/io";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

const SingleProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productId, merchantId, flowtype } = location.state || {};
  const [showAddToBasketBtn, setShowAddToBasketBtn] = useState(true);
  const [product, setProduct] = useState('');
  const [cartId, setCartId] = useState("");
  const [shipingtype, setShipingtype] = useState("");
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();
  const [cartCount, setCartCount] = useState()
  let { vendorId, productid } = useParams();
  const [selectItem, setSelectItem] = useState('')



  const addonModalRef = useRef(null);
  const addonEditeModalRef = useRef(null);

  const [product_Id, setProduct_Id] = useState('')
  const [productCost, setProductCost] = useState('')
  const [addonsCost, setAddonsCost] = useState('')
  const [selectedAddons, setSelectedAddons] = useState([])
  const [productCount, setProductCount] = useState(0)
  const [addonModel, setAddonModel] = useState(false);


  const flowtypeValue = flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : flowtype;

  useEffect(() => {
    getproductdetails();
  }, [cartId]);

  useEffect(() => {
    let productCart = localStorage.getItem("productCart")
      ? JSON.parse(localStorage.getItem("productCart"))
      : "";
    setCartId(productCart?.cartId ? productCart?.cartId : "");
    setShipingtype(
      productCart?.shipping_type ? productCart?.shipping_type : ""
    );

    let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
    setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "");
    setcardType(appliedCard?.cardType ? appliedCard?.cardType : "");
    setcardId(appliedCard?.cardId ? appliedCard?.cardId : "");
  }, []);


  const getproductdetails = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        product_id: productid,
        cart_id: cartId
      };
 
      axiosInstance
        .post("/user/products/single_product_detail_without_login", reqData)
        .then((res) => {
 
          setProduct(res?.data?.result); 
          setSelectItem(res?.data?.result)
          setCartCount(res?.data?.cart_count)
          setProduct_Id(res?.data?.product_id)
        })
        .catch((err) => {
          if (err.response.status == 400) {
            alert(`Error:${err.response.state}-${err.response.message}`);
          }
          if (err.response.status == 500) {
            alert(`Error:${err.response.status}-${err.response.message}`);
          }
        });
    } catch (error) {
      alert("Tryagin later");
    }
  };
  // console.log("product price ", product.price);

  const Add_Remove_productcount = async (productId, Operation) => {

    try {
      const Addition = [];

      if (selectedAddons && selectedAddons.length > 0) {
        selectedAddons?.forEach((addon) => {
          Addition.push(...addon.options);
        });
      }
      let reqData = {
        vendor_id: vendorId,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: 1,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: Addition
      };
  

      axiosInstance.post("user/products/add_or_remove_product_cart", reqData)
        .then((res) => {
          if (res.status == 200) {
            getproductdetails()
            const localcartid = res.data.cart_id;
       
            let productCart = {
              shipping_type: shipingtype,
              cartId: res.data.cart_id,
              vendorId: vendorId,
            };

            localStorage.setItem("productCart", JSON.stringify(productCart));
            localStorage.setItem("cartid", JSON.stringify(res.data.cart_id));
            setCartId(res.data.cart_id);
            // getproductlist();
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            alert(
              `Error: ${err.response.status} - ${err.response.data.message}`
            );
          }
          if (err.response.status == 500) {
            alert(`Error:${err.response.status}-${err.response.message}`);
          }
        });
    } catch (error) {
      alert("try againe later");
    }
  };
  const openAddonModal = () => {
    addonModalRef.current.classList.add("show");
    addonModalRef.current.style.display = "block";
    setProductCost(product?.price)
  };

  const closeAddonModal = () => {
    addonModalRef.current.classList.remove("show");
    addonModalRef.current.style.display = "none";
  };
  const handelEdittoAddonmodel = () => {
    closeEditAddonModal()
    openAddonModal()
  }
  const transformDataAndCalculateTotalCost = (rawData, selectedOptions) => {
    let transformedData = [];
    let totalCost = 0;

    if (rawData && rawData.length > 0) {
      rawData.forEach((addon) => {
        let addonObj = {
          addonId: addon.id,
          options: []
        };

        addon.options.forEach((option) => {
          if (selectedOptions.includes(option.id)) {
            addonObj.options.push(option.id);
            totalCost += parseInt(option.price, 10);
          }
        });

        if (addonObj.options.length > 0) {
          transformedData.push(addonObj);
        }
      });
    }

    return { addons: transformedData, cost: totalCost };
  };
  const selectAddon = (addon, option, price) => {
    let addonId = addon.id;
    let selectedAddonsList = [...selectedAddons];
    let found = false;

    selectedAddonsList?.forEach(item => {
      if (item.addonId === addonId) {
        found = true;
        if (!item.options.includes(option)) {
          if ((addon.selection_type == 1 && item.options.length > 0) || (addon.selection_type == 2 && item.options.length >= addon.max_select)) {

            toast("You have already selected max addons for this category", {
              position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1'
            });
          } else {
            item.options.push(option);
            setProductCost(Number(productCost) + Number(price));
            setAddonsCost(Number(addonsCost) + Number(price));
            // setProductPrice(productCost)
          }
        } else {
          item.options = item.options.filter((opt) => opt !== option);

          setProductCost(Number(productCost) - Number(price));
          setAddonsCost(Number(addonsCost) - Number(price));

        }
      }
    });

    if (!found) {
      selectedAddonsList.push({ "addonId": addonId, "options": [option] });
      setProductCost(Number(productCost) + Number(price));
      setAddonsCost(Number(addonsCost) + Number(price));
    };
    setSelectedAddons(selectedAddonsList);
  }

  const checkIsSelectedAddon = (addonId, option) => {
    let selectedAddonsList = [...selectedAddons];
    let found = false;
    selectedAddonsList.forEach(item => {
      if (item.addonId === addonId) {
        if (item.options.includes(option)) {
          found = true;
        }
      }
    });

    return found;
  }
 

  const checkButtonStatus = () => {
 
      for (const addon of product?.addons) {
        const selectedAddon = selectedAddons?.find((sel) => sel?.addonId == addon.id);
 

        const selectedOptionsCount = selectedAddon ? selectedAddon?.options?.length : 0;

        if (addon?.required == 1 && addon?.selection_type == 2 && selectedOptionsCount < addon?.min_select) {
          toast(`You need to select at least ${addon?.min_select} option(s) from the category ${addon?.addon_name}`, {
            position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1 toast-custom-style' // Add your custom class here
          });
          return;
        } else if (addon?.required == 1 && addon?.selection_type == 1 && selectedOptionsCount <= 0) {

          toast(`You need to select at least 1 option(s) from the category ${addon.addon_name}`,
            { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1 toast-custom-style' });

          return;
        }
      }
    
    closeAddonModal()
    Add_Remove_productcount(productid, "add", "1");
  }

  const openEditAddonModal = (addons, addedAddons, productid, added_count) => {
    let transformedData = transformDataAndCalculateTotalCost(addons, addedAddons);
    setSelectedAddons(transformedData.addons);
    setProduct_Id(productid);
    setProductCount(added_count)
    addonEditeModalRef.current.classList.add("show");
    addonEditeModalRef.current.style.display = "block";
    setAddonModel(true)
  };
  const closeEditAddonModal = () => {
    addonEditeModalRef.current.classList.remove("show");
    addonEditeModalRef.current.style.display = "none";
    // setModelOpen(true)
  };
  const update_Product = async (productId, Operation, count) => {
    try {
      // if (addonstate != "0") {
      let addons = [];
      selectedAddons?.map((addon) => {
        addons = [...addons, ...addon.options];
        // setReqAddon(addons)
      });
      // }
      let reqData = {
        vendor_id: merchantId.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: count,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: addons
      };

      axiosInstance.post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            getproductdetails();
            setSelectedAddons([]);
            closeEditAddonModal()
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast(`You can't add more than ${productCount} of this product`, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };
  const confirmUpdate = () => {
    let ProductItem = product?.result
    var ProductHave = ProductItem?.some((obj) => obj.product_id == productid);
    ProductItem?.map((Cartdata, id) => {

      if (Cartdata.product_id == productid) {
        if (Cartdata.quantity > productCount) {
          update_Product(productid, 'remove', Cartdata.added_count - productCount);
          setAddonModel(false)
        } else if (productCount > Cartdata.added_count) {
          update_Product(productid, 'add', productCount - Cartdata.added_count);
          setAddonModel(false)
        } else {
          setAddonModel(false)
          closeEditAddonModal()
        }
      }
    })

  }
  // result 
  return (
    <>
      <div className="scroller-style-y">
        <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
          <div className="d-flex justify-content-between align-items-center ms-2 me-2">
            <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => navigate(-1)} />
            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Products</div>
          </div>

          <div className="batchContainer2" style={{ cursor: "pointer" }} onClick={() => navigate("/productBasket", { state: { merchantId: { vendor_id: vendorId }, cartId } })} >
            <img src={ion_cart_outline} />

            {cartCount !== 0 ?
              < div className="basketCountSP primary-bg text-white">
                {cartCount}
              </div> :
              <></>
            }
          </div>

          {product.added_count == 0 ? (
            <div className="addToBasketBtnDiv">
              <button className="my-2 py-3 addToBasketBtn primary-bg text-white ff-poppins-semiBold rounded-2 border-0 py-2"
                onClick={() => product?.addons == "" ? Add_Remove_productcount(productid, "add") : openAddonModal()} >
                Add to Basket
              </button>
            </div>
          ) : (
            <>
              <div className="addToBasketBtnDiv">
                <button className="my-2 py-3 addToBasketBtn primary-bg text-white ff-poppins-semiBold rounded-2 border-0 py-2"
                  onClick={() => navigate("/productBasket", { state: { merchantId: { vendor_id: vendorId }, cartId, flowtype: flowtypeValue } })}>
                  Go to Basket
                </button>
              </div>
            </>
          )}
        </div>

        {product?.imges?.length !== 0 && (
          <div id="carouselExampleInterval" className="carousel slide " data-bs-ride="carousel" data-bs-interval="2000"  >
            <div className="carousel-inner">
              {product?.imges?.map((item, index) => (
                <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}   >
                  <img src={item.image == "" ? defaultimg : item.image_url} className="d-block w-100 curve" alt={`slide-${index}`} />
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev"  ></button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next"  ></button>
          </div>
        )}

        {/* Edit addon model  */}

        {/* <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390, zIndex: 7777, maxHeight: "auto", overflowY: "auto", }} ref={addonEditeModalRef} >
          <div className=" d-flex justify-content-end mt-2" onClick={() => closeEditAddonModal()}>
            <span className="closeIcon" ><IoMdClose /> </span>
          </div>
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <p className="m-0 ff-poppins-semiBold fs-14">Your Chosen</p>
              <p className="m-0 ff-poppins-regular fs-14 primary-text" onClick={() => handelEdittoAddonmodel()}>Edit</p>
            </div>

            {product?.result?.length > 0 &&
              <div>
                {product?.result?.map((item) => {
                  return productid === item.product_id ?
                    item?.addons.map((addon) =>
                      addon?.options?.map((option, optionIndex) => {
                        return item?.added_addons?.includes(option?.id) && (
                          <div className="d-flex justify-content-between" key={optionIndex}>
                            <div className="d-flex mt-1">
                              <img src={option.type === "1" ? vegIcon : option.type === "2" ? nonvegIcon : eggIcon} height="13px" width="13px" className="me-1 mt-1" />
                              <div className="ff-poppins-regular fs-13 ms-1">
                                {option?.name}
                              </div>
                            </div>
                            <div className="d-flex me-4">
                              <div className="ff-poppins-semiBold fs-12 mt-1 me-1">
                                + {option?.price}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : null;
                }

                )}
              </div>
            }
            <div className="mt-3" style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
              <div className=" ">
                {product?.result?.length > 0 &&
                  product?.result?.map((item) => (
                    <div className="ff-poppins-regular ">
                      {productid == item.product_id && item.added_count > 0 && (

                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="  d-flex fs-14 col-4  ">
                              <div className="col-2">
                                <FiMinusCircle
                                  className="  primary-text fontSizeCountIcon"
                                  onClick={() => {
                                    setProductCount((prevCount) => prevCount - 1);
                                    // setProductCost(item.price);
                                  }}
                                />
                              </div>
                              <div className=" col-2 ms-1 d-flex justify-content-center">
                                {productCount}
                              </div>
                              <div className="col-2">
                                <LuPlusCircle
                                  className={`  primary-text fontSizeCountIcon ${productCount == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                  onClick={() => {
                                    if (productCount != item.max_allowed_quantity) {
                                      setProductCount((prevCount) => prevCount + 1);
                                      setProduct_Id(item.product_id)
                                    } else {
                                      toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });

                                    }
                                  }}
                                />
                              </div>
                            </div>

                            <div className="ff-poppins-semiBold fs-14 primary-text">
                              Cost : {productCost}
                            </div>
                          </div>
                          <div className="col-12">
                            <button type="button" className="btn-type-1   ff-poppins-semiBold mt-2 fs-14 w-100 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
                              onClick={() => confirmUpdate()} >
                              Confirm
                            </button>
                          </div>

                        </div>
                      )}
                    </div>
                  )
                  )}
              </div>
            </div>

          </div>
        </div> */}

        {/* Add addon model  */}
        <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, width: 390, zIndex: 7777, maxHeight: "60%" }} ref={addonModalRef}  >
          <div className=" d-flex justify-content-end mt-2" onClick={() => closeAddonModal()}>
            <span className=" closeIcon " ><IoMdClose /> </span>
          </div>
          <div className="">
            {
              product_Id == product?.product_id ?
                product?.addons.map((addon, addonIndex) => {
                  return (
                    <div className="offcanvas-body small">
                      <div>
                        <div className="mb-2">
                          <h6 className="m-0 ff-poppins-semiBold fs-14">Choose {addon.addon_name}</h6>
                          <div className="secondary-text fs-13">Select any (optional)</div>
                        </div>
                        {addon?.options?.map((option, optionIndex) => (
                          <div key={optionIndex} className="d-flex justify-content-between">
                            <div className="d-flex mt-1">
                              <img src={option.type === "1" ? vegIcon : option.type === "2" ? nonvegIcon : ""} height="13px" width="13px" className="me-1 mt-1"
                              />
                              <div className="ff-poppins-regular fs-13 ms-1">{option?.name}</div>
                            </div>
                            <div className="d-flex">
                              <div className="ff-poppins-semiBold fs-12 mt-1 me-1">+ {option?.price}</div>
                              {checkIsSelectedAddon(addon.id, option.id) ? (
                                <div onClick={() => {
                                  selectAddon(addon, option.id, option?.price);
                                  setProduct_Id(product?.product_id);
                                }}
                                  className="primary-text fs-15 ff-poppins-semiBold"  >
                                  <MdOutlineCheckBox />
                                </div>
                              ) : (
                                <div onClick={() => {
                                  selectAddon(addon, option.id, option?.price);
                                  setProduct_Id(product?.product_id);
                                }}
                                  className="fs-15 ff-poppins-semiBold"  >
                                  <MdOutlineCheckBoxOutlineBlank />
                                </div>
                              )
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>)
                }) : ""}
          </div>

          <div style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
            <div className=" p-1  ">
              <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular fs-14 mb-1">
                Cost: {productCost}
              </p>
              <button type="button" className="btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
                onClick={() => checkButtonStatus()}  >
                Confirm
              </button>
            </div>

          </div>
        </div>

        <div className="" style={{ marginBottom: 100 }}>
          <div className="ff-poppins-semiBold fs-16 ms-2 ps-1 ">
            {product.product_name}
          </div>
          <div className="ff-poppins-semiBold fs-14 ms-2 ps-1">
            <FaRupeeSign /> {product.price}
          </div>
          <div className="ff-poppins-semiBold primary-text fs-14 ms-2 ps-1">
            {product.unit_quantity}   {product.unit_type_name}
            <p className="primary-text m-0 ff-poppins-regular fs-12">{product.stock_quantity}Left </p>
          </div>

          <div>
            <p className="ff-poppins-regular secondary-text-1 fs-13 m-0 p-3">
              {product.description}
            </p>
          </div>
        </div>
      </div >
    </>
  );
};

export default SingleProduct;
