import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import "../Membership/membershipList.css";
import axiosInstance from "../../Services/axios";
import MembershipCard from "../../Components/MembershipCard/MembershipCard";
import "./membershipDetail.css";
const MembershipBuyNow = () => {
  const [vendorMembershipCards, setVendorMembershipCards] = useState([]);
  const [VendorMembershipImageStatus, setVendorMembershipImageStatus] = useState([]);
  const [VendorMembershipImage, setVendorMembershipImage] = useState([]);
  const [vendorDetails, setVendorDetails] = useState([]);
  const [profile, setProfile] = useState();
  const [membershipID, setMembershipID] = useState();
  const location = useLocation();
  const { vendor_Id } = location.state || {};
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  let { vendorId } = useParams();
  const token = localStorage.getItem("token");

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  // console.log('Vendor member ship cards', vendorMembershipCards);
  // console.log('card_pattern_status --- Check check', VendorMembershipImageStatus);
  // console.log('card_pattern_url --- Check 2222', VendorMembershipImage);


  useEffect(() => {
    membershipCardsList();
  }, []);

  const membershipCardsList = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
      };
      axiosInstance
        .post("/user/membership/vendor_membership_list_without_login", reqData)
        .then((res) => {
          console.log("vendor_membership_list_without_login",res.data);

          setVendorMembershipCards(res.data.vendor_membership_list);
          setVendorDetails(res.data.vendor_detail);
          setProfile(res.data);

          const membership_ID = res.data.vendor_membership_list.map(
            (membership) => membership.membership_id
          );

          const VendorMembershipImageStatus = res.data.vendor_membership_list.map(
            (VendorMembershipImageStatus) => VendorMembershipImageStatus.card_pattern_status
          );

          const VendorMembershipImage = res.data.vendor_membership_list.map(
            (VendorMembershipImage) => VendorMembershipImage.card_pattern_image_url
          );

          setVendorMembershipImageStatus(VendorMembershipImageStatus);
          setVendorMembershipImage(VendorMembershipImage);
          setMembershipID(membership_ID);
        });
    } catch (error) {
      console.log("error --->", error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white ">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Membership</div>
        </div>
      </div>
      <div className="messengerList-container">
        <div
          className="p-2 scroller-style-y"
          style={{
            overflowX: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            {
              vendorMembershipCards.length > 0 ?

                vendorMembershipCards.map((cards, index) => {
                  return (
                    <div key={index} className="">
                      <button
                        className="MembershipDetailButton"
                        onClick={() => {
                          if (!token) {
                            handleShow();
                            return;

                          }

                          navigate(
                            `/MemberShipDetails/${profile.vendor_id}/${cards.membership_id}`,
                            {
                              state: {
                                vendorId: profile.vendor_id,
                                membership_ID: cards.membership_id,
                              },

                            }
                          );
                          // {   console.log("...........................................log",cards.card_pattern_status)
                          // console.log("...........................................next page ",cards.card_pattern_image_url)
                          // }
                        }}
                      >
                        <MembershipCard
                          buttonName="Buy Now"
                          icon
                          //  cardColor={cards.card_color}
                          cardColor={VendorMembershipImageStatus == true ? cards.card_pattern_image_url : cards.card_color}
                          data={{
                            membershipDetails: cards,
                            VendorDetails: vendorDetails,
                          }}
                        />
                      </button>
                    </div>
                  );
                })
                :
                <p className='text-dark text-center mt-5 ff-balooPaaji2-bold fs-20 '>No Membership Available</p>
            }
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal-backdrop show"></div>
      )}
      {showModal && (
        <div className="modal justify-content-center" tabIndex="-1" style={{ display: "block" }}>
          <div className="modal-dialog-centered loginWarningDialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="ff-poppins-semiBold fs-1 primary-text text-center">
                  Attention!
                </div>
                <div className="ff-poppins-regular fs-14 text-center mt-2">
                  To proceed further you need to login/register and get access
                  to all the features.
                </div>
                <div
                  className="d-flex justify-content-center align-items-center mt-1 mb-1"
                  role="button"
                  onClick={() => navigate("/login")}
                >
                  <div className="loginBtn primary-bg text-center text-white p-1 ff-poppins-medium fs-16">
                    Login
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  role="button"
                  onClick={handleClose}
                >
                  <div className="loginBtn text-center text-secondary p-1 ff-poppins-medium fs-16 secondary-bg-1 mt-2">
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MembershipBuyNow;
