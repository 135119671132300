import React, { useState, useEffect } from "react";
import { useGeolocated } from "react-geolocated";
 

const Demo = ({ onAccept, onDecline }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Location Permission</h2>
        <p>We need to access your location to provide better services. Do you allow us to access your location?</p>
        <button onClick={onAccept}>Allow</button>
        <button onClick={onDecline}>Deny</button>
      </div>
    </div>
  );
};

export default Demo;
