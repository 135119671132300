import React, { useState } from 'react'
function Toggle({ data, color, isSelected }) {
    return (
        <div style={{ padding: '5px 10px' }}>
            <div className={`toggle-switch ${isSelected && color === "veg" ? 'toggled veg_Color' : isSelected && color === "nonveg" ? 'toggled nonveg_Color' : isSelected && color === "egg" ? "toggled egg_Color" : ""}`}>
                <img src={data} className="toggle-button" alt="toggle button" />
            </div>
        </div>
    );
}

export default Toggle