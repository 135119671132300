import { useEffect, useRef, useState } from "react";
import p_nav_previous from "../../../Assets/Images/p_nav_previous.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import axiosInstance from "../../../Services/axios";
import defaultimg from "../../../Assets/Images/default-placeholder.png";
import { FaRupeeSign } from "react-icons/fa";
import { toast } from "react-toastify";
// Menu
import vegIcon from "../../../Assets/Images/vegpngoutput.png"
import eggIcon from "../../../Assets/Images/eggpngoutput.png"
import nonvegIcon from "../../../Assets/Images/nomvegoutput.png"
// Addon 
import { IoMdClose } from "react-icons/io";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
const ProductBasket = () => {

  const [cartId, setCartId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantId, flowtype } = location.state || {};
  const [cardDetails, setCardDetails] = useState([]);
  const [shipingtype, setShipingtype] = useState();
  const [deleteitem, setDeleteitem] = useState()

  // Offer or Membership 
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();


  // Addon 
  const addonModalRef = useRef(null);
  const addonEditeModalRef = useRef(null);

  const [productid, setProductid] = useState('')
  const [productCost, setProductCost] = useState('')
  const [addonsCost, setAddonsCost] = useState('')
  const [selectedAddons, setSelectedAddons] = useState('')
  const [cartAddonId, setCartAddonId] = useState([])
  const [productCount, setProductCount] = useState(0);
  const [cartDetails, setCartDetails] = useState([""])
  const [selectItem, setSelectItem] = useState('')

  const flowtypeValue = flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : flowtype;
  
  
  useEffect(() => {
    let productCart = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";
    setCartId(productCart?.cartId ? productCart?.cartId : "");
    setShipingtype(productCart?.shipping_type ? productCart?.shipping_type : "");

    let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
    setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "")
    setcardType(appliedCard?.cardType ? appliedCard?.cardType : "")
    setcardId(appliedCard?.cardId ? appliedCard?.cardId : "")
  }, []);

  useEffect(() => {
    if (cartId !== "") {
      getcardDetail();
    }

  }, [cartId, deleteitem]);

  const [productPrice, setProductPrice] = useState('')
  const [addedCount, setAddedCount] = useState(0);


  const getcardDetail = () => {
    try {
      let reqData = {
        vendor_id: merchantId.vendor_id,
        cart_id: cartId,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"

      };

      axiosInstance.post("user/products/product_cart", reqData).then((res) => {
     

        setCardDetails(res.data.cart);
        if (res.data.cart_count == 0) {
          navigate(-1)
        }
      
        setCartDetails(res.data.cart)

      }).catch((err) => {
        if (err.response.status == 400 || err.response.status == 500) {
          toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
        }
      });
    } catch { }
  };
  const Add_Remove_productcount = (productId, Operation) => {
    try {
      const Addition = [];
      if (selectedAddons && selectedAddons.length > 0) {
        selectedAddons.forEach((addon) => {
          Addition.push(...addon.options);
        });
      }

      let reqData = {
        vendor_id: merchantId.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: 1,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: Addition ? Addition : "",
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"

      };
      axiosInstance
        .post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          // if (res.status == 200) {
          getcardDetail();
          if (Operation == "remove") {
            setProductCount(prevCount => prevCount - 1)
          } else {
            setProductCount(prevCount => prevCount + 1)
          }
          // }
        }).catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast("can't Add product please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };
  const deleteProduct = () => {
    try {
      let localServiceDetail = localStorage.getItem("shipping_type")
        ? JSON.parse(localStorage.getItem("shipping_type"))
        : "";
      let reqData = {
        vendor_id: merchantId.vendor_id,
        product_id: deleteitem.product_id,
        shipping_type: shipingtype,
        quantity: deleteitem.added_count,
        operation: 'remove',
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"

      }

      axiosInstance.post('user/products/add_or_remove_product_cart_without_login', reqData)
        .then((res) => {
          getcardDetail();
        }).catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast("can't Detele product please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  }
  const openAddonModal = () => {
    addonModalRef.current.classList.add("show");
    addonModalRef.current.style.display = "block";
  };

  const closeAddonModal = () => {
    addonModalRef.current.classList.remove("show");
    addonModalRef.current.style.display = "none";
  };
  const handelEdittoAddonmodel = () => {
    closeEditAddonModal()
    openAddonModal()
    setProductCost((totalPrice) * (productCount))
  }
  const transformDataAndCalculateTotalCost = (rawData, selectedOptions) => {
    let transformedData = [];
    let totalCost = 0;

    if (rawData && rawData.length > 0) {
      rawData.forEach((addon) => {
        let addonObj = {
          addonId: addon.id,
          options: []
        };

        addon.options.forEach((option) => {
          if (selectedOptions?.includes(option.id)) {
            addonObj.options.push(option.id);
            totalCost += parseInt(option.price, 10);
          }
        });

        if (addonObj.options.length > 0) {
          transformedData.push(addonObj);
        }
      });
    }

    return { addons: transformedData, cost: totalCost };
  };


  const selectAddon = (addon, option, price) => {
    let addonId = addon.id;
    let selectedAddonsList = [...selectedAddons];
    let found = false;
    // console.log("addon, option, price", addon, option, price);

    selectedAddonsList.forEach(item => {
      if (item.addonId == addonId) {
        found = true;
        if (!item?.options?.includes(option)) {
          if ((addon.selection_type == 1 && item.options.length > 0) || (addon.selection_type == 2 && item.options.length >= addon.max_select)) {
            toast("You have already selected max addons for this category", {
              position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1'
            });
          } else {
            item.options.push(option);
            setProductCost(Number(productCost) + Number(price));
            setAddonsCost(Number(addonsCost) + Number(price));
            setProductPrice(productCost)
          }
        } else {
          item.options = item.options.filter((opt) => opt !== option);
          setProductCost(Number(productCost) - Number(price));
          setAddonsCost(Number(addonsCost) - Number(price));

        }
      }
    });

    if (!found) {
      selectedAddonsList.push({ "addonId": addonId, "options": [option] });
      setProductCost(Number(productCost) + Number(price));
      setAddonsCost(Number(addonsCost) + Number(price));
    };

    setSelectedAddons(selectedAddonsList);
  }

  const checkIsSelectedAddon = (addonId, option) => {
    let selectedAddonsList = [...selectedAddons];
    let found = false;
    selectedAddonsList.forEach(item => {
      if (item.addonId === addonId) {
        if (item?.options?.includes(option)) {
         

          found = true;
        }
      }
    });

    return found;
  }

  const checkButtonStatus = () => {

  
    for (const addon of selectItem?.addons) {
      const selectedAddon = selectedAddons.find((sel) => sel.addonId === addon.id);
      const selectedOptionsCount = selectedAddon ? selectedAddon.options.length : 0;

      if (addon?.required == 1 && addon.selection_type == 2 && selectedOptionsCount < addon.min_select) {
        toast(`You need to select at least ${addon.min_select} option(s) from the category ${addon.addon_name}`, {
          position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1 toast-custom-style' // Add your custom class here
        });
        return;
      } else if (addon?.required == 1 && addon.selection_type == 1 && selectedOptionsCount <= 0) {

        toast(`You need to select at least 1 option(s) from the category ${addon.addon_name}`,
          { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: 'text-danger toast-style-1 toast-custom-style' });

        return;
      }
    }

    closeAddonModal()
    closeEditAddonModal()
    Add_Remove_productcount(productid, "add", "1");
  }

  const openEditAddonModal = (addons, addedAddons, productid, added_count) => {
    let transformedData = transformDataAndCalculateTotalCost(addons, addedAddons);
    setSelectedAddons(transformedData.addons);
    setProductid(productid);
    setProductCount(added_count)
    addonEditeModalRef.current.classList.add("show");
    addonEditeModalRef.current.style.display = "block";

  };
  const closeEditAddonModal = () => {
    addonEditeModalRef.current.classList.remove("show");
    addonEditeModalRef.current.style.display = "none";
    // setModelOpen(true)
  };
  const update_Product = async (productId, Operation, count) => {
    try {
      // if (addonstate != "0") {
      let addons = [];
      selectedAddons?.map((addon) => {
        addons = [...addons, ...addon.options];
        // setReqAddon(addons)
      });
      // }
      let reqData = {
        vendor_id: merchantId.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: count,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: addons ? addons : "",
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"

      };
 

      axiosInstance.post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
 
          getcardDetail();
          setSelectedAddons([]);
          closeEditAddonModal()
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast("can't Update product please content pingle are Try again later", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };
  const confirmUpdate = () => {
    let ProductItem = cartDetails
    var ProductHave = ProductItem?.some((obj) => obj.product_id == productid);
 

    ProductItem?.map((Cartdata, id) => {
      if (Cartdata.product_id == productid) {
        if (Cartdata.quantity > productCount) {
          update_Product(productid, 'remove', Cartdata.added_count - productCount);
        } else if (productCount > Cartdata.added_count) {
          update_Product(productid, 'add', productCount - Cartdata.added_count);
        } else {
          closeEditAddonModal()

        }
      }
    })

  }


  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let calculatedTotalPrice = 0;

    if (cardDetails?.length > 0) {
      cardDetails.forEach((item) => {
        if (productid === item.product_id) {
          calculatedTotalPrice += parseFloat(item.price) || 0;


          item?.addons.forEach((addon) => {
            addon?.options.forEach((option) => {
              if (item.added_addons?.includes(option?.id)) {

                calculatedTotalPrice += parseFloat(option?.price) || 0;
              }
            });
          });
        }
      });
    } 
    setTotalPrice(calculatedTotalPrice);
  }, [cardDetails, productid]);


  return (
    <div>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white ">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => navigate(-1)} />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            Product Basket
          </div>
        </div>
      </div>

      {/* Edit addon model   */}
      <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390, zIndex: 7777, }} ref={addonEditeModalRef} >
        <div className=" d-flex justify-content-end mt-2" onClick={() => closeEditAddonModal()}>
          <span className="closeIcon" ><IoMdClose /> </span>
        </div>
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <p className="m-0 ff-poppins-semiBold fs-14">Your Chosen</p>
            <p className="m-0 ff-poppins-regular fs-14 primary-text" onClick={() => handelEdittoAddonmodel()}>Edit</p>
          </div>

          {cardDetails?.length > 0 &&
            <div>
              {cardDetails?.map((item) => {

                return productid == item.product_id ?
                  item?.addons.map((addon) =>
                    addon?.options?.map((option, optionIndex) => {
                      return item?.added_addons?.includes(option?.id) && (
                        <div className="d-flex justify-content-between" key={optionIndex}>
                          <div className="d-flex mt-1">
                            <img src={option.type == "1" ? vegIcon : option.type == "2" ? nonvegIcon : ""} height="13px" width="13px" className="me-1 mt-1" />
                            <div className="ff-poppins-regular fs-13 ms-1">
                              {option?.name}
                            </div>
                          </div>
                          <div className="d-flex me-4">
                            <div className="ff-poppins-semiBold fs-12 mt-1 me-1">
                              + {option?.price}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : null;
              }
              )}
            </div>
          }
          <div className="mt-3" style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
            <div className=" ">
              {cardDetails?.length > 0 &&
                cardDetails.map((item) => (
                  <div className="ff-poppins-regular ">
                    {productid == item.product_id && item.added_count > 0 && (

                      <div>
                        <div className="d-flex justify-content-between">
                          <div className="  d-flex fs-14 col-4  ">
                            <div className="col-2">
                              <FiMinusCircle
                                className="  primary-text fontSizeCountIcon"
                                onClick={() => {
                                  setProductCount((prevCount) => prevCount - 1);
                                  setProductCost(item.price);
                                }}
                              />
                            </div>
                            <div className=" col-2 ms-1 d-flex justify-content-center">
                              {productCount}
                            </div>
                            <div className="col-2">
                              <LuPlusCircle
                                className={`  primary-text fontSizeCountIcon ${productCount == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                onClick={() => {
                                  if (productCount != item.max_allowed_quantity) {
                                    setProductCount((prevCount) => prevCount + 1);
                                    setProductid(item.product_id)
                                    setProductPrice(item.price)
                                  } else {
                                    toast("You have reached maximum order product", { position: "bottom-center", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });

                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="ff-poppins-semiBold fs-14 primary-text">
                            Cost : {(totalPrice) * (productCount)}
                          </div>
                        </div>
                        <div className="col-12">
                          <button type="button" className="btn-type-1   ff-poppins-semiBold mt-2 fs-14 w-100 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
                            onClick={() => confirmUpdate()} >
                            Confirm
                          </button>
                        </div>

                      </div>
                    )}
                  </div>
                )
                )}
            </div>
          </div>

        </div>
      </div>

      {/* Add addon model  */}
      <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390, zIndex: 7777, maxHeight: "60%" }} ref={addonModalRef}  >
        <div className=" d-flex justify-content-end mt-2" onClick={() => closeAddonModal()}>
          <span className=" closeIcon " ><IoMdClose /> </span>
        </div>
        {cardDetails?.length > 0 &&
          <div>
            {cardDetails?.map((item, productItemIndex) => {
              return productid == item.product_id ?
                item?.addons.map((addon, addonIndex) => (
                  <div className="offcanvas-body small" >
                    <div>
                      <div className="mb-2">
                        <h6 className="m-0 ff-poppins-semiBold fs-14">choose {addon.addon_name}</h6>
                        <div className="secondary-text fs-13">select any (optional)</div>
                      </div>
                      {addon?.options?.map((option, optionIndex) => (
                        <div className="d-flex justify-content-between" >
                          <div className="d-flex mt-1   ">
                            <img src={option.type === "1" ? vegIcon : option.type === "2" ? nonvegIcon : ""} height="13px" width="13px" className="me-1 mt-1" />
                            <div className="ff-poppins-regular fs-13 ms-1">{option?.name}</div>
                          </div>
                          <div className="d-flex">
                            <div className="ff-poppins-semiBold fs-12 mt-1 me-1">+ {option?.price}</div>

                            {
                              checkIsSelectedAddon(addon.id, option.id) ?
                                <div onClick={() => { selectAddon(addon, option.id, option?.price); setProductid(item.product_id) }} className="primary-text fs-15 ff-poppins-semiBold"> <MdOutlineCheckBox /> </div> :
                                <div onClick={() => { selectAddon(addon, option.id, option?.price); setProductid(item.product_id) }} className="fs-15 ff-poppins-semiBold " >    <MdOutlineCheckBoxOutlineBlank /></div>
                            }
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )) : ""
            })}
          </div>
        }
        <div style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
          <div className=" p-1  ">
            <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular fs-14 mb-1">
              Cost: {productCost}
            </p>
            <button type="button" className="btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
              onClick={() => checkButtonStatus()}  >
              Confirm
            </button>
          </div>

        </div>
      </div>
      {/* position-relative */}
      <div className="offcanvas-parentType3 position-relative scroller-style-y">
        <div className="doorStepService ">
          <div className="productsHeroDiv">
            <div className="offcanvas-parentType3 ">
              {/* position-relative */}
              <div className="doorStepService">
                <div className="productsHeroDiv scroller-style-y" style={{ overflowX: 'hidden' }}>
                  {cardDetails.map((item, index) => {
                    return (
                      <div className="productBasketDiv">
                        <div className="col-4 productImageBasketDiv">
                          <img src={item.cover_image.image == "" ? defaultimg : item.cover_image.image_url} className="productImage" />
                        </div>

                        <div className="col-5">
                          <p className="ff-poppins-semiBold  fs-12 m-0">
                            <img src={item.food_type == "1" ? vegIcon : nonvegIcon} height="13px" width="13px" />  {item.product_name}{" "}
                          </p>
                          <p className="ff-poppins-semiBold  fs-12 me-1 m-0">
                            <FaRupeeSign />{item.price}{" "}
                          </p>
                          <p className="ff-poppins-regular primary-text fs-9 m-0">
                            {item.unit_quantity} {item.unit_type_name}
                          </p>

                          <div className="moreDetailsDiv">
                            <p className="moreInfobtn ff-poppins-regular fs-10 px-0 m-0"
                              onClick={() => navigate(`/singleProductDetails/${merchantId.vendor_id}/${item.product_id}`, {
                                state: { productId: item.product_id, merchantId, },
                              })}  >
                              More details
                            </p>
                            <IoIosArrowDown className="fw-bold productWeightBasket primary-text" />
                          </div>
                          {item.applied_card_status == "1" ? (
                            <></>
                          ) : (
                            <div className=" ">
                              <div className="row align-items-center w-50 gx-0 fs-14">
                                <div className="col-3">
                                  <FiMinusCircle className="w-100 fs-24 primary-text" onClick={() => Add_Remove_productcount(item.product_id, "remove")} />
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-end primary-text">
                                  {item.added_count}
                                </div>
                                <div className="col-3">
                                  <LuPlusCircle
                                    className={`w-100 primary-text fontSizeCountIcon ${item.added_count == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                    onClick={() => {
                                      setSelectItem(item)
                                      if (item.addons === "") {
                                        if (item.added_count !== item.max_allowed_quantity) {
                                          Add_Remove_productcount(item.product_id, "add");
                                        }
                                      } else {
                                        openEditAddonModal(item.addons, item.added_addons, item.product_id, item.added_count);
                                      }
                                      // getMatchedDetails(item.added_addons, item.addons);
                                    }}
                                  />

                                </div>
                              </div>
                            </div>

                          )}
                        </div>
                        {item.applied_card_status == "1" ? (
                          <></>
                        ) : (
                          <div className="col-3 deleteIconProductBasket">
                            <MdOutlineDeleteOutline className="ms-2" data-bs-toggle="modal"
                              data-bs-target="#exampleModal" onClick={() => setDeleteitem(item)} />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="reviewAndPayDiv-3" style={{ backgroundColor: "", position: 'sticky', width: 'unset' }}>
            <button className="reviewAndPayBtnProduct primary-bg ff-balooPaaji2-bold" onClick={() => navigate("/ReviewPay", { state: { merchantId, flowtype: flowtypeValue } })}  >
              Proceed to Buy
            </button>
          </div>
        </div>
      </div>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mainModal text-dark ">
          <div class="modal-content p-3">
            <div class="modal-body text-center ff-balooPaaji2-bold mb-3">
              Are you sure want to delete the product?
            </div>
            <div class="modalFooter">
              <button type="button" class="btn btn-secondary ff-balooPaaji2-bold" data-bs-dismiss="modal"   >
                Cancel
              </button>
              <button type="button" class="btn btn-danger ff-balooPaaji2-bold" onClick={() => deleteProduct()} data-bs-dismiss="modal"  >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBasket;
