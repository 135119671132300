import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cartSelectPetOrPerson, cartSelectStaff, vendorStaffList } from '../../Services/api';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import p_down_arrow from '../../Assets/Images/p_down_arrow.svg';
import p_up_arrow from '../../Assets/Images/p_up_arrow.svg';
import DatePickerOffCanvas from '../../Components/offCanvas/datePickerOffCanvas';
import BookForOffCanvas from '../../Components/offCanvas/bookForOffCanvas';

const BookingSelectStaff = () => {
    // Hooks
    const navigate = useNavigate(); // Navigation hook
    const valueParam = useParams(); // URL parameter hook
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    // State variables
    const [staffList, setStaffList] = useState([]); // Staff list state
    const [isDatePickerOffCanvasOpen, setDatePickerOffCanvasOpen] = useState(false); // Date picker visibility state

    const [showBookForModal, setShowBookForModal] = useState(false);
    const [isBookForOffCanvasOpen, setBookForOffCanvasOpen] = useState(false);
    const [cartSelectServiceDetails, setCartSelectServiceDetails] = useState('');
    const [selectedBookForItem, setSelectedBookForItem] = useState(null);

    let selectedServiceOption = localStorage.getItem('selectedServiceOption');

    // Function to toggle date picker visibility
    const toggleOffCanvas = () => {
        setDatePickerOffCanvasOpen(!isDatePickerOffCanvasOpen);
    };

    useEffect(() => {
        getVendorStaffList();
    }, []);

    useEffect(() => {
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
        if (localServiceDetail && localServiceDetail?.reschedule && localServiceDetail?.reschedule == true) {
            toggleOffCanvas();
        }
        if (localServiceDetail.timslotStatus == true && selectedServiceOption && selectedServiceOption == 'service') {
            setDatePickerOffCanvasOpen(true)
        }
    }, []);

    // Fetch vendor staff list on component mount




    useEffect(() => {

        if (selectedBookForItem && selectedBookForItem.id) {
            submitCartSelectPetOrPerson();
        }
    }, [selectedBookForItem]);

    // Function to fetch vendor staff list
    function getVendorStaffList() {
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';

        let param = {
            "vendor_id": valueParam.vendor_id,
            "cart_app_id": (localServiceDetail && localServiceDetail?.cart_app_id) ? localServiceDetail?.cart_app_id : '',
            "service_type": localServiceDetail?.serviceType ? localServiceDetail?.serviceType : '',
        };

        vendorStaffList(param).then((res) => {
            res.data.vendor_staffs.map(item => {
                item.showMoreInfo = false; // Initialize showMoreInfo flag
            });

            setStaffList(
                res.data.vendor_staffs && res.data.vendor_staffs.length > 0
                    ? res.data.vendor_staffs
                    : []
            );
        }).catch(() => {
            console.log('No staffs available');
        });
    }

    // Function to toggle 'showMoreInfo' flag
    function showMoreInfo(item, index) {
        staffList.fill(item.showMoreInfo = !item.showMoreInfo, index, index++);
        forceUpdate();
    }

    function selectStaff(id) {
        let localServiceDetail = localStorage.getItem('serviceDetail')
            ? JSON.parse(localStorage.getItem('serviceDetail'))
            : '';

        let param = {
            "vendor_id": valueParam.vendor_id,
            "cart_app_id": (localServiceDetail && localServiceDetail?.cart_app_id) ? localServiceDetail?.cart_app_id : '',
            "staff_id": id,
            "service_type": (localServiceDetail && localServiceDetail?.serviceType) ? localServiceDetail?.serviceType : '',
        };
       

        cartSelectStaff(param).then((res) => {

            if (res.data?.status == "1") {

      
                setCartSelectServiceDetails(res.data);

                if (selectedServiceOption == 'staff') {
                    localServiceDetail = {
                        cart_app_id: res.data?.cart_app_id,
                        vendor_type: res.data.vendor_type,
                        vendorId: valueParam.vendor_id,
                        serviceType: localServiceDetail?.serviceType
                    }

                    localStorage.setItem('serviceDetail', JSON.stringify(localServiceDetail));
                    openBookForModal()
                } else {
                    toggleOffCanvas();
                }
            }
        })

    }

    const toggleBookForOffCanvas = () => {
        setBookForOffCanvasOpen(!isBookForOffCanvasOpen);
    };

    function openBookForModal() {
        setShowBookForModal(true);
        toggleBookForOffCanvas();
    }

    const handleDataFromBookForOffCanvas = (data) => {
        setSelectedBookForItem(data);
        forceUpdate();
    };

    function submitCartSelectPetOrPerson() {
        let localServiceDetail = localStorage.getItem('serviceDetail')
            ? JSON.parse(localStorage.getItem('serviceDetail'))
            : '';

        let selectedItemId = (cartSelectServiceDetails.vendor_type == 1 || cartSelectServiceDetails.vendor_type == 3)
            ? selectedBookForItem.id
            : selectedBookForItem.pet_id

        let param = {
            "vendor_id": valueParam.vendor_id,
            "cart_app_id": cartSelectServiceDetails.cart_app_id,
            "service_type": localServiceDetail?.serviceType ? localServiceDetail?.serviceType : '',
            "booking_for": selectedItemId
        };
         

        cartSelectPetOrPerson(param).then((res) => {
            

            if (res.data.status == '1') {
                localServiceDetail.bookForStatus = true;
                localStorage.setItem("serviceDetail", JSON.stringify(localServiceDetail));
                if (selectedServiceOption == 'staff') {
                    navigate(`/door-step-service/${valueParam.vendor_id}`)
                } else {
                    navigate(`/select-staff/${valueParam.vendor_id}`)
                }

            }
        });

    }

    return (
        <>
            {/* Navigation Bar */}
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    {/* Previous Button */}
                    <img
                        src={p_nav_previous}
                        width={'24px'}
                        height={'16px'}
                        alt='Previous Button'
                        role='button'
                        onClick={() => navigate(-1)}
                    />
                    {/* Page Title */}
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Staff</div>
                </div>
            </div>

            {/* Staff List */}

            <div className='offcanvas-parentType2 scroller-style-y'>

                {/* Rendering staff list */}
                {
                    staffList.length > 0
                        ? staffList.map((item, index) => (
                            <div className='item-type-1 bg-white' key={index}>
                                <div className='d-flex p-3'>
                                    <img src={item.staff_image} width={'48px'} height={'48px'} className='me-2' alt='Staff Image' />
                                    <div className='w-100'>
                                        <div className='d-flex justify-content-between ms-1'>
                                            <div>
                                                <div className='ff-poppins-semiBold fs-14'>{item.staff_name}</div>
                                                <div className='ff-poppins-regular fs-12 text-secondary'>{item.staff_designation}</div>
                                            </div>
                                            <button
                                                className='btn-type-2 border-0 primary-bg text-white ff-poppins-medium fs-14'
                                                onClick={() => selectStaff(item.staff_id)}
                                            >Select</button>
                                        </div>

                                        {/* Conditional rendering for more info */}
                                        {
                                            item.showMoreInfo
                                                ? (
                                                    <div className='ms-1'>
                                                        <div className='ff-poppins-regular fs-13'>{item.staff_specialist}</div>
                                                        <hr className='mt-1 mb-1' />
                                                        <div className='ff-poppins-regular fs-13'>{item.staff_about}</div>
                                                        <div className='ff-poppins-semiBold fs-13 d-flex align-items-center primary-text' role='button' onClick={() => { showMoreInfo(item, index) }}>
                                                            Less info
                                                            <img
                                                                src={p_up_arrow}
                                                                width={'10px'}
                                                                height={'7px'}
                                                                alt='Show Less Info'
                                                                role='button'
                                                                className='ms-1'
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    <div className='ff-poppins-semiBold fs-13 d-flex align-items-center primary-text ms-1' role='button' onClick={() => { showMoreInfo(item, index) }}>
                                                        More info
                                                        <img
                                                            src={p_down_arrow}
                                                            width={'10px'}
                                                            height={'7px'}
                                                            alt='Show Less Info'
                                                            role='button'
                                                            className='ms-1'
                                                        />
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                        : (
                            <div className='ff-poppins-semiBold fs-14 w-100 h-100 d-flex align-items-center justify-content-center'>
                                No Data Found
                            </div>
                        )
                }

                {showBookForModal && (
                    <BookForOffCanvas
                        isOpen={isBookForOffCanvasOpen}
                        toggleOffCanvas={toggleBookForOffCanvas}
                        cartAppId={cartSelectServiceDetails.cart_app_id}
                        vendorId={cartSelectServiceDetails.vendor_id}
                        vendorType={cartSelectServiceDetails.vendor_type}
                        sendDataToDoorstep={handleDataFromBookForOffCanvas}
                    />
                )}

                {/* Date Picker */}
                <DatePickerOffCanvas isOpen={isDatePickerOffCanvasOpen} toggleOffCanvas={() => {
                    let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
                    if (localServiceDetail && localServiceDetail?.reschedule && localServiceDetail?.reschedule == true) {
                        navigate(-1)
                    } else {
                        toggleOffCanvas();
                    }
                }} />
            </div>
        </>
    );
};

export default BookingSelectStaff;
